import React from 'react';
import SimpleTable from '@platform/simple-table';
import { utils } from '@platform/table-core';
import { ViewModel, linkTo, PubSub, getSafeRandom } from '@platform/api';
const { useSimpleTable } = SimpleTable;

const { isObject, warningOnce, isUndefined, isWrong, getPageSizeStorage } = utils;

// 页面其他区域变化表格重新计算标识
const OTHERCOMPLETE = 'otherComplete';
const BEFORECOMPLETE = 'beforeAreaChangeComplete';

/** 3   getTablePageInfo    获取分页信息**/
/** 4   openModel    显示录入表单**/
/** 5   closeModel    关闭录入表单**/
/** 6   setTableRender    设置某一列的render**/
/**7    setColScale 设置列精度******************
这个结构是根据崔松杰的方法返回的，又问题可以问问他。。。
 list数据结构   [{areacode: 'tableid', filedcode: 'colkey', scale: "2"}, ....]**/
/**8    checkVisible 检查显示******************/
/** 9   getAllTableData    获取表格全数据方法**/
/** 10   setTableValueByKeyAndRecord    设置表格数据**/
/** 11   hideColByKey    隐藏列**/
/** 12   showColByKey    显示列**/
/** 13   getCheckedRows    获取选中行**/
/** 14   selectAllRows    选中所有行**/
/** 15   reverseSelected    反转所有行的选中状态**/
/** 16   deleteTableRowsByIndex    通过行index删除行**/
/** 17   deleteTableRowsByRowId    通过行RowId删除行**/
/** 18   addTableRow    通过index新增行**/
/** 19   setTableValueBykey    通过key设置单元格的值**/
/** 20   setTableValueDisabled    通过key设置表格列的编辑性**/
/** 21   setTableValueRequired    通过key设置列是否必输**/
/** 22   focusTableRowByIndex    通过index聚焦行**/
/** 23   updateDataByIndexs    通过index更新多行的数据**/
/** 24   setValByKeyAndRowId    根据rowid设置表格某行某个字段值**/
/** 25   selectTableRows    设置某些行的选中状态**/
/** 26   getPks    获取某页的pks**/
/** 27   hasCacheData    判断列表是否有缓存数据**/
/** 28   deleteCacheId    删除 allpks**/
/** 29   addCacheId    新增 allpks**/
/** 30   updateTableData     通过rowid更新表格行数据**/
/** 31   setClickRowIndex     设置当前点击行**/
/** 32   getClickRowIndex     获取当前点击行**/
/** 33   setValByKeyAndIndex     根据index设置表格某行某个字段值**/
/** 34   updateDiffDataByIndex     差异更新（王策）**/
/** 35   updateTableHeight      共享  郭扬让加的，他们现在再用
 * 刷新表格高度  这是特殊场景使用，一般是表格上方部分，高度变化，表格需要不断适应时使用）**/
/** 36   getSortParam     获取当前表格,排序信息包括当前是多列还是单列排序, 排序的字段,和排序顺序
 * 这是产品李聪慧让加的, 为了解决单据打印时，列表对中字段排序产生的问题**/
/** 37   updateDataByRefresh      根据wensocket推送值，更新表格数据**/

function existenceCheck(id) {
    let metaStore = this.tableDataSource[id];
    if (!metaStore) {
        warningOnce(false, `所操作的表格中无ID为${id}的数据`);
        return;
    }
    return metaStore;
}
/** 1   createsimpleTable    创建表格**/
export function createSimpleTable(moduleId, config = {}) {
    let pageScope = this,
        store = pageScope.tableDataSource[moduleId];
    if (!store) {
        store = useSimpleTable();
        // 如果有缓存值 则做一下  Store 的赋值操作  把setTableData里的else操作提到这里了
        if (pageScope.myTableData[moduleId]) {
            // store.setTableData(pageScope.myTableData[moduleId]); // TODO Check
            store.setTableProps('focusIndex', -1, false);
            delete pageScope.myTableData[moduleId];
        }
    }
    let { onRowClick, onRowDoubleClick, selectedChange, onSelected, onSelectedAll, handlePageInfoChange, onBatchSelected, tableModelConfirm, rowClassName, ...others } = config;

    others.onBatchSelected = !onBatchSelected ? false : rows => {
        return onBatchSelected && onBatchSelected({
            ...pageScope.props,
            ...pageScope.output,
        }, moduleId, rows);
    };
    //modelDatas
    let meta = store.getMeta(moduleId);
    if (meta) {
        store.setStore(['modelDatas', 'modelColumn'], meta);
    }

    others.high_filter = others.high_filter || { alloweFilter: others.alloweFilter };
    // console.log(config);

    return (
        <SimpleTable
            store={store}
            name={moduleId}
            output={pageScope.output}
            afterLangload={json => {
                // console.log(this, json);
                pageScope.tableDataSource._langJson = json || {};
            }}
            onRowClick={(record, outputIndex, e) => {
                if (onRowClick) {
                    outputIndex = store.getTrueRowIndex({ rowIndex: outputIndex }, 'normal', 'filter');
                    return onRowClick.call(
                        pageScope,
                        { ...pageScope.props, ...pageScope.output },
                        moduleId,
                        record,
                        outputIndex,
                        e,
                    );
                }
            }}
            onRowDoubleClick={(record, outputIndex, e) => {
                if (onRowDoubleClick) {
                    outputIndex = store.getTrueRowIndex({ rowIndex: outputIndex }, 'normal', 'filter');
                    return onRowDoubleClick.call(
                        pageScope,
                        record,
                        outputIndex,
                        { ...pageScope.props, ...pageScope.output },
                        e,
                    );
                }
            }}
            onSelected={(tableId, record, outputIndex, selected) => {
                if (onSelected) {
                    outputIndex = store.getTrueRowIndex({ rowIndex: outputIndex }, 'normal', 'filter');
                    onSelected.call(
                        pageScope,
                        { ...pageScope.props, ...pageScope.output },
                        tableId,
                        record,
                        outputIndex, //outputIndex
                        selected,
                    );
                }
            }}
            selectedChange={(tableId, newLen, oldLen) => {
                if (selectedChange) {
                    selectedChange.call(
                        pageScope,
                        { ...pageScope.props, ...pageScope.output },
                        tableId,
                        newLen,
                        oldLen,
                    );
                }
            }}
            onSelectedAll={(tableId, checkedAll, len) => {
                if (onSelectedAll) {
                    onSelectedAll.call(
                        pageScope,
                        { ...pageScope.props, ...pageScope.output },
                        tableId,
                        checkedAll,
                        len,
                    );
                }
            }}
            onPageInfoChange={(pks, total) => {
                if (handlePageInfoChange) {
                    handlePageInfoChange({ ...pageScope.props, ...pageScope.output }, config, pks, total);
                }
            }}
            tableModelConfirm={(data, type) => {
                if (tableModelConfirm) {
                    tableModelConfirm({ ...pageScope.props, ...pageScope.output }, data, type);
                }
            }}
            onCellHyperlinkTo={(record, attrcode, value, tableId) => {
                linkTo(pageScope.state.meta.pageid, tableId, attrcode, value.value);
            }}

            rowClassName={rowClassName}
            config={others}
        />
    );
}
/** 2   setAllTableData    给表格设置所有数据**/
export function setAllTableData(moduleId, data, isTop = true, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, moduleId);
    let prePageSize = getPageSizeStorage(this.state.meta, moduleId);
    if (!store) {
        this.myTableData[moduleId] = data;
        return;
    }

    /**
     * 处理筛选状态下的情况
     * 清空的表格筛选标志
     * 清空筛选状态下的表格全数据
     * 退出筛选状态
     */
    // TODO 筛选
    store.setStore('filterReset', true, false, () => {
        store.setStore('filterReset', false, false);
    });
    store.resetFilter(moduleId, false);

    let allpks = store.getStore(['allpks']),
        alltss = store.getStore(['alltss']),
        rows = [],
        pageInfo = store.getStore(['pageInfo']);
    //pageinfo
    if (data.hasOwnProperty('pageInfo') && !isWrong(data.pageInfo)) {
        // 前端重置后端返回的页码，初次为0，应该改为1，有问题在看看
        pageInfo = data.pageInfo;
        pageInfo.pageIndex = Number(data.pageInfo.pageIndex) + 1;
    } else if (data.rows && data.rows.length == 0) {
        pageInfo = {
            pageSize: prePageSize,
            pageIndex: 1,
            total: 0,
            totalPage: 1,
        };
    } else if (!(allpks.length > 0)) {
        pageInfo = {
            pageSize: prePageSize,
            pageIndex: 1,
        };
    }
    store.setStore('pageInfo', pageInfo, false);
    //allpks
    if (data.allpks) {
        allpks = data.allpks;
    } else if (data.rows && data.rows.length == 0) {
        allpks = []
    }
    store.setStore('allpks', allpks, false);
    //alltss
    if (data.alltss) {
        alltss = data.alltss;
    }
    store.setStore('alltss', alltss, false);
    //props
    if (isTop) {
        let props = store.getStore('props');
        props.focusIndex = 0;
        store.setStore('props', props, false);
    }
    //   checkinfo 
    let crossPageSelectIndex = store.getStore(['checkInfo', 'crossPageSelectIndex'])
    let checkedAllpage = store.getStore(['checkInfo', 'checkedAllpage'])
    if (crossPageSelectIndex.size) {
        let selectPk = new Set(crossPageSelectIndex);
        let newIndex = new Set()
        selectPk.forEach(pk => {
            if (allpks.indexOf(pk) !== -1) {
                newIndex.add(pk)
            }
        })
        crossPageSelectIndex = newIndex
    }
    let selectedRowsSet = new Set();
    data.rows.map(item => {
        if (item.selected) {
            selectedRowsSet.add(item.rowId)
        }
    })
    let selectedRowsSetSize = selectedRowsSet.size
    let checkInfo = {
        checkedAll: (selectedRowsSetSize && selectedRowsSetSize == data.rows.length) ? true : false, //当前页全选标志
        indeterminate: (selectedRowsSetSize && selectedRowsSetSize < data.rows.length) ? true : false, //半选标志
        selectIndex: selectedRowsSet, //选中行
        disabledAll: false,
        checkedAllpage, //所有页全选
        crossPageSelectIndex,
    }
    store.setStore(['checkInfo'], checkInfo, false);

    //排序待写 清空排序
    let arrayColumns = store.getArrayMeta(moduleId);
    arrayColumns && store.clearSortStatus({ columns: arrayColumns });

    //筛选待写
    //rows
    rows = data.rows.map(e => {
        e.rowId = e.rowId || String(new Date().getTime()).slice(-5) + getSafeRandom().toString(12);
        return e;
    });

    store.setData(rows, false);

    // 跨页全选
    let config = store.getConfig()
    let { crossPageSelect, pkname } = config
    if (crossPageSelect && pkname) {
        if (checkedAllpage) {
            store.setSelect(undefined, 'allpage', false)
        } else {
            let rows = store.getData()
            let rowIdArr = []
            rows.forEach(row => {
                if (!row.values[pkname]) {
                    console.error(`行数据中没有pkname为${pkname}的value`)
                    console.log(row)
                    return
                }
                let pk = row.values[pkname].value
                if (crossPageSelectIndex.has(pk)) {
                    rowIdArr.push(row['rowId'])
                }
            })

            store.setSelect(rowIdArr, 'set', false)
        }

    }

    // 统一更新
    shouldForceUpdate && store.refresh(() => {
        // 聚焦完成之后重置 避免一更新  直接跳到第一行
        store.setTableProps('focusIndex', -1, false);
        store.setStore('filterReset', false, false);
    });
}
/** 3   getTablePageInfo    获取分页信息**/
export function getTablePageInfo(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    let prePageSize = getPageSizeStorage(this.state.meta, moduleId);
    if (!store) {
        return { pageIndex: 0, pageSize: prePageSize };
    }
    let pageInfo = store.getStore('pageInfo');

    let { pageIndex = 0, pageSize = prePageSize } = pageInfo;
    pageSize = Number(pageSize);
    return {
        pageIndex: pageIndex > 0 ? pageIndex - 1 : 0,
        pageSize,
    };
}

/** 4   openModel    显示录入表单**/
export function openModel(moduleId, type, record, index) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    index = store.getTrueRowIndex({ rowIndex: index }, 'filter', 'normal');

    store.openModal(type, record, index);
}

/** 5   closeModel    关闭录入表单**/
export function closeModal(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.closeModel();
}

/** 6   setTableRender    设置某一列的render**/
export function setTableRender(moduleId, key, render) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.setColumn(moduleId, key, { render });
}
/**7    setColScale 设置列精度******************
这个结构是根据崔松杰的方法返回的，又问题可以问问他。。。
 list数据结构   [{areacode: 'tableid', filedcode: 'colkey', scale: "2"}, ....]**/
export function setColScale(list) {
    list.forEach(eve => {
        const { areacode, fieldcode, scale } = eve;

        let store = existenceCheck.call(this, areacode);
        if (!store) {
            return;
        }
        let meta = store.getMeta();
        meta[areacode].items.forEach(item => {
            if (item.attrcode === fieldcode) {
                item.scale = scale;
            }
        });
        store.setMeta(meta);
    });
}
/**8    checkVisible 检查显示******************/
export function checkVisible(moduleId, colKey) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    let isVisible = false;
    let items = store.getMeta(moduleId);
    items.forEach(eve => {
        //获取合计行的列配置
        const { visible, attrcode, children } = eve;
        if (!isUndefined(children)) {
            //判断和并列的情况
            children.forEach(item => {
                const { visible } = item;
                if (colKey === attrcode && visible) {
                    isVisible = true;
                }
            });
        } else if (colKey === attrcode && visible) {
            isVisible = true;
        }
    });
    return isVisible;
}
/** 9   getAllTableData    获取表格全数据方法**/
export function getAllTableData(moduleId, flag = true) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let data = {
        pageInfo: store.getStore('pageInfo'),
        rows: store.getStore('rows'),
        model: store.getStore('modelDatas', 'showModal'),
        operType: store.getStore('modelDatas', 'operType'),
        checkedAll: store.getStore('checkInfo', 'checkedAll'),
        allpks: store.getStore('allpks'),
    };
    return flag ? JSON.parse(JSON.stringify(data)) : data;
}
/** 10   setTableValueByKeyAndRecord    设置表格数据**/
//这个方法好像没有暴露出去，不知道有什么用，报错再说
export function setTableValueByKeyAndRecord(moduleId, record, dist) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let newRecord = { ...record, ...dist };
    let id = record.attrcode.value;
    let rows = store.getStore('rows');
    rows.map(item => {
        if (item.values.attrcode && id == item.values.attrcode.value) {
            item.values = { ...newRecord };
        }
    });

    store.setData(rows);
}
/** 11   hideColByKey    隐藏列**/
export function hideColByKey(moduleId, key) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.showColumn(moduleId, key, false);
}
/** 12   showColByKey    显示列**/
export function showColByKey(moduleId, key) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.showColumn(moduleId, key, true);
}
/** 13   getCheckedRows    获取选中行**/
export function getCheckedRows(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let selectIndex = store.getStore(['checkInfo', 'selectIndex']);
    let rows = store.getStore(['rows']);
    //兼容修改数据selected true的情况
    if (selectIndex.size === 0 && rows.length) {
        rows.forEach(row => {
            if (row.selected) {
                selectIndex.add(row.rowId)
            }
        })
    }
    let checkRows = [];
    let config = store.getConfig();
    let { crossPageSelect, pkname } = config;
    if (crossPageSelect && pkname) {
        let crossPageSelectIndex = store.getStore(['checkInfo', 'crossPageSelectIndex']);
        let pageInfo = store.getStore(['pageInfo']);
        let { pageSize } = pageInfo;
        pageSize = Number(pageSize);
        // pageIndex = Number(pageIndex);
        // let currentPageStartIndex = pageSize * pageIndex - pageSize;
        // let currentPageEndIndex = pageSize * pageIndex - 1;
        let allpks = store.getStore(['allpks']);
        let alltss = store.getStore(['alltss']);

        let currentPagePkArr = []
        rows.forEach(row => {
            let pk = row.values[pkname].value
            currentPagePkArr.push(pk)
        })

        crossPageSelectIndex.forEach(item => {
            let row
            let rowIndex = currentPagePkArr.indexOf(item)
            if (rowIndex !== -1) {
                let pkIndex = allpks.indexOf(item)
                row = {
                    data: rows[rowIndex],
                    index: pkIndex % pageSize,
                    pageIndex: Math.floor(pkIndex / pageSize),
                }
            } else {
                let pkIndex = allpks.indexOf(item)
                if (pkIndex !== -1) {
                    row = {
                        data: { pk: allpks[pkIndex], ts: alltss[pkIndex] },
                        index: pkIndex % pageSize,
                        pageIndex: Math.floor(pkIndex / pageSize),
                    }
                }
            }
            checkRows.push(row);

        });
    } else {
        // 取过滤之后视图的数据来做操作
        let data = store.getFilterData();
        data.forEach((row, index) => {
            if (selectIndex.has(row.rowId)) {
                let checkRow = {
                    data: row,
                    index,
                };
                checkRows.push(checkRow);
            }
        });
    }
    return checkRows;
}
/** 14   selectAllRows    选中所有行**/
export function selectAllRows(moduleId, checked) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    //跨页全选
    let config = store.getConfig();
    let { crossPageSelect, pkname } = config;
    if (crossPageSelect && pkname) {
        if (checked) {
            let allpks = store.getStore('allpks')
            store.setStore(['checkInfo', 'crossPageSelectIndex'], new Set(allpks), false)
        } else {
            store.setStore(['checkInfo', 'crossPageSelectIndex'], new Set(), false)
        }
    }

    if (checked) {
        store.setSelect(undefined, 'all');
    } else {
        store.setSelect(undefined, 'clear');
    }
}
/** 15   reverseSelected    反转所有行的选中状态**/
export function reverseSelected(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    store.setSelect(undefined, 'reverse');
}
/** 16   deleteTableRowsByIndex    通过行index删除行**/
export function deleteTableRowsByIndex(moduleId, index) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    // 因为不会给内部用，所以不用转，内部默认就是按照过滤之前的数据处理的
    // index = store.getTrueRowIndex({ rowIndex: index }, 'filter', 'normal');

    store.deleteTableRows(index);
}
/** 17   deleteTableRowsByRowId    通过行RowId删除行**/
export function deleteTableRowsByRowId(moduleId, rowId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.deleteTableRows(rowId, 'rowId');
}
/** 18   addRow    通过index新增行**/
export function addRow(moduleId, data, index) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    // 因为不会给内部用，所以不用转，内部默认就是按照过滤之前的数据处理的
    // index = store.getTrueRowIndex({ rowIndex: index }, 'filter', 'normal');
    store.addTableRows(data.rows, index);
}
/** 19   setTableValueBykey    通过key设置单元格的值**/
export function setTableValueBykey(moduleId, key, data, type) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    if (type == 'refer') {
        store.setStore(
            ['modelDatas', 'record', key],
            {
                display: data.refname,
                value: data.refpk,
            },
            true,
        );
    } else {
        store.setStore(
            ['modelDatas', 'record', key],
            {
                value: data,
                display: null,
            },
            true,
        );
    }
}

/** 20   setTableValueDisabled    通过key设置表格列的编辑性**/
export function setTableValueDisabled(moduleId, key, flag) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.setColumn(moduleId, key, { disabled: flag });
}
/** 21   setTableValueRequired    通过key设置列是否必输**/
export function setTableValueRequired(moduleId, key, flag) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.setColumn(moduleId, key, { required: flag });
}
/** 22   focusRowByIndex    通过index聚焦行**/
export function focusRowByIndex(moduleId, index) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    // focusIndex 用过滤之后的视图
    let focusIndex = store.getTrueRowIndex({ rowIndex: index }, 'filter', 'normal');
    store.setTableProps('focusIndex', focusIndex, false);
    // 因为不会给内部用，所以不用转，内部默认就是按照过滤之前的数据处理的
    // index = store.getTrueRowIndex({ rowIndex: index }, 'filter', 'normal');
    store.setTableProps('currentIndex', index, true, () => {
        store.setTableProps('focusIndex', -1, false);
    });
}
/** 23   updateDataByIndexs    通过index更新多行的数据**/
export function updateDataByIndexs(moduleId, record) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let testDataType = Array.isArray(record);
    if (testDataType) {
        let tableData = store.getData();
        let rowidArr = []
        record.forEach(item => {
            let { index, data } = item;
            // 因为不会给内部用，所以不用转，内部默认就是按照过滤之前的数据处理的
            if (index < 0) {
                console.error(`index =${index},小于0`)
                return
            }
            if (isObject(data)) {
                let row = tableData[index];
                tableData[index] = Object.assign(row, data);
                rowidArr.push(tableData[index].rowId)
            }
        });
        //判断勾选
        store.setData(tableData, false);
        store.setSelect(rowidArr, 'delete') //解决NCC-41690
        return false;
    }
}
/** 24   setValByKeyAndRowId    根据rowid设置表格某行某个字段值**/
export function setValByKeyAndRowId(moduleId, rowid, key, value) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.setCellValue({ value, rowId: rowid, attrcode: key });
    return false;
}
/** 25   selectTableRows    设置某些行的选中状态**/
export function selectTableRows(moduleId, index, flag, isCallBack = false, param = {}, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    // 因为不会给内部用，所以不用转，内部默认就是按照过滤之前的数据处理的
    let indexArr = Array.isArray(index) ? index : [index];
    let rowIdArr = _getRowIdByIndex.call(this, moduleId, indexArr);

    let { pattern } = param
    if (flag) {
        store.setSelect(rowIdArr, pattern ? pattern : 'set', shouldForceUpdate);
    } else {
        store.setSelect(rowIdArr, 'delete', shouldForceUpdate);
    }
    // let config = store.getConfig()
    isCallBack && warningOnce(false, `还没写`);
}
/** 26   getPks    获取某页的pks**/
export function getPks(moduleId, pageIndex, pageSize) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let pageInfo = store.getStore(['pageInfo']);
    let allpks = store.getStore(['allpks']);
    let { pageIndex: currentPageIndex, pageSize: currentPageSize } = pageInfo;
    let pks = [];
    let index = Number(isUndefined(pageIndex) ? currentPageIndex : pageIndex);
    let size = Number(isUndefined(pageSize) ? currentPageSize : pageSize);
    let start = (index - 1) * size;
    for (let i = start; i < start + size; i++) {
        if (allpks[i]) {
            pks.push(allpks[i]);
        } else {
            break;
        }
    }
    return pks;
}
/** 27   hasCacheData    判断列表是否有缓存数据**/
export function hasCacheData(dataSource) {
    if (dataSource) {
        let mcData = ViewModel.getData(dataSource);
        if (mcData && mcData.simpleTable && mcData.simpleTable.rows && mcData.simpleTable.rows.length) {
            return true;
        }
    }
    return false;
}
/** 28   deleteCacheId    删除 allpks**/
export function deleteCacheId(moduleId, pkvalue) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let allpks = store.getStore(['allpks']);
    if (Array.isArray(allpks) && allpks.length) {
        if (typeof pkvalue === 'string') {
            allpks = allpks.filter(item => {
                return item != pkvalue;
            });
        }
        if (Array.isArray(pkvalue) && pkvalue.length) {
            allpks = allpks.filter(item => {
                return !pkvalue.includes(item);
            });
        }
        store.setStore(['allpks'], allpks);
    }
}
/** 29   addCacheId    新增 allpks**/
export function addCacheId(moduleId, pkvalue) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let allpks = store.getStore(['allpks']);
    if (Array.isArray(allpks)) {
        if (!allpks.includes(pkvalue)) {
            allpks.push(pkvalue);
        }
        store.setStore(['allpks'], allpks);
    }
}
/** 30   updateTableData     通过rowid更新表格行数据**/
export function updateTableData(moduleId, data) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    let checkData = isObject(data) && Array.isArray(data.rows);
    if (checkData) {
        // 处理rowId
        let allRows = store.getData();
        allRows.forEach(item => {
            for (let row of data.rows) {
                if (item.rowId == row.rowId) {
                    // 状态为 1，2用返回的0代替
                    Object.keys(item).forEach(key => {
                        if (!isUndefined(row[key])) {
                            item[key] = row[key];
                        } else {
                            delete item[key];
                        }
                    });
                }
            }
        });

        _setHeadCheckbox.call(this, moduleId, allRows, true);
        store.setData(allRows, false);
    }
}
/** 31   setClickRowIndex     设置当前点击行**/
export function setClickRowIndex(moduleId, data) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    if (data && (data.index || data.index === 0)) {
        data.index = store.getTrueRowIndex({ rowIndex: data.index }, 'filter', 'normal')

    }

    store.setTableProps("currentInfo", data);
}
/** 32   getClickRowIndex     获取当前点击行**/
export function getClickRowIndex(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    let data = store.getTableProps("currentInfo");

    if (data && (data.index || data.index === 0)) {
        data.index = store.getTrueRowIndex({ rowIndex: data.index }, 'normal', 'filter');
    }

    return data;
}
/** 33   setValByKeyAndIndex     根据index设置表格某行某个字段值**/
export function setValByKeyAndIndex(moduleId, index, key, value) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    // 因为不会给内部用，所以不用转，内部默认就是按照过滤之前的数据处理的
    store.setCellValue({ value, index, attrcode: key });

    return false;
}

/** 34   updateDiffDataByIndex     差异更新（王策）**/
export function updateDiffDataByIndex(moduleId, data) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    let rows = store.getData();
    let testDataType = Array.isArray(data);
    if (testDataType) {
        data.forEach(item => {
            // 因为不会给内部用，所以不用转，内部默认就是按照过滤之前的数据处理的
            let { index, values } = item;
            if (isObject(values)) {
                Object.keys(values).forEach(key => {
                    rows[index].values[key] = { ...rows[index].values[key], ...values[key] };
                });
            }
        });
        store.setData(rows);
    }
}
/** 35   updateTableHeight      共享  郭扬让加的，他们现在再用
 * 刷新表格高度  这是特殊场景使用，一般是表格上方部分，高度变化，表格需要不断适应时使用）**/
export function updateTableHeight() {
    PubSub.publish(OTHERCOMPLETE, true);
}
export function beforeAreaChangeHeight() {
    PubSub.publishSync(BEFORECOMPLETE, true);
}
/** 36   getSortParam     获取当前表格,排序信息包括当前是多列还是单列排序, 排序的字段,和排序顺序
 * 这是产品李聪慧让加的, 为了解决单据打印时，列表对中字段排序产生的问题**/
export function getSortParam(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    return store.getStore(['sortParam']);
}
/** 37   updateDataByRefresh      根据wensocket推送值，更新表格数据**/
export function updateDataByRefresh(moduleId, pkname, refreshData, saga_errormesg) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    if (Array.isArray(refreshData)) {
        // let table = myTable.state.table;
        let rows = store.getData();
        let refreshLen = refreshData.length;
        let data = rows.map(item => {
            let values = item.values;
            for (let i = 0; i < refreshLen; i++) {
                //找到相应行,更新对应字段的value
                if (values[pkname] && values[pkname].value === refreshData[i][pkname]) {
                    //console.log("simpletable数据对比")
                    for (let pop in refreshData[i]) {
                        if (values[pop]) {
                            values[pop].value = refreshData[i][pop];
                        } else {
                            values[pop] = { value: refreshData[i][pop] };
                        }
                        //有错误信息时，将错误信息数据放表格行
                        if (pop === 'saga_status' && refreshData[i][pop] === '1' && saga_errormesg) {
                            values.saga_errormesg = saga_errormesg;
                        }
                    }
                    break;
                }
            }
            return item;
        });
        store.setData({ data });
    }
}

/** 38   saveFilterCustomColData       存储自定义的数据, 用于自定义列的筛选**/
export function saveFilterCustomColData(tableId, { attrcode, rowId, value }) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    store.setCache(['customColData', attrcode, rowId], value);

}

export function setCacheFocusIndex(moduleId, index) {

    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    // currentIndex 默认就应该存当前真实的index(过滤之前的)
    store.setTableProps('currentIndex', index, false);
}

//内部方法
// 设置全选半选或者无状态
function _setHeadCheckbox(moduleId, data, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, moduleId);
    let selectArr = new Set();
    let indeterminate = false,
        checkedAll = false;
    data.forEach((row, index) => {
        if (row.selected) {
            selectArr.add(index);
        }
    });

    let selectArrLen = selectArr.size;
    let dataLen = data.length;
    if (selectArrLen) {
        if (selectArrLen === dataLen) {
            checkedAll = true;
        } else if (selectArrLen < dataLen) {
            indeterminate = true;
        }
    }
    store.setStore(['checkInfo', 'indeterminate'], indeterminate, false);
    store.setStore(['checkInfo', 'checkedAll'], checkedAll, shouldForceUpdate);
}

//通过index获得rowid
function _getRowIdByIndex(moduleId, indexArr) {
    let store = existenceCheck.call(this, moduleId);
    let rows = store.getData();
    let rowIdArr = [];
    rows.forEach((row, index) => {
        if (indexArr.includes(index)) {
            rowIdArr.push(row.rowId);
        }
    });
    return rowIdArr;
}
