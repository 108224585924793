

import * as config from './const';

export const feConfigName = config.name;

export const getCheckedMethod = function (obj, name, defaultf) {
	return obj && typeof obj[name] === 'function' ? obj[name] : (defaultf || config.emptyf);
};

export const getCheckedExtendMethod = function (obj, name, defaultf) {
	// console.log('二开相关回调方法', config, name);
	if (name === 'templateAlreadyLoaded') {
		console.log('二开相关回调方法', config, name);
	}
	// 有些特殊情况 取不到this对象上的方法
	if (obj && (!obj[config.name] || JSON.stringify(obj[config.name]) === '{}')) {
		obj = window;
	}
	return obj && obj[config.name] ? getCheckedMethod(obj[config.name], name, defaultf) : (defaultf || config.emptyf);
};

// export const decoratorZ = function (target, name, descriptor) {
// 	var oldValue = descriptor.value;
// 	descriptor.value = function () {
// 		console.log(`before ${name}`, this);
// 		let res = oldValue.apply(this, arguments);
// 		console.log(`after ${name}`);
// 		return res;
// 	};
// 	return descriptor;
// };

const defaultFxConfig = {
	onAfterEvent: 'after', // after before replace remian
	onBeforeEvent: 'before',
	onEditTableAfterEvent: 'after', // after before replace remian
	onEditTableBeforeEvent: 'before',
	onFormAfterEvent: 'after', // after before replace remian
	onFormBeforeEvent: 'before',
	onCardTableAfterEvent: 'after', // after before replace remian
	onCardTableBeforeEvent: 'before',
};

function testType(origin) {
	return Object.prototype.toString.call(origin).slice(8, -1);
}

export const beforeAndAfter = function (pageScope, info = {}, fy, fx) {
	//有些特殊情况 取不到this对象上的方法
	if (JSON.stringify(pageScope[config.name]) === '{}') { 
		pageScope = window;
	}
	// console.log('二开相关回调方法', info);
	if (!pageScope[config.name] || !info || !info['attrcode'] || !info['fxType']) {
		return fy();
	}
	let { attrcode, moduleId = '', fxType = 'onAfterEvent', fxParams = {} } = info,
		fe = pageScope[config.name],
		fxConfig = {
			...defaultFxConfig,
			...(fe['defaultFxConfig'] || {}),
			...(fe[moduleId + '-' + attrcode] || {})
		};
	// 获取对应的回调方法
	fx = fx || getCheckedExtendMethod(pageScope, fxType);
	// console.log(fxConfig, info, fe['defaultFxConfig']);
	if (fxConfig[fxType] === 'mixin') {
		return fx(fxParams, fy);
	} else if (fxConfig[fxType] === 'replace') {
		return fx(fxParams);
	} else if (fxConfig[fxType] === 'before') {
		const orderResult = fx(fxParams); // 可以考虑添加异步处理
		if (testType(orderResult) === 'AsyncFunction') {
			return new Promise(resolve => {
				orderResult().then(data => {
					fy();
					resolve(data);
				});
			});
		} else if (testType(orderResult) === 'Promise') {
			return new Promise(resolve => {
				orderResult.then(data => {
					fy(); // 这里也可能是异步关系
					resolve(data);
				});
			});
		} else {
			return fy();
		}
	} else {
		const orderResult = fy(); // 可以考虑添加异步处理
		if (testType(orderResult) === 'AsyncFunction') {
			return new Promise(resolve => {
				orderResult().then(data => {
					fx(fxParams);
					resolve(data);
				});
			});
		} else if (testType(orderResult) === 'Promise') {
			return new Promise(resolve => {
				orderResult.then(data => {
					fx(fxParams); // 这里也可能是异步关系
					resolve(data);
				});
			});
		} else {
			return fx(fxParams);
		}
	}

};
export default {
	getCheckedExtendMethod,
	getCheckedMethod,
	beforeAndAfter,
	feConfigName,
};