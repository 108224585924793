
import React from 'react';
import CardTable from '@platform/card-table';
import { CONFIG, utils } from '@platform/table-core';
import { beforeAndAfter, getCheckedExtendMethod } from '../../../../FastExtend/utils';
import { WithKeyboardChange } from '@platform/base'
import { toast, linkTo, getCookie, PubSub, clearOneTypeToast, getLangCode} from '@platform/api';
import { filter } from 'lodash-es';

const { keyByModuleId, createByModuleType } = WithKeyboardChange.createKeyMap;

const { isFunction, isArray, isObject, warningOnce, isString, isUndefined, isWrong } = utils;


const { useCardTable, TabsTableStore } = CardTable;

const defaultEmptyFunction = function () { };

// console.log(useCardTable, TabsTableStore);

const ROW_STATUS = CONFIG.ROW_STATUS;

// 页面其他区域变化表格重新计算标识
const OTHERCOMPLETE = 'otherComplete';
const HEIGHTTOSCROLL = 'heightToScroll';

// console.log(beforeAndAfter, getCheckedExtendMethod);

/**
 *  cardTable 关键功能：
 * *
 *  1.  编辑后事件：需要维护一份旧值，编辑后事件需要给业务新值和旧值
 *
 *  2.  新增行时，需要取模板 initialValue 数据 给单元格赋默认值
 *
 *  3.  孙表领域调用API测试
 * 
 *
 * *
 */

/**
 *     ***********      方 法 目 录    ************
 *
 *     PS： 按习惯和常用性排序，新增方法请追加
 *
 *     序号      	英文名称              	 作用
 * 		1     	setTableData       		  设置表格数据
 * 		2     	getStatus          		  获取表格的状态
 * 		3     	setStatus          		  设置表格的状态
 * 		4     	getAllRows     	 		    获得表格所有数据
 * 		5     	getVisibleRows     		  获得表格可见数据
 * 		6     	getChangedRows     		  获得表格修改后数据
 * 		7     	getNumberOfRows    	  	获得表格总数(可见/全部)
 * 		8     	getAllData        		  获取表格被包装的数据
 *      9     	addRow             	  	新增行(通过index值)
 * 		10    	delRowByRowId      	  	删除行通过（rowId）
 * 		11    	delRowsByIndex     		  删除行通过（index或者indexs）
 * 
 *      12    	setValByKeyAndIndex     设置值(通过键key和index)
 *      13      setValByKeyAndRowId     设置值(通过键key和rowId)
 *      14      resetTableData          重置表格数据(回到上次缓存)
 * 		15      updateTableData         修正表格数据
 * 		18      getValByKeyAndIndex     获取值(通过键key和index)
 * 		19      getValByKeyAndRowId     获取值(通过键key和rowId)
 *      22      setEditableByRowId      设置字段编辑性(通过rowId)
 * 		23		  setEditableByIndex      设置字段编辑性(通过Index)
 * 
 *		24		  pasteRow 				        复制粘贴行
 * 		27		  getCheckedRows          获取选中行数据
 * 		28		  selectAllRows           选中全部行
 * 		29		  selectRowsByIndex       选中某些行(通过index)
 * 		30		  unSelectRowsByIndex     取消选中某些行(通过index)
 * 		31      reverseSelected         反选
 * 		32      filterEmptyRows         移除空行
 * 		33      getAllRowsRemoveKeys    通过传入的键过滤不想要的数据
 * 		34		  toggleRowView           切换视图
 * 		36      getCacheData            获取缓存数据
 *      37      focusRowByIndex         设置选中行(通过index)
 *      38      getMixinMetaData        以模板为基准获取全数据    TODO
 *      40      getFilterRows           筛选表格中指定数据的方法
 *      41      setFiltrateTableData    设置筛选后的表格数据
 *      42      batchChangeTableData    批量修改
 * 		45		  updateDataByIndexs      更新多行的数据（根据index值）
 * 		46      insertDataByIndexs      更新多行的数据（根据index值）    !!!仅供编辑后使用
 * 		47      setRowStatusByIndexs    设置行数据的状态(根据index)
 * 		50		  insertRowsAfterIndex    多行数据插入到index行后
 * 		51      setTdEditByIndex        设置某个单元格为编辑态
 * 		52      setValByKeysAndIndex    设置多个值(通过键key和index)
 *      53      setAllCheckboxAble      设置所有左侧复选禁/启用
 * 		57      setMulTablesData        设置多个表格数据
 *      58      getRowsByIndexs         根据index获取行数据
 *      59      checkTableRequired      根据id检测必输性
 *      61      setAllKeysVal           给表格所用单元格设值
 *      62      setClickRowIndex        设置当前点击行
 *      63      getClickRowIndex        获取当前点击行
 *      64      setSaveData             给表格所用单元格设值
 *      65      getDataByIndex          获取表格中一行的数据(根据index)
 *      66      updateDataByRowId       通过传入的行数据更新数据
 *      70      getCurrentIndex         获得当前行
 *      71      updateDiffDataByRowId   郭祉祺差异更新
 *      72      moveRow                 移动行方法
 *      73      setRowEditByIndex       设置行编辑态
 *   //任亚军整理
 *      69      setModelEdit            设置行侧拉编辑性
 *      68      setColVisibleByKey      设置列显示隐藏
 *      67      setColsValue            设置列数据
 * *    16      openModel               打开侧拉窗
 *      17      closeModel              关闭侧拉窗
 * *    20      hideColByKey            隐藏列(根据key)
 * 		21      showColByKey            显示列(根据key)
 * *    25		  getColValue             获取列数据
 *      26		  setColValue             设置列数据
 * *    39      closeExpandedRow        关闭任何的展开视图
 * *    43      openListView            最大化多表中表体卡片列表
 * 		44 		  openMaxTable            最大化多表体表格
 * * 	48      getMetaValByKey         根据key键获取模板中数据
 * 		49      setColEditableByKey     设置某一列的编辑性
 * *    54		  getModalDataByIndex     获取侧拉数据(根据index)
 * 		55		  showEditAreaKey 		    设置侧拉某控件显隐
 * 		56      toggleCardTable         控制主表的收起展开
 * *    60      setQueryCondition       统一给表体和侧拉添加参照顾虑
 *
 *
 */

function _setGrandTableStore(pageScope, store, config) {
    let { grandGroup } = config;
    let grandTableIds = [];
    if (isArray(grandGroup) && store) {
        grandGroup.forEach(item => {
            grandTableIds.push(item.grandTableId);
            if (!pageScope.cardTableDataSource[item.grandTableId]) {
                let grandTableStore = store.getStore('grandTableStore');
                grandTableStore[item.grandTableId] = TabsTableStore();
                pageScope.cardTableDataSource[item.grandTableId] = grandTableStore[item.grandTableId];
                store.setStore('grandTableStore', grandTableStore, false);
            }
            store.setStore('grandTableIds', grandTableIds, false);
        });
    }
}
// store.statusChange = statusChange;
// store.selectedChange = selectedChange;
// store.tableStatusChanged = tableStatusChanged;

function __handleEventsCallBack(name, func, currentStore) {
    let pageScope = this;
    // 点击事件回调
    if (name === 'onRowClick') {
        return (moduleId, record, outputIndex, e, currentStore) => {
            outputIndex = currentStore.getTrueRowIndex({ rowIndex: outputIndex }, 'normal', 'filter');
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output }, moduleId, record, outputIndex, e);
        };
    }
    // 点击事件回调
    if (name === 'onRowDoubleClick') {
        return (record, outputIndex, e, currentStore) => {
            outputIndex = currentStore.getTrueRowIndex({ rowIndex: outputIndex }, 'normal', 'filter');
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output }, record, outputIndex, e);
        };
    }
    //侧拉回调
    if (name === 'modelSave') {
        return (outputIndex, store) => {
            outputIndex = store.getTrueRowIndex({ rowIndex: outputIndex }, 'normal', 'filter');
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output }, outputIndex);
        };
    }
    if (name === 'modelClose') {
        return () => {
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output });
        };
    }
    if (name === 'modelFooter' || name === 'modelDelRowBefore' || name === 'modelAddRow') {
        return (moduleId, outputIndex, record, store) => {
            outputIndex = store.getTrueRowIndex({ rowIndex: outputIndex }, 'normal', 'filter');
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output }, moduleId, outputIndex, record);
        };
    }
    if (name === 'modelDelRow' || name === 'modelAddRowBefore') {
        return (moduleId, beforeObj) => {
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output }, moduleId, beforeObj);
        };
    }
    //编辑事件回调,
    if (name === 'onAfterEvent') {
        return (params, currentStore) => {
            let {
                currentName,
                moduleId,
                record,
                rowKey,
                rowKeyValue,
                rowIndex,
                attrcode,
                column,
                value = {},
                oldValue = {},
                componentValue,
                event,
                triggerType = 'onChange', // onChange || onBlur
                isTriggerModal = false,
                activeCode
            } = params;

            if (!currentStore) {
                console.warn('not find current currentStore !!!');
            }

            let val = value.value;

            // 为郭祉祺加的针对input类型时,这两个字段的特殊校验,换算率
            if (column.itemtype === 'input' && ['vchangerate', 'vqtunitrate'].includes(attrcode)) {
                let reg = /^([1-9][0-9]*|0)(\.\d{1,})?(\/)(([1-9][0-9]*)(\.\d{1,})?|(\d\.\d{1,}))$/;
                if (val && val.length < 1000 && !reg.test(val)) {
                    if (!isTriggerModal) {
                        currentStore.setCellProps(rowKeyValue, attrcode, { isEdit: false });
                    }
                    currentStore.setCellProps(rowKeyValue, attrcode, { value: null });
                    // toast({
                    //     content: `${json['table0025']}'1/2'!`,
                    //     color: 'danger',
                    //     groupOperation: true,
                    //     isNode: true,
                    //     TextArr: [json['table0022'], json['table0023'], json['table0024']]
                    // });
                    return;
                }
            }

            let changedRows = [];

            // editItem.props.hasOwnProperty('isMultiSelectedEnabled') && (isMul = editItem.props.isMultiSelectedEnabled);
            let isMulti = column.isMultiSelectedEnabled;
            if (column.refcode) {
                let refcode = column.refcode.replace('.js', '');
                if (refcode.startsWith('/')) refcode = refcode.substring(1);
                let refer = window[refcode] && window[refcode].default();
                if (refer && refer.props && refer.props.isMultiSelectedEnabled) {
                    isMulti = refer.props.isMultiSelectedEnabled;
                }
            }

            // 多选参照的判断
            let valueArr = [];
            if (isMulti && oldValue) {
                let oldValArr = isString(oldValue.value)
                    ? oldValue.value.split(',')
                    : isArray(oldValue.value) ? oldValue.value : [oldValue.value];

                let valueArr = isString(value.value)
                    ? value.value.split(',')
                    : isArray(value.value) ? value.value : [value.value];

                valueArr.forEach((val, i) => {
                    changedRows.push({
                        rowid: record.rowid,
                        newvalue: { value: val || '' },
                        oldvalue: { value: oldValArr[0] || '' },
                    });
                });
            } else {
                changedRows.push({
                    rowid: record[rowKey],
                    newvalue: { value: val || '' },
                    oldvalue: { value: oldValue.value || '' },
                });
            }

            valueArr.length > 0 && currentStore.saveRowOldValue(record[rowKey], attrcode, valueArr[0]);

            // 转换一下index // 影响客开 暂不处理
            // if (func !== defaultEmptyFunction) {
            rowIndex = currentStore.getTrueRowIndex({ rowIndex }, 'normal', 'filter');
            // }

            // !isValEqual(changedrows[0].newvalue.value, changedrows[0].oldvalue.value)
            if (column.editAfterFlag && pageScope.handleRelationItems) {
                console.log("打印日志========", "触发编辑关联项", "表格区域:", moduleId, "字段：", attrcode, "changedRows:", changedRows)
                pageScope.handleRelationItems({
                    type: 'table',
                    areaCode: moduleId,
                    key: attrcode,
                    changedrows: changedRows,
                    index: rowIndex,
                    rowid: record[rowKey],
                    record: record,
                    tableMetaCode: activeCode,
                    column: column,
                    callback: () => {
                        let fxParams = {
                            moduleId,
                            record: record,
                            attrcode: attrcode,
                            methods: pageScope.output,
                            rows: currentStore.getViewData(),
                            index: rowIndex,
                            val: column.itemtype === 'refer' || column.itemtype === 'residtxt' ? componentValue : value.value,
                            changedRows,
                            actionTag: triggerType === 'onBlur' ? 'blur' : 'change',
                        };
                        // 客开先不加了
                        beforeAndAfter(
                            pageScope,
                            { moduleId, attrcode: attrcode, fxType: 'onCardTableAfterEvent', fxParams },
                            () => {
                                console.log("打印日志========", "触发业务编辑后", "表格区域:", moduleId, "字段：", attrcode, "changedRows:", changedRows)
                                return func(
                                    {
                                        ...pageScope.props,
                                        ...pageScope.output,
                                    },
                                    moduleId,
                                    attrcode,
                                    column.itemtype === 'refer' || column.itemtype === 'residtxt' ? componentValue : value.value,
                                    changedRows,
                                    rowIndex,
                                    record,
                                    isTriggerModal ? 'model' : 'line',
                                    triggerType === 'onBlur' ? 'blur' : 'change',
                                    currentStore.getViewData(),
                                    event,
                                )
                            });
                    },
                });
            } else {
                let fxParams = {
                    moduleId,
                    record: record,
                    attrcode: attrcode,
                    methods: pageScope.output,
                    rows: currentStore.getViewData(),
                    index: rowIndex,
                    val: column.itemtype === 'refer' || column.itemtype === 'residtxt' ? componentValue : value.value,
                    changedRows,
                    actionTag: triggerType === 'onBlur' ? 'blur' : 'change',
                };
                beforeAndAfter(
                    pageScope,
                    { moduleId, attrcode: attrcode, fxType: 'onCardTableAfterEvent', fxParams },
                    () => {
                        // console.log('beforeAndAfter  编辑后');
                        console.log("打印日志========", "触发业务编辑后", "表格区域:", moduleId, "字段：", attrcode, "changedRows:", changedRows)
                        return func(
                            {
                                ...pageScope.props,
                                ...pageScope.output,
                            },
                            moduleId,
                            attrcode,
                            column.itemtype === 'refer' || column.itemtype === 'residtxt' ? componentValue : value.value,
                            changedRows,
                            rowIndex,
                            record,
                            isTriggerModal ? 'model' : 'line',
                            triggerType === 'onBlur' ? 'blur' : 'change',
                            currentStore.getViewData(),
                            event,
                        )
                    });
            }
        };
    }
    if (name === 'onBeforeEvent') {
        return async params => {
            let {
                moduleId,
                record,
                rowKey,
                rowKeyValue,
                rowIndex,
                attrcode,
                currentStore,
                column,
                value = {},
                event,
                triggerType = 'onChange', // onChange || onBlur
                isTriggerModal = false,
            } = params;
            console.log(record, attrcode, '编辑前');

            // 转换一下index // 影响客开 暂不处理
            // if (func !== defaultEmptyFunction) {
            rowIndex = currentStore.getTrueRowIndex({ rowIndex }, 'normal', 'filter');
            // }

            /***
              * 二开的编辑后事件 --qinbb
              */
            let fxParams = {
                moduleId,
                record,
                attrcode: attrcode,
                methods: pageScope.output,
                val: record.values[attrcode],
                index: rowIndex,
                areaTag: isTriggerModal ? 'model' : 'line',
            };

            return (
                (await beforeAndAfter(
                    pageScope,
                    { moduleId, attrcode: attrcode, fxType: 'onCardTableBeforeEvent', fxParams },
                    () => {
                        // 触发编辑前
                        return func(
                            { ...pageScope.props, ...pageScope.output },
                            moduleId,
                            attrcode,
                            record.values[attrcode],
                            rowIndex,
                            record,
                            isTriggerModal ? 'model' : 'line',
                        )
                    })
                ) !== false
            );
        };
    }
    //复选框选中回调
    if (name === 'onSelected') {
        return (moduleId, record, index, selected, currentStore) => {
            index = currentStore.getTrueRowIndex({ rowIndex: index }, 'normal', 'filter');
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output }, moduleId, record, index, selected);
        };
    }

    if (name === 'onCellKeyDown') {
        return (moduleId, record, index, attrcode, column, currentStore, e) => {
            index = currentStore.getTrueRowIndex({ rowIndex: index }, 'normal', 'filter');
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output }, moduleId, record, index, attrcode, column, e);
        };
    }

    //复选框选中回调
    if (name === 'onBatchSelected') {
        return (moduleId, rows) => {
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output }, moduleId, rows);
        };
    }

    if (name === 'onSelectedAll' || name === 'selectedChange') {
        if (currentStore && name === 'selectedChange') {
            currentStore.selectedChange = func;
        }
        return (moduleId, checked, len) => {
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output }, moduleId, checked, len);
        };
    }
    //页签改变、表格展开收起事件
    if (name === 'onTabChange' || name === 'onHeadAngleToggle') {
        if (currentStore && name === 'onHeadAngleToggle') {
            currentStore.onHeadAngleToggle = func;
        }
        return (moduleId, item) => {
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output }, moduleId, item);
        };
    }

    // 这几个有坑  放在这个store上限制太大  由于store 难先生成  必须的话  就需要业务自己use了
    if (name === 'statusChange') {
        currentStore.statusChange = func;
    }

    if (name === 'tableStatusChanged') {
        currentStore.tableStatusChanged = func;
    }

    if (name === 'onBodyScroll') {
        return (...params) => {
            return func.call(pageScope, { ...pageScope.props, ...pageScope.output }, ...params);
        };
    }

    return func;
}

/**
 * @description: 转换和添加一些属性
 * @param {type} 
 * @return: 
 */
function __handleProps(item, name) {
    if (name === 'moduleId') {
        item.name = item.moduleId;
    }

    if (name === 'isAddRow') {
        item.autoAddRow.enabled = item.isAddRow;
    }

    if (name === 'addRowCallback') {
        item.autoAddRow.callback = item.addRowCallback.bind(this);
    }

    if (name === 'addRowDefaultValue') {
        item.autoAddRow.defaultValue = item.addRowDefaultValue;
    }

    if (name === 'rowClassList') {
        item.rowClassnName = item.rowClassList;
    }

    if (name === 'height') {
        item.bodyStyle = { minHeight: item.height || 300 };
        item.scroll = {
            x: true,
            y: item.height || 300,
        };

        // 避免行高的传递
        delete item.height;
    }

    if (name === 'setCellClass') {
        item.cellClassName = (record, rowIndex, attrcode, currentStore) => {
            rowIndex = currentStore.getTrueRowIndex({ rowIndex: rowIndex }, 'normal', 'filter');
            return isFunction(item.setCellClass) && item.setCellClass(rowIndex, record, attrcode);
        }
    }

    if (name === 'alloweFilter') {
        item.high_filter = { alloweFilter: item.alloweFilter };
    }
}

/**
 * @description: 添加一些默认方法和参数
 * @param {type} 
 * @return: 
 */
function __addDefautProps(item) {
    // 添加事件
    item.onChange = ({ rowKey, rowKeyValue, rowIndex, attrcode, value, column, store, e }) => {
        // 为郭祉琪加的针对input类型时,这两个字段的特殊校验,换算率
        if (column.itemtype === 'input' && ['vchangerate', 'vqtunitrate'].includes(column.attrcode)) {
            // eslint-disable-next-line no-useless-escape
            let reg = /^[0-9\/\.]+$/;
            if (value && !reg.test(value)) {
                //只能输入数字和/
                return;
            }
        }
        //胡杰李忠博加
        if (isFunction(item.inputChange)) {
            rowIndex = store.getTrueRowIndex({ rowIndex: rowIndex }, 'normal', 'filter');
            let callbackValue = item.inputChange.call(this, {
                index: rowIndex,
                item: column,
                valueChange: value.value,
            });
            if (!callbackValue) {
                return;
            }
        }
    };

    // 自动增行的参数
    item.autoAddRow = item.autoAddRow || {};

    // 给 onAfterEvent 事件设置一个默认值  
    // 因为 onAfterEvent 里处理了默认逻辑 避免业务没有传参数时不调用
    item.onAfterEvent = item.onAfterEvent || defaultEmptyFunction;
    item.onBeforeEvent = item.onBeforeEvent || defaultEmptyFunction;
}

/**
 * config: {key: config}
 * @description: 统一处理一下所有的方法回调
 * @param {type} 
 * @return: 
 */
function handleEventsCallBack(config = {}, stores, moduleId) {
    let pageScope = this;
    for (let key in config) {
        let item = config[key]; // 获取所有表配置项
        if (isObject(item)) {
            // onAfterEvent  autoAddRow  onChange
            __addDefautProps.call(pageScope, item);
            for (let ikey in item) {
                // 获取所有方法的

                //孙表
                if (ikey === 'grandGroup' && isArray(item[ikey])) {
                    item[ikey].forEach(elem => {
                        let grandConfig = elem.grandTableConfig;
                        let moduleId = elem.grandTableId;
                        handleEventsCallBack.call(pageScope, { [moduleId]: grandConfig }, {}, moduleId);
                    });
                }

                // 先处理一些属性转换
                __handleProps.call(pageScope, item, ikey);

                if (isFunction(item[ikey])) {
                    item[ikey] = __handleEventsCallBack.call(pageScope, ikey, item[ikey], stores[key], moduleId);
                }
            }
        }
    }
    return config;
}

export function createCardTable(moduleId, config = {}) {
    let pageScope = this,
        store = pageScope.cardTableDataSource[moduleId];

    let newConfig = Object.assign({}, config);
    if (!store) {
        store = useCardTable();
        pageScope.cardTableDataSource[moduleId] = store;
        // 如果有缓存值 则做一下  Store 的赋值操作  把setTableData里的else操作提到这里了  估计可以去除
        if (pageScope.myTableData[moduleId]) {
            store.setTableData(pageScope.myTableData[moduleId]); // TODO 验证
            store.setTableProps('focusIndex', -1, false);
            delete pageScope.myTableData[moduleId];
        }
    }

    // console.log('cardTableStore', store);

    // 解析事件
    if (isObject(newConfig.multiConfig)) {
        for (let name in newConfig.multiConfig) {
            pageScope.cardTableDataSource[name] = pageScope.cardTableDataSource[name] || useCardTable();
            store.addTabStore(name, pageScope.cardTableDataSource[name]);
        }

        // 可以放到事件解析里做
        let stores = store.getTabsStores(); // 这里meta 不一定有所以 不一定有Store

        newConfig.multiConfig = handleEventsCallBack.call(pageScope, newConfig.multiConfig, stores, moduleId);
    }

    if (isObject(newConfig.multiConfig) && !newConfig.multiConfig[moduleId]) {
        newConfig.multiConfig[moduleId] = newConfig;
    }

    // 解析事件 不要放在else 里  可以单多来源都兼容
    newConfig =
        handleEventsCallBack.call(pageScope, { [moduleId]: newConfig }, { [moduleId]: store }, moduleId)[moduleId] ||
        newConfig;

    // 挂载孙表store
    _setGrandTableStore(
        pageScope,
        store,
        !isObject(newConfig.multiConfig) || !newConfig.multiConfig[moduleId] ? newConfig : newConfig.multiConfig[moduleId],
    );

    //console.log(config, config.multiConfig);

    // renderItem 适配  TODO 多来源时是否需要区分id
    let renderItems = {};
    if (pageScope.state.renderItem && pageScope.state.renderItem.table) {
        renderItems = pageScope.state.renderItem.table || {};
    }
    store.setConfig(newConfig)
    // console.log(newConfig);

    return (
        <CardTable
            // 多语加载完成事件
            afterLangload={(json, store) => {
                // console.log(this, json);
                pageScope.cardTableDataSource._langJson = json || {};
            }}
            onCellHyperlinkTo={(record, attrcode, value, tableId) => {
                linkTo(pageScope.state.meta.pageid, tableId, attrcode, value.value);
            }}
            name={moduleId}
            store={store}
            config={newConfig}
            multiConfig={newConfig.multiConfig || {}}
            renderItems={renderItems}
            mountChildrenStore={(id, callback) => {
                // 挂载多页签的 store
                pageScope.cardTableDataSource[id] = callback(pageScope.cardTableDataSource[id]);
                // 挂载孙表store
                newConfig.multiConfig &&
                    newConfig.multiConfig[id] &&
                    _setGrandTableStore(pageScope, pageScope.cardTableDataSource[id], newConfig.multiConfig[id]);
            }}
            cardTableComponentUpdate={(props, data) => {
                // console.log('cardTableComponentUpdate', props, data);
                getCheckedExtendMethod(pageScope, 'updateEditTable')({ pageScope, data, props, useOn: 'create' });
            }}
            // 快捷键 解耦
            getHotKeyConfig={() => {
                return {
                    pageScope: pageScope,
                    autoFocusDeferred: this.autoFocusDeferred,
                    meta: this.state.meta,
                    withHotKeyChange: this.withHotKeyChange,
                    orderOfHotKey: this.orderOfHotKey,
                    onLastFormEnter: config.onLastFormEnter,
                };
            }}
        />
    );
}

/**
 * bbq
 * 检测组件对象是否存在
 * @param {*} id
 */
const existenceCheck = function (id) {
    let cardTableStore = this.cardTableDataSource[id];
    if (!cardTableStore) {
        warningOnce(false, `所操作的表格中无ID为${id}的数据`);
        return;
    }
    return cardTableStore;
};

/**
 * bbq
 * 检测行的index是否存在
 * @param {*} id
 */
const indexCheck = function (id, index, view = 'normal') {
    let cardTableStore = existenceCheck.call(this, id);
    if (!cardTableStore) {
        return;
    }
    let viewData = cardTableStore.getViewData(view);
    if (isNaN(index) || index > viewData.length || index < 0) {
        warningOnce(index, '传入的第二个参数为行index值，须为大于等于0且小于等于总行数减一的整数');
        return -1;
    }
    return index;
};

// 多来源 设置布局方式
export function setLayout(moduleId, layout) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.setLayout(layout);
}

// 多来源 获取布局方式
// export function getLayout(moduleId) {
//     let store = existenceCheck.call(this, moduleId);
//     if (!store) {
//         return;
//     }
//     return store.getLayout(moduleId);
// }

// 多来源 设置全部数据
/**
 * 1、设置表格数据
 * @param  moduleId      表格id
 * @param  data         数据
 * @param  callback     设置后回调,
 * 这里注意设置值的时候,表格如果没有数据,也要设置默认值 { rows: [] }
 * data数据格式 例如: {
 * revecont_b: { rows:[{values: { num: {value: 1111}, fconfirmpoint: {value: 1, display: '人工'} }}] },
 * material: { rows:[{values: { num: {value: 1111}, fconfirmpoint: {value: 1, display: '人工'} }}] }
 * }
 */
export function setTabsTableData(moduleId, data) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.setTabsTableData(data, true, moduleId);
}

/**
 * 3、取消所有页签数据
 * @param  moduleId  表格id
 */
export function resetTabsTableData(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    store.resetTabsTableData(false, moduleId);
}

/**
 * 2、获取所有表格数据
 * @param  moduleId  表格id
 */
export function getTabsTableData(moduleId) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }
    return store.getTabsTableData(moduleId);
}

/**
 * 4、设置多个主表状态
 * @param  status tableId和表格状态组成的对象
 * { revecont_b: 'edit', revecont_b1: 'browse' }
 */
export function setTabsTableStatus(moduleId, status = {}) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return;
    }

    let _this = this;

    return store.setTabsTableStatus(status, false, moduleId, (tableId, mainStore, oldStatus) => {
        // 切换成浏览态的时候把所有的toast清掉
        if (oldStatus !== 'browse' && status[tableId] == 'browse') {
            clearOneTypeToast('danger');
            clearRequiredStatus.call(_this || this, tableId);
        }
    });
}

//设置页签显示隐藏
export function setTabsTableShow(mainId, flagObj) {
    let store = existenceCheck.call(this, mainId);
    if (!store) {
        return;
    }
    return store.setTabsTableShow(mainId, flagObj, !this.isUpdatePage);
}
//设置页签激活
export function setTabsTableActive(mainId, childId) {
    let store = existenceCheck.call(this, mainId);
    if (!store) {
        return;
    }
    return store.setTabsTableActive(mainId, childId, !this.isUpdatePage);
}

//获取当前激活页签
export function getTabsTableActive(mainId) {
    let store = existenceCheck.call(this, mainId);
    if (!store) {
        return;
    }
    return store.getTabsTableActive(mainId);
}

/**
 * 工具api 区域
 */

/**
 * 校验页签的数据 他是一个工具函数
 * @param {*} items 单个页签的模版
 * @param {*} rows 单个页签的行数据
 * @param {*} allToast 当个页签检验结果的容器
 */
function checkTabRequired({ items, rows, allToast, rowKey, json }) {
    let validateFailed = {}, firstValidateFaild = [];

    items.forEach(item => {
        //将数据中没有的列附上空对象，保证校验的准确性
        const { attrcode } = item;
        rows.forEach(row => {
            const { values } = row;
            if (isUndefined(values[attrcode])) {
                values[attrcode] = {};
            }
        });
    });
    rows.forEach((row, index) => {
        const rowToast = []; //保存每行的检验提示信息
        const { values } = row;
        //便利校验是否没有输入必输项
        Object.entries(values).forEach(([colKey, colValue]) => {
            let value = colValue.value;
            if (isString(value)) {
                value = value.trim();
            }
            if (isWrong(value)) {
                items.forEach(item => {
                    const { attrcode, required, label } = item;
                    if (required && attrcode === colKey) {
                        rowToast.push(label);
                        // 有就是有没有就是没有  给页签上判断用
                        validateFailed[row[rowKey]] = validateFailed[row[rowKey]] || {};
                        validateFailed[row[rowKey]][item.attrcode] = item.attrcode;
                        if (!firstValidateFaild.length) {
                            firstValidateFaild = [row[rowKey], item.attrcode];
                        }
                    }
                });
            }
        });

        if (rowToast.length > 0) {
            allToast[`${json && json['table_first']}${index + 1}${json && json['table_row']}`] = rowToast;
        }
    });

    return { tabToast: allToast, validateFailed, firstValidateFaild };
}

/**
 * TODO
 * 5、校验多页签表格
 * @param {*} moduleId 表格id
 */
export function checkAllTabsRequire(moduleId, autoFocus = true) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) {
        return false;
    }

    const meta = store.getMeta() || this.state.meta;
    const json = this.cardTableDataSource._langJson || {};
    const { gridrelation, containerrelation } = meta;

    // 校验
    let allValidateFailed = {};
    let allFirstValidateFaild = {};

    /**
     * 必输项不全的页签
     */
    const errorTabIds = [];
    /**
     * 提示信息集合
     */
    const label = {};
    /**
     * 多页签所有的主表列表
     */
    let metaTabs = containerrelation[moduleId];
    let tabs = [];
    if (Array.isArray(metaTabs) && metaTabs.length) {
        metaTabs.forEach(item => {
            tabs.push(...item);
        })
    }
    /**
     * 构造用校验的数据结构
     */
    const checkedMusters = tabs.reduce((checkedMuster, tabId) => {
        const currentRelation = gridrelation[tabId];
        const currentStore = existenceCheck.call(this, tabId);
        if (!currentStore || !currentRelation) {
            return checkedMuster;
        }
        const rows = currentStore.getViewData('filter');
        const tabRelation = currentRelation.initialTabRelation || currentRelation.tabRelation;

        const tabRelationMuster = tabRelation.reduce((metaConfig, tabId) => {
            const name = meta[tabId].name;
            let items = currentStore.getArrayMeta(tabId) || [];
            items = items.filter(item => item.visible);
            metaConfig[tabId] = { name, items };
            return metaConfig;
        }, {});
        checkedMuster[tabId] = { tabRelationMuster, rows };
        return checkedMuster;
    }, {});
    for (let tableId in checkedMusters) {
        const tabItem = checkedMusters[tableId];
        let store = existenceCheck.call(this, tableId);
        const rowKey = store ? store.getTableProps('rowKey') : 'rowid';
        allValidateFailed[tableId] = allValidateFailed[tableId] || {};
        allFirstValidateFaild[tableId] = allFirstValidateFaild[tableId] || [];

        const { tabRelationMuster, rows } = tabItem;
        Object.entries(tabRelationMuster).forEach(([tabId, config]) => {
            const { name, items } = config;
            const { tabToast, validateFailed, firstValidateFaild } = checkTabRequired({ items, rows, allToast: {}, rowKey, json });

            allValidateFailed[tableId][tabId] = allValidateFailed[tableId][tabId] || {};

            if (Object.keys(tabToast).length) {
                allValidateFailed[tableId][tabId] = validateFailed;
                // console.log(validateFailed, firstValidateFaild);
                if (!allFirstValidateFaild[tableId].length) {
                    allFirstValidateFaild[tableId] = [tabId, ...firstValidateFaild];
                }
                errorTabIds.push(tabId);
                label[`${name}(${json && json['table008']})`] = tabToast;
            }
        });
    }

    if (errorTabIds.length) {
        // validateFailed, firstValidateFaild // 四层数据
        console.log(allValidateFailed, allFirstValidateFaild);
        for (let tableId in allValidateFailed) {
            const store = existenceCheck.call(this, tableId);
            const table = allValidateFailed[tableId];
            let isRefresh = false;
            if (store && table) {
                console.log(store);
                // 缓存状态
                store.setCache('validateFailed', allValidateFailed);
                store.setCache('firstValidateFaild', allFirstValidateFaild);
                for (let tabId in table) {
                    const rows = table[tabId];
                    if (rows) {
                        for (let rowKeyValue in rows) {
                            if (rowKeyValue) {
                                let row = rows[rowKeyValue];
                                if (row) {
                                    for (let attrcode in row) {
                                        store.setCellProps(rowKeyValue, attrcode, { validated: false }, false);
                                    }
                                }
                                isRefresh = true;
                            }
                        }

                    }
                }
                isRefresh && store.refresh();
            }
        }

        // 自动聚焦到首个
        if (allFirstValidateFaild && autoFocus) {
            const tableIdList = tabs ? (isArray(tabs) ? tabs : [tabs]) : tabs || this.orderOfHotKey;

            let tabSelector = null,
                cellSelector = null;
            // 找到第一个
            tableIdList.some(tableId => {
                let cellInfo = allFirstValidateFaild[tableId];
                if (cellInfo && cellInfo.length > 0) {
                    tabSelector = `#js_lightTabs_header_${tableId} [name="${cellInfo[0]}"] a`;
                    cellSelector = `.table-cell-wrapper[cellselectorkey="${cellInfo[1]}@${cellInfo[2]}"] [tabindex="0"]`;
                    return true;
                }
            });

            const tabElement = document.querySelector(tabSelector);

            tabElement && tabElement.click();

            setTimeout(() => {
                const cellElement = document.querySelector(cellSelector);
                if (cellElement) {
                    cellElement.ncExecuteFocus = true;
                    cellElement.focus();
                    cellElement.ncExecuteFocus = false;
                }
            }, 300)

            console.log(tableIdList, allFirstValidateFaild, tabSelector, cellSelector);
        }
        const errorContent = Object.entries(label).reduce((errorContent, [tabName, rowsToast]) => {
            errorContent.push(`${tabName} :`);
            Object.entries(rowsToast).forEach(([rowName, rowToast]) => {
                errorContent.push(`${rowName}: ${rowToast.join(', ')}`);
            });
            return errorContent;
        }, []);
        toast({
            content: `${json && json['table_no_empty']}：`,
            color: 'danger',
            TextArr: [json['table0022'], json['table0023'], json['table0024']],
            groupOperation: true,
            groupOperationMsg: errorContent,
            mark: 'edit-table-toastx',
        });
        store.setStore('errorTabIds', errorTabIds);
        errorContent && errorContent.length > 0 && store.setCache('errorContent', errorContent);
        return false;
    } else {
        store.setStore('errorTabIds', []);
        store.setCache('errorContent', []);
        return true;
    }
}

// 处理旧值函数 bbq
export function saveChangedRowsOldValue(moduleId, rowid, attrcode, value) {
    let metaStore = existenceCheck.call(this, moduleId);
    if (!metaStore) {
        return;
    }
    metaStore.saveRowOldValue(rowid, attrcode, value);
}

/**
 * bbq
 * 对旧值处理的函数, 这原来是标识是index,但是增加筛选后,
 * 对筛选结果进行编辑,使用index作为标识存取新旧值,会导致存取值错误,
 * 所以改成现在rowid为标识, 但是对旧值处理的方法,已经对外暴露,如果出现问题,需要业务组配合修改,
 * 将对新旧值的处理换成rowid为标识
 * */
export function getChangedRowsOldValue(moduleId, rowid, attrcode) {
    let metaStore = existenceCheck.call(this, moduleId);
    if (!metaStore) {
        return;
    }
    return metaStore.getRowOldValue(rowid, attrcode);
}

// 删除旧值函数 bbq
export function delChangedRowsOldValue(moduleId, rowid, attrcode) {
    let metaStore = existenceCheck.call(this, moduleId);
    if (!metaStore) {
        return;
    }
    metaStore.deleteRowOldValue(rowid, attrcode);
}

/**
 * bbq
 * 设置表格数据，同时缓存数据，缓存数据用于取消时调用
 * @param  tableId   表格主区域编码
 * @param  data      传入的data数据
 * @param  isCache   是否缓存  默认缓存
 * @param  isTop     是否回到顶部
 * @param  autoFocus 表格重新渲染后是否需要聚焦到之前聚焦的元素 主要解决快捷键连续性操作的问题
 * @param  resetSort 重置主表的排序标记
 */
export function setTableData(tableId, data, callback, isCache = true, isTop = false, autoFocus = false) {
    if (!data || !data.rows || !isArray(data.rows)) {
        warningOnce(data, '传入的第二个参数为所设置的数据，数据格式是对象，且有个rows属性，rows的内容是数组');
        return;
    }

    let store = existenceCheck.call(this, tableId);
    if (!store) {
        // 控制设值定位后将参数至成默认值
        this.myTableData[tableId] = data;
        isFunction(callback) && callback.call(this, { ...this.props, ...this.output }, tableId, data);
        return;
    }

    // 设置表格数据
    data = store.setTableData(data, {
        isCache,
        isTop,
        shouldForceUpdate: !this.isUpdatePage,
    });

    // 调用selectedChange事件
    isFunction(store.selectedChange) && store.selectedChange({ ...this.props, ...this.output }, tableId, 0);
    //将回调打开，看看有没有问题 zhanghengh  18/7/30
    isFunction(callback) && callback.call(this, { ...this.props, ...this.output }, tableId, data);
}

/**
 * bbq
 * 2、获取表格状态
 * @param  tableId   meta的id号
 */
export function getStatus(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    return store.getTableProps('status');
}

//  清除校验状态
export function clearRequiredStatus(tableId, includeAllArea = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    let validateFailed = store.getCache('validateFailed') || {};

    for (let tableId in validateFailed) {
        const mainStore = existenceCheck.call(this, tableId);
        const table = validateFailed[tableId];
        // let isRefresh = false;
        if (mainStore && table) {
            // console.log(mainStore);
            // 缓存状态
            // mainStore.setCache('validateFailed', allValidateFailed);
            // mainStore.setCache('firstValidateFaild', allFirstValidateFaild);
            for (let tabId in table) {
                const rows = table[tabId];
                if (rows) {
                    for (let rowKeyValue in rows) {
                        if (rowKeyValue) {
                            let row = rows[rowKeyValue];
                            if (row) {
                                for (let attrcode in row) {
                                    mainStore.setCellProps(rowKeyValue, attrcode, { validated: true }, false);
                                }
                            }
                            // isRefresh = true;
                        }
                    }

                }
            }
            // isRefresh && mainStore.refresh();
            mainStore.setCache('validateFailed', null);
            mainStore.setCache('firstValidateFaild', null);
        }

        // 去除校验状态
        store.setCache('validateFailed', null);
        store.setCache('firstValidateFaild', null);
    }

    if (includeAllArea && store) {
        // 孙表的
        let grandTableStore = store.getStore('grandTableStore') || {};
        let grandValidateFailed = store.getCache('grandValidateFailed') || {};

        for (let grandTableId in grandTableStore) {
            let grandStore = grandTableStore[grandTableId];

            for (let rowid in grandValidateFailed) {
                // 记录校验状态
                const validateFailed = grandValidateFailed[rowid];
                if (grandStore && validateFailed) {
                    // for (let tableId in validateFailed) {
                    const table = validateFailed[grandTableId];
                    // let isRefresh = false;
                    if (table) {
                        for (let tabId in table) {
                            const rows = table[tabId];
                            if (rows) {
                                for (let rowKeyValue in rows) {
                                    if (rowKeyValue) {
                                        let row = rows[rowKeyValue];
                                        if (row) {
                                            for (let attrcode in row) {
                                                // debugger;
                                                grandStore.setCellProps(rowKeyValue, attrcode, { validated: true }, false);
                                            }
                                        }
                                        // isRefresh = true;
                                    }
                                }

                            }
                        }

                        // 缓存状态
                        grandStore.setCache('validateFailed', null);
                        // isRefresh && mainStore.refresh();
                    }
                    // }
                }
            }

        }

        store.setCache('grandValidateFailed', null);
    }

}

/**
 * bbq
 * 3、设置表格状态
 * @param  tableId   meta的id号
 * @param  status    表格状态：edit,browse其一
 */
export function setStatus(tableId, status) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    let oldStatus = store.getTableProps('status') || 'browse';
    // 切换成浏览态的时候把所有的toast清掉
    if (oldStatus !== 'browse' && status == 'browse') {
        clearOneTypeToast('danger');
        clearRequiredStatus.call(this, tableId);
    }

    if (status === 'edit') {
        store.setStore('expandedRowKeys', [], !this.isUpdatePage);
    }

    store.setTableStatus(status, { shouldForceUpdate: !this.isUpdatePage });
    // TODO props 传递有些问题
    isFunction(store.tableStatusChanged) && store.tableStatusChanged(tableId, { props: store, status });
}

/**
 * bbq
 * 4、获取表格内所有行数据（包含删除项目）
 * @param  tableId   meta的id号
 * @return []/false  返回数组或者false
 * @warn   // TODO   校验可能存在风险，规则不全
 * mofify by zhanghengh @18/05/05  在内部判断筛选后和没有筛选获得全数据等的情况
 * mofify by zhanghengh @18/05/16  对之前的逻辑进行修改，减少了不必要的代码
 */
export function getAllRows(tableId, isDeepCopy = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    return store.getAllRows(isDeepCopy);
}

/**
 * bbq
 * 5、获取表格内可见列表数据 （排除删除项目）
 * @param  tableId   meta的id号
 * @return []/false  返回数组或者false
 */
export function getVisibleRows(tableId, isDeepCopy = false, isFilter = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    return store.getVisibleRows(isDeepCopy, isFilter ? 'normal' : 'filter');
}

/**
 * bbq
 * 6、获取表格修改后数据 （排除删除项目）
 * @param  tableId   meta的id号
 * @return []/false  返回数组或者false
 */
export function getChangedRows(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    return store.getChangedRows();
}

/**
 * bbq
 * 7、根据id获取表格中所有(可见)的行的数量
 * @param  tableId   meta的id号
 * @param  flag      flag为true包含删除项目  flag不传或者false不包含删除项目
 */
export function getNumberOfRows(tableId, flag) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return 0;
    }
    return flag ? getAllRows.call(this, tableId, false).length : getVisibleRows.call(this, tableId, false).length;
}

// bbq
export function getAllData(tableId, flag = true) {
    let rows = getAllRows.call(this, tableId, flag);
    let data = {
        areaType: 'table',
        rows,
        areacode: null,
    };
    return data;
}

/**
 * bbq
 * 控制行展开收起
 * @param  tableId   meta的id号
 * @param  record    行data
 */
export function toggleRowView(tableId, record) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    return store.toggleRowView(record);
}

/**
 * bbq
 * 9、新增行(通过index值)
 * @param  tableId   meta的id号
 * @param  index     增加行的位置index   0为行首 不传为和len为行尾部
 * @param  defaultValue      新增的默认defaultValue 格式：{key: {display: '', scale: 0, value: ''}, key2: {display: '', scale: 0, value: ''}}
 * mofify by zhanghengh  增加flag标识位，判断是否为多表头，默认是false，不是多表头
 */
export function addRow(tableId, index, defaultValue, autoFocus = true, callback, flag = false, isAutoAddRow, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    //判断index是否存在
    index = isUndefined(index) ? store.getViewData('filter').length : index;

    if (!defaultValue) defaultValue = {};

    // flag 暂时没有用 内部会处理多表头
    store.addTableRow(index, defaultValue, !this.isUpdatePage && shouldForceUpdate, {
        view: 'filter',
        autoFocus,
        isAutoAddRow,
        callback: () => {
            /*
             * 如果table第一次加载时数据是空，业务组调用addRow Api生成时没有在KeyByModuleId中生成数据
             * 所以再此手动调用 createByModuleType 方法
             * TODO 快捷键相关
             * */
            if (keyByModuleId[tableId] === undefined) {
                // 应该不需要， createByModuleType操作已经放到componentDidUpdate中了 addRow 应该也会触发
                // createByModuleType['table'](myCardScope.props);
            }
            // 给每个控件赋初始值   TODO  好多地方需要这个 比如  reset的时候  TODO
            // const myCardTable = this.myTable[tableId].state.table;
            //const myCardTable = store;
            isFunction(callback) && callback.call(this, tableId, index, { rows: store.getViewData() });

            // TODO 快捷键
            // clearOtherTableRowSelectedStatus({ pageScope: this, moduleId: tableId });

            // TODO 全局事件
            PubSub.publish(HEIGHTTOSCROLL, true);
        },
    });

    // isFunction(store.statusChange) && store.statusChange(status);
}

/**
 * bbq
 * 10、根据rowId的删除行方法
 * 规则：1、当state == ‘2’    新增        这时候直接删除数组就可以了
 *      2、当state == ‘0/1’  原始/修改   这时候数组的内容不能删除，把state置位3
 *      3、当state == ‘3’    已删除      这时候数组的内容不会显示，所以没删除功能
 * 解决思路： 把不是新增的 置位3 并push到结尾，其余的按index删除即可。 控制index的最大取值。
 * 注意点：   _selectedChangeFn方法调用
 * @param  tableId   meta的id号
 * @param  rowid     删除的行rowId
 */
export function delRowByRowId(tableId, rowid, callback, isDeepDel) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    store.deleteTableRows({ rowKeyValues: rowid }, isDeepDel, false);
    // 清理一下错误状态
    store.clearValidated();
    // this.myTable[tableId].state.table  store.getStore() // TODO
    isFunction(callback) && callback.call(this, tableId, rowid, store.getStore());
    PubSub.publish(HEIGHTTOSCROLL, true); // TODO
}

/**
 * bbq
 * 11、根据index的删除行方法
 * @param  tableId   meta的id号
 * @param  Index     删除的行号-1
 */
export function delRowsByIndex(tableId, index, callback, isDeepDel) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    let delRowidList = store.deleteTableRows({ rowIndexs: index }, isDeepDel, false, undefined, 'filter');
    // 清理一下错误状态
    store.clearValidated();
    // TODO  store.getStore()   this.myTable[tableId].state.table  store.getStore()
    isFunction(callback) && callback.call(this, tableId, index, store.getStore(), delRowidList);
    PubSub.publish(HEIGHTTOSCROLL, true); // TODO
}

/**
 * bbq
 * 12、根据index设置表格某行某个字段值  0代表第一行  行序号可有可没有
 * @param  tableId   meta的id号
 * @param  index     行序号-1，从0开始
 * @param  key       columns的键值
 * @param  value     需要设置的value值
 * @param  display   需要设置的display值
 * @param  scale     需要设置的scale值
 * mofify by zhanghengh  增加flag标识位，判断是否为多表头，默认是false，不是多表头
 * mofify by zhanghengh 这里现在只进行了重新赋值，没有进行更新页面，借助其他的更新一起更新，有问题的话，提供统一更新方法
 * mofify by zhanghengh 将批改数据重新缓存
 *
 *
 */
export function setValByKeyAndIndex(
    tableId,
    index,
    key,
    cellValue,
    callback,
    flag = false,
    isUpdateBatch = false,
) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    let allRows = store.getData();
    if (!allRows.length) {
        console.warn('empty data cant setting value');
        return;
    }

    // flag 没用了 多表头在内部处理
    store.setValByKeyAndIndex(
        index,
        key,
        cellValue,
        { isSetEdit: true, isUpdateBatch, shouldForceUpdate: !this.isUpdatePage, view: 'filter' },
    );
}

/**
 * bbq
 * 13、根据rowId设置表格某行某个字段值
 * @param  tableId   meta的id号
 * @param  rowid     行rowId
 * @param  key       columns的键值？？
 * @param  value     需要设置的value值
 * @param  display   需要设置的display值
 * @param  scale     需要设置的scale值
 * mofify by zhanghengh  增加flag标识位，判断是否为多表头，默认是false，不是多表头
 * mofify by zhanghengh 这里现在只进行了重新赋值，没有进行更新页面，借助其他的更新一起更新，有问题的话，提供统一更新方法
 * mofify by zhanghengh 将批改数据重新缓存
 */
export function setValByKeyAndRowId(
    tableId,
    rowid,
    key,
    cellValue,
    callback,
    flag = false,
    isUpdateBatch = true,
) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    // flag 没用了 多表头在内部处理
    store.setValByKeyAndRowId(rowid, key, cellValue, { isUpdateBatch, shouldForceUpdate: false });
}

/**
 * bbq
 * 14、取消编辑，回到上次状态
 * @param  tableId   meta的id号
 * change zhanghengh 18/5/5 更改取消方式
 * change zhanghengh 18/5/16 将取消回原来的代码，并将ALL_DATA指为null
 */
export function resetTableData(tableId, callback) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    clearOneTypeToast('danger');

    store.resetTableData({ shouldForceUpdate: !this.isUpdatePage });
    // TODO
    // callback && typeof callback === 'function' && callback.call(this, tableId, this.myTable[tableId].state.table);
}

/**
 * bbq
 * 15、更新表格数据
 * @param  tableId   meta的id号
 * @param  data      后台返回的data 需要有rowId 删除的数据不要返回
 */
export function updateTableData(tableId, data, callback, isCache = true) {
    if (!isObject(data) || !isArray(data.rows)) {
        warningOnce(data, '传入的第二个参数为所设置的数据，数据格式是对象，且有个rows属性，rows的内容是数组');
        return;
    }

    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    store.updateTableData(data, isCache, !this.isUpdatePage);

    // 调用selectedChange事件
    if (store.selectedChange) {
        store.selectedChange({ ...this.props, ...this.output }, tableId, 0);
    }
}

/**
 * bbq
 * 18、根据index获取表格某行某个字段值  0代表第一行  行序号可有可没有
 * @param  tableId   meta的id号
 * @param  index     行序号-1，从0开始
 * @param  key       columns的键值
 * mofify by zhanghengh  增加flag标识位，判断是否为多表头，默认是false，不是多表头
 */
export function getValByKeyAndIndex(tableId, index, key, flag = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    return store.getValByKeyAndIndex(index, key, 'filter');
}

/**
 * bbq
 * 19、根据rowId设置获取某行某个字段值
 * @param  tableId   meta的id号
 * @param  rowid     行rowId
 * @param  key       columns的键值
 * @param  value     需要设置的value值
 * @param  display   需要设置的display值
 * @param  scale     需要设置的scale值
 * mofify by zhanghengh  增加flag标识位，判断是否为多表头，默认是false，不是多表头
 */
export function getValByKeyAndRowId(tableId, rowid, key, flag = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    return store.getValByKeyAndRowKey(rowid, key);
}

/**
 * bbq
这个有方法用edittable的相同方法替换他了
 * 22、通过rowId和键设置表格某行某个字段编辑性
 * @param  tableId   meta的id号
 * @param  rowid     rowid
 * @param  key       columns的键值
 * @param  flag      开关true/false
 * mofify by zhanghengh  增加flag标识位，判断是否为多表头，默认是false，不是多表头
 */
export function setEditableByRowId(tableId, rowid, key, flag = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    store.setCellsProps({
        rowKeyValues: [rowid],
        attrcodes: key === 0 ? undefined : key,
        value: { disabled: !flag },
        shouldForceUpdate: !this.isUpdatePage,
    });
}

/**
 * bbq
 * 23、通过Index和键设置表格某行某个字段编辑性
 * @param  tableId   meta的id号
 * @param  index     行序号-1
 * @param  key       columns的键值
 * @param  flag      开关true/false
 * mofify by zhanghengh  增加flag标识位，判断是否为多表头，默认是false，不是多表头
 */
export function setEditableByIndex(tableId, index, key, flag = false) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    store.setCellsProps({
        rowIndexs: index,
        attrcodes: key === 0 ? undefined : key, // z支持数组
        value: { disabled: !flag },
        shouldForceUpdate: !this.isUpdatePage,
        view: 'filter',
    });
}

/**
 * bbq
 * 24、复制粘贴行，默认粘贴到该行下方
 * @param  tableId   meta的id号
 * @param  index     行序号index
 * @param  keys      不去复制的键值
 */
export function pasteRow(tableId, index, keys) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    let copy = {};

    if (index === undefined) {
        index = 0;
        copy = store.getRows({ rowIndexs: [0], view: 'filter' })[0];
    } else {
        // filter => normal
        index = store.getTrueRowIndex({ rowIndex: index }, 'filter');
        copy = store.getRows({ rowIndexs: [index], view: 'filter' })[0];
        index = index + 1;
    }

    store.pasteRow({ copyRow: copy, index, excludeKeys: keys, view: 'filter' });
}

/**
 * bbq
 * 27、获取选中行数据
 * @param  tableId   meta的id号
 */
export function getCheckedRows(tableId) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return [];
    }
   
    return store.getSelectedRows(false, 'filter');
}

/**
 * TODO
 * 28、选中所有行
 * @param  tableId   meta的id号
 * @param  checked   开关
 */
export function selectAllRows(tableId, checked) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return [];
    }

    return store.setAllRowsSelected(checked, !this.isUpdatePage, () => {
        let newLen = store.getSelectedRows(false, 'filter').length;
        isFunction(store.selectedChange) && store.selectedChange({ ...this.props, ...this.output }, tableId, newLen);
    });
}

/**
 * bbq
 * 29、选中某些行(通过index)
 * @param  tableId   meta的id号
 * @param  index     行序号index
 */
export function selectRowsByIndex(tableId, index) {
    // TODO  加入index的极限值判断
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    store.setRowsSelected({ rowIndexs: index, view: 'filter' }, true);

    let newLen = store.getSelectedRows(false, 'filter').length;
    isFunction(store.selectedChange) && store.selectedChange({ ...this.props, ...this.output }, tableId, newLen);
}

/**
 * bbq
 * 30、取消选中某些行(通过index)
 * @param  tableId   meta的id号
 * @param  index     行序号index
 */
export function unSelectRowsByIndex(tableId, index) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    store.setRowsSelected({ rowIndexs: index, view: 'filter' }, false);

    let newLen = store.getSelectedRows(false, 'filter').length;
    isFunction(store.selectedChange) && store.selectedChange({ ...this.props, ...this.output }, tableId, newLen);
}

/**
 * bbq
 * 31、反选
 * @param  tableId   meta的id号
 */
export function reverseSelected(tableId) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }
    store.reverseRowsSelected(!this.isUpdatePage);

    let newLen = store.getSelectedRows(false, 'filter').length;
    isFunction(store.selectedChange) && store.selectedChange({ ...this.props, ...this.output }, tableId, newLen);
}

/**
 * qbb
 * 32、移除没有输入内容，所有的空行
 * @param  tableId   meta的id号
 * @param  key       data的键值
 * @param  rule      对keys的修饰, rule == ‘except’/'include'
 * @modify
 * 			1、  增加了rule规则                             yanggqm      @18/05/18
 */
export function filterEmptyRows(tableId, keys, rule = 'except') {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }
    store.removeEmptyRows({ keys, rule });
}

/**
 * 过滤孙表数据
 * @param {string} tableId  子表id
 * @param {object} grandTableConfig  
 
 * grandTableConfig:
 * 
 *  { 
 *      grandTableCode1: { keys:[], rule='except' },
 *      grandTableCode2: { keys:[], rule='except' },
 *  }
 */
export function filterEmptyGrandRows(tableId, grandTableConfig, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }
    store.filterEmptyGrandRows(grandTableConfig, shouldForceUpdate);
}


/**
 * bbq
 * 33、通过传入的键过滤不想要的数据
 * @param  tableId   meta的id号
 * @param  keys      排除的项（根据columns里） 可数组可字符串也可不传
 */
export function getAllRowsRemoveKeys(tableId, keys) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    return store.getAllRowsRemoveKeys(keys);
}

/**
 * 关闭所有行展开
 * @param  tableId   meta的id号
 */
export function closeExpandedRow(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    store.setStore('expandedRowKeys', []);
}

/*
 * bbq
 * 36、获取缓存数据
 * @param  tableId   meta的id号
 * mofify by zhanghengh @18/05/05
 * mofify by zhanghengh @18/05/15 将初始值该会 ACHE_ORIGIN_DATA
 */
export function getCacheData(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    return JSON.parse(JSON.stringify(store.getCache(['base'])));
}

/*
 * bbq
 * 37、把index行设置为选中行
 * @param  tableId   meta的id号
 * @param  index     index 行序号-1
 */
export function focusRowByIndex(tableId, index) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }

    let normalIndex = store.getTrueRowIndex({ rowIndex: index }, 'filter', 'normal');
    // TODO setCurrnetInfo
    store.setTableProps('focusIndex', normalIndex, false);
    store.setTableProps('currentIndex', index, true, () => {
        store.setTableProps('focusIndex', -1, false);
    });
}

/**
 * TODO
 * 38、获取以模板数据为基准的全数组数据
 * @param  tableId   meta的id号
 * mofify by zhanghengh  增加flag标识位，判断是否为多表头，默认是false，不是多表头
 */
export function getMixinMetaData(tableId, flag = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    return store.getMixinMetaOfData();
}

/**
 * bbq
 * 40、搜索表格中特定数据的方法，
 * @param {*} tableId 表格id
 * @param {*} key 所要搜索表格数据中的key 字符串或字符串数组
 * @param {*} value 所要搜索表格数据中的value 字符串
 * return  返回值是搜索到的表格数据  object
 * mofify by zhanghengh @18/05/116 修改之前的逻辑，改为每次从全数据筛选
 */
export function getFilterRows(tableId, key, value, allRows) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    let langCode = getLangCode();
    return store.getFilterRows(key, value, langCode);
}
/**
 * bbq
 * 41、搜索设置搜索后表格数据的方法，
 * @param {*} tableId 表格id
 * @param {*} key 所要搜索表格数据中的key 字符串或字符串数组
 * @param {*} value 所要搜索表格数据中的value 字符串
 * @param {*}  isShowAll 筛选不到是否展示全数据  true 或false
 * mofify by zhanghengh @18/05/16 修改之前的逻辑，每次筛选，保存当前全数据
 */
export function setFiltrateTableData(tableId, key, value, isShowAll) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    let langCode = getLangCode();
    return store.setFiltrateTableData(key, value, isShowAll, langCode, tableId);
}

/**
 * TODO
 * 批量更改表格数据42
 * add by zhangheng 18/5/4
 * @param {*} tableId 表格id
 */
export function batchChangeTableData(tableId) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return null;
    }

    return store.batchChangeTableData();
}

/**
 * bbq
 * add by yanggqm @18/05/15
 * 45、更新多行的数据（根据index值）
 * @param  tableId   meta的id号
 * @param  record    插入的数据数组
 * @param  flag      是否缓存新加入的数据
 * @param  autoFocus 表格重新渲染后是否需要聚焦到之前聚焦的元素 主要解决快捷键连续性操作的问题
 */
export function updateDataByIndexs(tableId, record, flag = false, autoFocus = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    store.updateTableDataByIndexs(record, flag, !this.isUpdatePage, 'filter');

    store.clearValidated();
    // TODO
    //     let cell = autoFocus && getCellDom(tableId);
    //   let cellIdentity = cell && getCellIdentity(cell);
}

/**
 * bbq
 * add by yanggqm @18/05/15
 * 46、插入多行的数据（根据index值）
 * @param  tableId   meta的id号
 * @param  record    插入的数据数组
 * @param  autoFocus 表格重新渲染后是否需要聚焦到之前聚焦的元素 主要解决快捷键连续性操作的问题

 */
export function insertDataByIndexs(tableId, record, flag = false, autoFocus = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    // 由于内部是true  所以这里默认要为false
    store.insertDataByIndexs(record, flag, !this.isUpdatePage, 'filter');
    // TODO
    //     let cell = autoFocus && getCellDom(tableId);
    //   let cellIdentity = cell && getCellIdentity(cell);
    return {
        // ...myCardScope.state.table,
        rows: store.getData(),
    };
}

/**
 * bbq
 * add by yanggqm @18/05/15
 * 47、根据index设置行数据的状态
 * @param  tableId   meta的id号
 * @param  record    插入的数据数组
 */
export function setRowStatusByIndexs(tableId, record) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    store.setRowStatusByIndexs(record, true, 'filter');
}

/**
 * bbq
 * add by yanggqm @18/06/07
 * 51、 多行数据插入到index行后
 * @param  tableId   meta的id号
 * @param  data      多行为数组。
 * @param  index     行序号-1 index
 */
export function insertRowsAfterIndex(tableId, data, index) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    store.insertRowsAfterIndex(data, index, true, 'filter');
}

/**
 * bbq
 * add by yanggqm @18/05/29
 * 50、 设置单元格为编辑态（同时光标定位）
 * @param  tableId   meta的id号
 * @param  index     行序号-1 index
 * @param  key       键值
 * @param  flag      编辑性 默认为true  为编辑态
 */
export function setTdEditByIndex(tableId, index, key, flag = true, isUpdate = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }

    // index = store.getTrueRowIndex({ rowIndex: index }, 'filter');

    let row = store.getRows({ rowIndexs: [index], view: 'filter' })[0];
    let rowKey = store.getTableProps('rowKey');

    store.setCellProps({
        attrcode: key,
        rowKeyValue: row[rowKey],
        shouldForceUpdate: isUpdate,
        value: { isEdit: flag },
        view: 'filter',
    });
}

/**
 * bbq
 * add by yanggqm @18/06/08
 * 52、根据index设置表格某行某个字段值
 * @param  tableId   meta的id号
 * @param  index     行序号-1，从0开始
 * @param  origin    多键组成的对象，例如： {key1: {display: '', value: ''}, key2: {display: ''}}, key2: {display: '', value: '', scale: ''}}
 * motify zhanghengh 将批改数据重新缓存
 */
export function setValByKeysAndIndex(tableId, index, origin = {}) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }

    let row = store.getRows({ rowIndexs: [index], view: 'filter' })[0];
    let rowKey = store.getTableProps('rowKey');

    if (!row) {
        return console.warn('unknow row data');
    }

    row.status == ROW_STATUS.origin && store.setRowProps({ rowIndex: index, view: 'filter' }, { status: ROW_STATUS.edit }, false);

    for (let key in origin) {
        store.setCellValue({
            attrcode: key,
            rowIndex: index,
            shouldForceUpdate: false,
            value: origin[key],
            view: 'filter',
        });
        // 将批改数据重新缓存 有问题看一下 TODO
        // if (!isUndefined(origin[key].value) || !isUndefined(origin[key].display)) {
        //     this.batchData[tableId] = {
        //       batchChangeIndex: index,
        //       batchChangeKey: key,
        //       batchChangeValue: !isUndefined(origin[key].value) ? origin[key].value : null,
        //       batchChangeDisplay: !isUndefined(origin[key].display) ? origin[key].display : null
        //     };
        //   }
    }

    if (!this.isUpdatePage) {
        store.refresh();
    }

}

/**
 * TODO
 * add by yanggqm @18/06/09
 * 53、设置所有左侧复选禁/启用
 * @param  tableId     表格id
 * @param  flag        是否禁用 true 或false
 */
export function setAllCheckboxAble(tableId, flag = false) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    let checkInfo = store.getCheckInfo();
    checkInfo.disabledAll = !flag;
    store.setCheckInfo({ checkInfo, shouldForceUpdate: false });

    let data = store.getData();
    let rowKey = store.getTableProps('rowKey');

    // add by bbqin 禁用所有checkbox时  先判断是否是聚焦的 聚焦就将焦点 移动一下，解决火狐复制粘贴快捷键焦点丢失的问题
    let activeEl = document && document.activeElement;
    let container = document.getElementById(`hot-key-${tableId}`);
    if (!flag && container && container.contains(activeEl) && activeEl.type === 'checkbox') {
        document.body.focus();
    }

    data.forEach(item => {
        store.setRowProps({ rowKeyValue: item[rowKey] }, { disabled: !flag }, false);
    });

    //  更新
    if (!this.isUpdatePage) {
        store.refresh();
    }

}

/**
 * bbq
 * add by yanggqm @18/06/26
 * 57、设置多个表格数据
 * @param  tableId   meta的id号
 * @param  data      传入的data数据
 * 解决思路：
 * 		1、给不同的tableId配对应的data
 * 		2、不论第几次给tableId对应table来setTableData，都要把最新的data存下来
 *      3、date的数据结构为 {rows: []}
 */
export function setMulTablesData(data = {}, callback) {
    for (let key in data) {
        let store = existenceCheck.call(this, key);
        if (!store) {
            return [];
        }

        let originData = JSON.parse(JSON.stringify(data[key]));
        originData.rows = store.formatData(originData.rows);

        store.setCache(['base'], JSON.parse(JSON.stringify(originData)));
        store.setCache(['all'], null);

        let oldData = store.getData();
        // 清理旧值
        store.clearRowsOldValue({ rows: oldData });
        // 设置数据
        store.setData({ data: originData.rows, shouldForceUpdate: true });
        // 设置值之后  存旧值
        let newData = store.getData();
        store.saveRowsOldValue({ rows: newData });

        isFunction(callback) && callback();

        // if (!this.myTable[key]) {
        //     // 给每个控件赋初始值
        //     let rows = originData.rows;
        //     _saveOldValue.call(this, { rows, tableId: key });
        //     this.myTableData[key] = originData;
        //     isFunction(callback) && callback.call(this, { ...this.output }, data);
        //     _saveOriginCache(key, originData);
        //     _saveAllData(key, null); // TODO 缓存表格初始全数据 zh
        // }
    }
}

/**
 * bbq
 * add by yanggqm @18/07/05
 * 58、根据index获取行数据
 * @param  tableId   meta的id号
 * @param  index     index值 行序号-1
 */

export function getRowsByIndexs(tableId, index) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    return store.getRows({ rowIndexs: index, view: 'filter' });
}

// TODO
export function getAllTableRequire(tableIds, flag = false) {
    const errorInfo = {
        key: {},
        label: {},
        ids: [],
    };

    if (!isArray(tableIds) && !isString(tableIds) && !isUndefined(tableIds)) {
        warningOnce(
            !isArray(tableIds) && !isString(tableIds) && !isUndefined(tableIds),
            '不传检测所有表，传入字符串检测当前表，传入数组检测数组中的每一项，其他格式错误',
        );
        return errorInfo;
    }

    const json = this.cardTableDataSource._langJson || {};
    const meta = this.state.meta;
    const { gridrelation, containerrelation } = meta;

    if (containerrelation) {
        console.log('current meta include mutil containerrelation');
    }

    tableIds = !isUndefined(tableIds) ? (isArray(tableIds) ? tableIds : [tableIds]) : null;

    // 校验
    const validateFailed = {};
    let firstValidateFaild = {};

    for (let tableId in gridrelation) {
        const idInfo = gridrelation[tableId];
        errorInfo.key[tableId] = {};
        const tableName = meta[tableId]['name'] || json['table0010'];

        //  校验
        validateFailed[tableId] = validateFailed[tableId] || {};
        firstValidateFaild[tableId] = firstValidateFaild[tableId] || [];

        let store = existenceCheck.call(this, tableId);
        const rowKey = store ? store.getTableProps('rowKey') : 'rowid';

        if (!tableIds) {
            errorInfo.ids.push(tableName);
        } else {
            tableIds.includes(tableId) && errorInfo.ids.push(tableName);
        }

        const tableLabel = `${json['table007']}_` + tableName + ' > ';
        errorInfo.label[tableLabel] = {};
        // 筛选之前的代码
        // const tableData =
        //   this.myTable[tableId] && this.myTable[tableId].state ? this.myTable[tableId].state.table : { rows: [] };
        const rows = getAllRows.call(this, tableId);

        const tableDataRows = rows.filter(item => item.status != ROW_STATUS.delete);

        // destEditAreaCode是侧拉项  tabRelation为页签项
        const { tabRelation = [] } = idInfo;

        if (tabRelation.length) {
            tabRelation.forEach(idMain => {
                const tabId = idMain + '_TAB';
                const tabName = meta[idMain]['name'] || json['table009'];
                const tabLabel = `${tabName}(${json['table008']})` + ' ： ';
                errorInfo.key[tableId][tabId] = {};
                errorInfo.label[tableLabel][tabLabel] = {};

                validateFailed[tableId][idMain] = {};

                // idMain 为主表内的不同页签  itemsMain可能会多表头
                let itemsMain = isObject(meta[idMain]) ? meta[idMain].items : [];
                if (flag) {
                    itemsMain = itemsMain.getArrayData ? itemsMain.getArrayData() : _getAllItem(itemsMain); // 处理侧拉多表头
                }

                // itemsMain  兼容
                itemsMain = itemsMain.getArrayData ? itemsMain.getArrayData() : itemsMain;

                itemsMain.forEach(item => {
                    if (!!item.required && !!item.visible && tableDataRows.length > 0) {
                        tableDataRows.forEach((val, index) => {
                            const cellLabel = `${json['table_first']}${index + 1}${json['table_row']}：`;
                            errorInfo.key[tableId][tabId][index] = errorInfo.key[tableId][tabId][index] || {};
                            errorInfo.label[tableLabel][tabLabel][cellLabel] = errorInfo.label[tableLabel][tabLabel][cellLabel] || {};

                            if (val.values[item.attrcode]) {
                                const checkedValue = val.values[item.attrcode].value;
                                if (isWrong(checkedValue)) {
                                    errorInfo.key[tableId][tabId][index][item.attrcode] = item.label;
                                    errorInfo.label[tableLabel][tabLabel][cellLabel][item.label] = ' 为空';

                                    // 有就是有没有就是没有  给页签上判断用
                                    validateFailed[tableId][idMain][val[rowKey]] = validateFailed[tableId][idMain][val[rowKey]] || {};
                                    validateFailed[tableId][idMain][val[rowKey]][item.attrcode] = item.attrcode;
                                    if (!firstValidateFaild[tableId].length) {
                                        firstValidateFaild[tableId] = [idMain, val[rowKey], item.attrcode];
                                    }
                                }
                            } else {
                                errorInfo.key[tableId][tabId][index][item.attrcode] = item.label;
                                errorInfo.label[tableLabel][tabLabel][cellLabel][item.label] = ' 为空';

                                // 有就是有没有就是没有  给页签上判断用
                                validateFailed[tableId][idMain][val[rowKey]] = validateFailed[tableId][idMain][val[rowKey]] || {};
                                validateFailed[tableId][idMain][val[rowKey]][item.attrcode] = item.attrcode;
                                if (!firstValidateFaild[tableId].length) {
                                    firstValidateFaild[tableId] = [idMain, val[rowKey], item.attrcode];
                                }
                            }
                        });
                    }
                });
            });
        }
    }
    //console.log(errorInfo);
    errorInfo.validateFailed = validateFailed;
    errorInfo.firstValidateFaild = firstValidateFaild;
    return errorInfo;
}
/**52
 * 返回手动选中过的的单元格信息
 * add by  zhangheng 18/07/22
 */
export function getTableItemData(tableId) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    // {
    //     batchChangeIndex,
    //     batchChangeKey,
    //     batchChangeValue,
    //     batchChangeDisplay,
    // }
    let oldBatchData = store.getCache('oldBatchData');
    if (oldBatchData && oldBatchData.batchChangeIndex) {
        oldBatchData.batchChangeIndex = store.getTrueRowIndex({ rowIndex: oldBatchData.batchChangeIndex }, 'normal', 'filter');
    }

    return oldBatchData;
}

/**53
 * add zhanghengh 18/7/30
 * 设置某些表格，某些列的精度
 * add by  zhangheng 18/07/22  这个结构是根据崔松杰的方法返回的，又问题可以问问他。。。
 * list数据结构   [{areacode: 'tableid', filedcode: 'colkey', scale: "2"}, ....]
 */
export function setColScale(list) {
    list.forEach(eve => {
        const { areacode, fieldcode, scale } = eve;
        this.state.meta[areacode].items.forEach(item => {
            if (item.attrcode === fieldcode) {
                item.scale = scale;
            }
        });
    });
    /**重新设置模版精度*/
    this.meta.setMeta(this.state.meta);
}

/**55
 * add zhanghengh 18/8/2
 * 判断哪列隐藏
 * tableId
 * colKey  所有检测的列字段
 */
export function checkVisible(tableId, colKey) {
    let isVisible = false;
    this.state.meta[tableId].items.forEach(eve => {
        //获取合计行的列配置
        const { visible, attrcode, children } = eve;
        if (!isUndefined(children)) {
            //判断和并列的情况
            children.forEach(item => {
                const { visible } = item;
                if (colKey === attrcode && visible) {
                    isVisible = true;
                }
            });
        } else if (colKey === attrcode && visible) {
            isVisible = true;
        }
    });
    return isVisible;
}

// 校验孙表
// tableId: {parentId: []}
export function getChildTableRequired(parentIds = {}, childRowKey = 'rowid') {

    if (!parentIds) { console.log('not get tableId'); }

    const json = this.cardTableDataSource._langJson || {};
    const meta = this.state.meta;
    const { gridrelation, containerrelation } = meta;

    if (containerrelation) {
        console.log('current meta include mutil containerrelation');
    }

    // 整表校验
    const allErrorInfo = [];

    // 可能会有效率问题
    for (let parentId in parentIds) {
        const childIds = parentIds[parentId] || [];
        const parentStore = existenceCheck.call(this, parentId);
        // 所有子表行
        const parentData = parentStore && parentStore.getViewData('filter');

        if (parentData) {
            // 所有孙表数据
            let grandTableDatas = parentStore.getStore('grandTable') || {};
            // console.log(grandTableData, parentData);
            const parentRowKey = parentStore.getTableProps('rowKey') || 'rowid';

            // 当前表
            const parentErrorInfo = [];
            parentData.forEach((prow, pindex) => {
                let grandTableData = grandTableDatas[prow[parentRowKey]] || {};
                if (grandTableData) {
                    // 校验缓存
                    const errorInfo = {
                        key: {},
                        label: {},
                        ids: [],
                    };
                    const validateFailed = {};
                    const firstValidateFaild = {};

                    childIds.forEach(tableId => {
                        const idInfo = gridrelation[tableId] || {};
                        errorInfo.key[tableId] = {};
                        const tableName = meta[tableId]['name'] || json['table0010'];

                        //  校验
                        validateFailed[tableId] = validateFailed[tableId] || {};
                        firstValidateFaild[tableId] = firstValidateFaild[tableId] || {};

                        // 获取孙表的rowKey
                        let store = existenceCheck.call(this, tableId);
                        const childRowKey = store ? store.getTableProps('rowKey') : childRowKey;

                        errorInfo.ids.push(tableName);

                        const tableLabel = `${json['table007']}_` + tableName + ' > ';
                        errorInfo.label[tableLabel] = {};
                        // 筛选之前的代码
                        const rows = (grandTableData[tableId] || {}).rows || [];

                        const tableDataRows = rows.filter(item => item.status != ROW_STATUS.delete);

                        // destEditAreaCode是侧拉项  tabRelation为页签项
                        const { tabRelation = [tableId] } = idInfo;

                        // 共享页签
                        if (tabRelation.length) {
                            tabRelation.forEach(idMain => {
                                const tabId = idMain + '_TAB';
                                const tabName = meta[idMain]['name'] || json['table009'];
                                const tabLabel = `${tabName}(${json['table008']})` + ' ： ';
                                errorInfo.key[tableId][tabId] = {};
                                errorInfo.label[tableLabel][tabLabel] = {};

                                validateFailed[tableId][idMain] = {};

                                // idMain 为主表内的不同页签  itemsMain可能会多表头
                                let itemsMain = isObject(meta[idMain]) ? meta[idMain].items : [];

                                itemsMain = itemsMain.getArrayData ? itemsMain.getArrayData() : _getAllItem(itemsMain); // 处理侧拉多表头

                                // itemsMain  兼容
                                itemsMain = itemsMain.getArrayData ? itemsMain.getArrayData() : itemsMain;

                                itemsMain.forEach(item => {
                                    if (!!item.required && !!item.visible && tableDataRows.length > 0) {
                                        tableDataRows.forEach((val, index) => {
                                            const cellLabel = `${json['table_first']}${index + 1}${json['table_row']}：`;
                                            errorInfo.key[tableId][tabId][index] = errorInfo.key[tableId][tabId][index] || {};
                                            errorInfo.label[tableLabel][tabLabel][cellLabel] = errorInfo.label[tableLabel][tabLabel][cellLabel] || {};

                                            if (val.values[item.attrcode]) {
                                                const checkedValue = val.values[item.attrcode].value;
                                                if (isWrong(checkedValue)) {
                                                    errorInfo.key[tableId][tabId][index][item.attrcode] = item.label;
                                                    errorInfo.label[tableLabel][tabLabel][cellLabel][item.label] = ' 为空';

                                                    // 有就是有没有就是没有  给页签上判断用
                                                    validateFailed[tableId][idMain][val[childRowKey]] = validateFailed[tableId][idMain][val[childRowKey]] || {};
                                                    validateFailed[tableId][idMain][val[childRowKey]][item.attrcode] = item.attrcode;
                                                    if (!Object.keys(firstValidateFaild[tableId]).length) {
                                                        firstValidateFaild[tableId][idMain] = {};
                                                        firstValidateFaild[tableId][idMain][val[childRowKey]] = item.attrcode;
                                                    }
                                                }
                                            } else {
                                                errorInfo.key[tableId][tabId][index][item.attrcode] = item.label;
                                                errorInfo.label[tableLabel][tabLabel][cellLabel][item.label] = ' 为空';

                                                // 有就是有没有就是没有  给页签上判断用
                                                validateFailed[tableId][idMain][val[childRowKey]] = validateFailed[tableId][idMain][val[childRowKey]] || {};
                                                validateFailed[tableId][idMain][val[childRowKey]][item.attrcode] = item.attrcode;
                                                if (!Object.keys(firstValidateFaild[tableId]).length) {
                                                    firstValidateFaild[tableId][idMain] = {};
                                                    firstValidateFaild[tableId][idMain][val[childRowKey]] = item.attrcode;
                                                }
                                            }
                                        });
                                    }
                                });
                            });
                        }
                    });

                    errorInfo.validateFailed = validateFailed;
                    errorInfo.firstValidateFaild = firstValidateFaild;

                    // 行信息
                    parentErrorInfo.push({
                        index: pindex,
                        rowKeyValue: prow[parentRowKey],
                        info: `${json['table_first']}${pindex + 1}${json['table_row']}：`,
                        errorInfo,
                    });
                }
            });

            // 表信息
            allErrorInfo.push({
                id: parentId,
                info: meta[parentId]['name'] || json['table0010'],
                row: parentErrorInfo,
            });
        }
    }

    return allErrorInfo;
}

export function checkChildTableRequired(parentIds = {}, isReturnBool = true, childRowKey = 'rowid') {
    let allErrorInfo = getChildTableRequired.call(this, parentIds, childRowKey);

    // console.log(allErrorInfo, 'allErrorInfoallErrorInfoallErrorInfo');
    const json = this.cardTableDataSource._langJson || {};
    let isRequired = false;
    // 校验信息
    let toastMessage = [];

    // 被检验住的行
    const requiredRows = { isNoRequired: false };
    // 校验信息
    allErrorInfo.forEach(pinfo => {
        requiredRows[pinfo.id] = {};
        if (pinfo.row) {
            // 缓存记录
            let grandValidateFailed = {};
            pinfo.row.forEach(rowInfo => {
                const parentLabel = pinfo.info + rowInfo.info;

                // 父表
                toastMessage.push('>>> ' + parentLabel);

                const { label, ids, validateFailed = {}, firstValidateFaild = {} } = rowInfo.errorInfo;
                // 记录
                grandValidateFailed[rowInfo.rowKeyValue] = validateFailed;
                // 被检验住的行
                const childRequiredRows = { isNoRequired: false };
                let messageWrong = []; //需要校验的表格
                messageWrong = Object.keys(label).filter(item =>
                    ids.some(item1 => item.startsWith(`${json['table007']}_${item1}`)),
                );
                messageWrong.forEach(eve => {
                    const labelItem = label[eve];
                    childRequiredRows[eve] = {};
                    Object.keys(labelItem).forEach(key => {
                        childRequiredRows[eve][key] = { childRequiredRows: [] };
                        const labelItemChild = labelItem[key];
                        let pagelabel = '';
                        if (
                            Object.keys(labelItemChild).length > 0 &&
                            Object.keys(labelItemChild).filter(key => {
                                return Object.keys(labelItemChild[key]).length;
                            }).length
                        ) {
                            pagelabel = key;
                        }
                        toastMessage.push(pagelabel);
                        Object.keys(labelItemChild).forEach((cont, index) => {
                            if (Object.keys(labelItemChild[cont]).length > 0) {
                                childRequiredRows[eve][key]['childRequiredRows'].push(index);
                                isRequired = true;
                                //对输出的校验内容进行格式转化
                                let str =
                                    cont +
                                    `${Object.keys(labelItemChild[cont])
                                        .map(ele => {
                                            return `[${ele}]`;
                                        })
                                        .join('，')}；`;
                                toastMessage.push(str);
                            }
                        });
                    });
                });

                // 记录结果
                requiredRows[pinfo.id][rowInfo.index] = childRequiredRows;
            });
            if (isRequired) {
                // console.log(pinfo.id, grandValidateFailed);
                const store = existenceCheck.call(this, pinfo.id);
                store && store.setCache('grandValidateFailed', grandValidateFailed);
            }

        }
    })

    // 如果没通过
    if (isRequired) {
        toast({
            content: json['table_no_empty'] + '：',
            color: 'danger',
            groupOperation: true,
            isNode: true,
            TextArr: [json['table0022'], json['table0023'], json['table0024']],
            groupOperationMsg: toastMessage.filter(message => {
                return message;
            }),
            mark: 'card-table-toastx',
        });
        return isReturnBool ? !isReturnBool : requiredRows;
    }

    return true;
}

/**
 * TODO
 * 59、根据id检测必输性
 * 要考虑 多表头 和 侧拉分组 和 多页签 这极端情况。
 * mofify by zhanghengh  增加flag标识位，判断是否为多表头，默认是false，不是多表头
 */
export function checkTableRequired(tableIds, flag = false, isReturnBool = true, autoFocus = true) {
    const json = this.cardTableDataSource._langJson || {};
    const { label, ids, validateFailed = {}, firstValidateFaild = {} } = getAllTableRequire.call(this, tableIds, flag);
    let messageWrong = []; //需要校验的表格
    let isRequired = false;
    let toastMessage = [];

    // 被检验住的行
    const requiredRows = { isNoRequired: false };
    messageWrong = Object.keys(label).filter(item =>
        ids.some(item1 => item.startsWith(`${json['table007']}_${item1}`)),
    );
    messageWrong.forEach(eve => {
        const labelItem = label[eve];
        requiredRows[eve] = {};
        Object.keys(labelItem).forEach(key => {
            requiredRows[eve][key] = { requiredRows: [] };
            const labelItemChild = labelItem[key];
            let pagelabel = '';
            if (
                Object.keys(labelItemChild).length > 0 &&
                Object.keys(labelItemChild).filter(key => {
                    return Object.keys(labelItemChild[key]).length;
                }).length
            ) {
                pagelabel = key;
            }
            toastMessage.push(pagelabel);
            Object.keys(labelItemChild).forEach((cont, index) => {
                if (Object.keys(labelItemChild[cont]).length > 0) {
                    requiredRows[eve][key]['requiredRows'].push(index);
                    isRequired = true;
                    //对输出的校验内容进行格式转化
                    let str =
                        cont +
                        `${Object.keys(labelItemChild[cont])
                            .map(ele => {
                                return `[${ele}]`;
                            })
                            .join('，')}；`;
                    toastMessage.push(str);
                }
            });
        });
    });

    if (isRequired) {
        // validateFailed, firstValidateFaild // 四层数据
        console.log(validateFailed);
        for (let tableId in validateFailed) {
            const store = existenceCheck.call(this, tableId);
            const table = validateFailed[tableId];
            let isRefresh = false;
            if (store && table) {
                // 缓存状态
                store.setCache('validateFailed', validateFailed);
                store.setCache('firstValidateFaild', firstValidateFaild);
                for (let tabId in table) {
                    const rows = table[tabId];
                    if (rows) {
                        for (let rowKeyValue in rows) {
                            if (rowKeyValue) {
                                let row = rows[rowKeyValue];
                                if (row) {
                                    for (let attrcode in row) {
                                        store.setCellProps(rowKeyValue, attrcode, { validated: false }, false);
                                    }
                                }
                                isRefresh = true;
                            }
                        }

                    }
                }
                isRefresh && store.refresh();
            }
        }

        // 自动聚焦到首个
        if (firstValidateFaild && autoFocus) {
            const tableIdList = tableIds ? (isArray(tableIds) ? tableIds : [tableIds]) : tableIds || this.orderOfHotKey;

            let tabSelector = null,
                cellSelector = null;
            // 找到第一个
            tableIdList.some(tableId => {
                let cellInfo = firstValidateFaild[tableId];
                if (cellInfo && cellInfo.length > 0) {
                    tabSelector = `#js_lightTabs_header_${tableId} [name="${cellInfo[0]}"] a`;
                    cellSelector = `.table-cell-wrapper[cellselectorkey="${cellInfo[1]}@${cellInfo[2]}"] [tabindex="0"]`;
                    return true;
                }
            });

            const tabElement = document.querySelector(tabSelector);

            tabElement && tabElement.click();

            setTimeout(() => {
                const cellElement = document.querySelector(cellSelector);
                if (cellElement) {
                    cellElement.ncExecuteFocus = true;
                    cellElement.focus();
                    cellElement.ncExecuteFocus = false;
                }
            }, 300)

            console.log(tableIdList, firstValidateFaild, tabSelector, cellSelector);
        }

        toast({
            content: json['table_no_empty'] + '：',
            color: 'danger',
            groupOperation: true,
            isNode: true,
            TextArr: [json['table0022'], json['table0023'], json['table0024']],
            groupOperationMsg: toastMessage.filter(message => {
                return message;
            }),
            mark: 'card-table-toastx',
        });
        return isReturnBool ? !isReturnBool : requiredRows;
    }
    return true;
}

/**
 * bbq
 * add by zhanghengh @18/09/01  到时候让业务组试一下有问题在改
 * 61、给表格所用单元格设值
 * 通过传入的数据给单元格重新设值
 * rows 就是正常表格数据的格式  [{ status:v '1', rowid: '111111', values: { name: { value: 1, dispaly: 1, scale: '1' } }}]
 */
export function setAllKeysVal(tableId, rows) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    return store.setRowsCellValue({
        rows,
        shouldForceUpdate: !this.isUpdatePage,
    });
}

/**62
 * bbq
 * 设置当前点击行
 * add by  zhangheng 18/07/04
 * @param {*} tableId 表格id
 * index  索引
 */
export function setClickRowIndex(tableId, data) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return null;
    }

    if (data && (data.index || data.index === 0)) {
        data.index = store.getTrueRowIndex({ rowIndex: data.index }, 'filter', 'normal')
    }

    store.setTableProps("currentInfo", data, false);
}

/**63
 * bbq
 * 获取当前点击行
 * add by  zhangheng 18/07/04
 * @param {*} tableId 表格id
 */
export function getClickRowIndex(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return null;
    }

    let data = store.getTableProps("currentInfo");

    if (data && (data.index || data.index === 0)) {
        data.index = store.getTrueRowIndex({ rowIndex: data.index }, 'normal', 'filter');
    }

    return data;
}

/**
 * bbq
 * add by zhanghengh @18/09/12  到时候让业务组试一下有问题在改
 * 64、给表格所用单元格设值
 * 通过传入的数据给单元格重新设值
 * rows 就是正常表格数据的格式  [{ status:v '1', rowid: '111111', values: { name: { value: 1, dispaly: 1, scale: '1' } }}]
 */
export function setSaveData(tableId, data) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }
    store.setRowsCellValue({
        rows: data.rows,
        shouldForceUpdate: !this.isUpdatePage,
    });
    store.setCache(['base'], { rows: data.rows });
    store.setCache(['all'], null); // TODO 缓存表格初始全数据 zh
}

/**
 * bbq
 * 65、根据index获取某行数据
 */
//
export function getDataByIndex(tableId, index) {
    if (!tableId || typeof index == 'undefined') {
        return;
    }
    let arrRows = getAllRows.call(this, tableId);
    // 这里也可以用index取找真实index  然后再取值
    // 这里没有问题了 上面arrRows相当于过滤数据
    return arrRows[index];
}

/**
 * 66、根据传入数据更新表格数据  后台返回差异的结果的时候使用，配套使用，其他场景不能使用
 * data 格式{rows：[{ status:v '1', rowid: '111111', values: { name: { value: 1, dispaly: 1, scale: '1' } }}]}
 */
//
export function updateDataByRowId(tableId, data, isDel = false, isCache = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }

    store.updateTableDataByRowId(data, isCache, !this.isUpdatePage, isDel);

    store.clearValidated();

    return {
        // ...this.myTable[tableId].state.table, // TODO
        rows: store.getData(),
    };
}

/**67
 * 获取行的状态
 * add by  zhangheng 18/06/21
 * @param {*} tableId 表格id
 * @param {*} index 行索引   数组或者数字
 */
export function getRowStatus(tableId, index) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    return store.getRowProps({ rowIndex: index, view: 'filter' }, 'status');
}

/****70 获得当前行****/
export function getCurrentIndex(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }

    // currentIndex 是filter视图的index，可以直接使用
    return store.getTableProps('currentIndex');
}

/****
 * TODO
 * 71 差异更新Rowid 郭祉祺加  （各业务组后台返回数据应该相同，如果有需要应该可以暴露给业务组）   这个方法是基于后台数据完整的情况下，进行差异更新，与updatebyrowid 的逻辑相反，需要后台配合，谨慎使用
 * 在筛选模式下,调这个api直接更新总数据,然后取消掉筛选状态,先这样处理,如有问题在进行调整
 */
export function updateDiffDataByRowId(tableId, data, isCache = false, isDel = true, isAddStatus = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }

    let rowKey = store.getTableProps('rowKey');

    if (Array.isArray(data.rows)) {
        // let rows = table.rows;
        let rows = getAllRows.call(this, tableId);
        let rowidMap = {};
        let deleteRow = [];
        rows.map(row => {
            rowidMap[row[rowKey]] = row;
            if (row.status == ROW_STATUS.delete) {
                deleteRow.push(row);
            }
        });
        data.rows.map((record, index) => {
            let row = data.rows[index];
            let values = row.values;
            let rowid = record[rowKey];
            if (rowid && rowidMap[rowid]) {
                let rowStatus = row.status;
                let status =
                    typeof rowStatus == 'undefined' || rowStatus == ROW_STATUS.origin ? ROW_STATUS.edit : rowStatus;
                Object.keys(values).forEach(key => {
                    values[key] = {
                        ...rowidMap[rowid].values[key],
                        ...{ display: null, value: null, scale: -1 },
                        ...values[key],
                    };
                });
                data.rows[index] = { ...rowidMap[rowid], ...row };
                data.rows[index].values = {
                    ...rowidMap[rowid].values,
                    ...values,
                };
                data.rows[index].status = status;
            } else {
                row.rowid = store.generateRowKey();
                if (isAddStatus) {
                    row.status = ROW_STATUS.add;
                    // 模版上设置了默认值时,给这里没有值的新增行设置上默认值
                    // let sumItems = _sumItemsCode.call(this, tableId, false);
                    let sumItems = store.getArrayMeta(tableId);
                    sumItems.forEach(item => {
                        const { initialvalue, attrcode } = item;
                        const hasInit = isObject(initialvalue);
                        const cellValue = row.values[attrcode];
                        if (cellValue && hasInit) {
                            const { display, scale, value } = cellValue;
                            const initialCellValue = {
                                display: display || initialvalue.display,
                                scale: scale || initialvalue.scale,
                                value: value || initialvalue.value,
                            };
                            Object.assign(cellValue, initialCellValue);
                        }
                    });
                }
            }
        });
        // 如果是更新，需要就删除行留下来，保存则不需要
        if (isDel) {
            data.rows = data.rows.concat(deleteRow);
        }

        store.setTableData(data, {
            isCache: false,
            isDel: true,
            shouldForceUpdate: !this.isUpdatePage,
        });
        // 清理一下数据  如果有问题  再和业务对一下
        let storeData = store.getStore();
        // 郭祉祺让加的
        return {
            rows: storeData.data,
            checkInfo: storeData.checkInfo,
        };
    }
}

/**72
 * TODO
 * 移动行的方法
 * add by  zhangheng 19/03/5
 * @param {*} tableId 表格id
 * @param {*} indexStart 开始索引位置
 * @param {*} indexEnd 结束索引位置
 */
export function moveRow(tableId, indexStart, indexEnd) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    // 这个默认也是按照filter视图来的，不用处理
    store.moveRow(indexStart, indexEnd);
}

/**73
 * TODO
 * 王策加
 * 通过Index设置表格某些行的编辑性
 * @param  tableId   meta的id号
 * @param  index     行序号-1 （数字数组或者数字）
 * @param  flag      开关true/false
 */
export function setRowEditByIndex({ tableId, index, flag }) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    store.setRowsCellPropsByIndex(index, { disabled: !flag }, true, undefined, 'filter');
}

/**
 *   //任亚军整理
 *      69      setModelEdit            设置行侧拉编辑性
 *      68      setColVisibleByKey      设置列显示隐藏
 *      67      setColsValue            设置列数据
 * *    16      openModel               打开侧拉窗
 *      17      closeModel              关闭侧拉窗
 * *    20      hideColByKey            隐藏列(根据key)
 * 		21      showColByKey            显示列(根据key)
 * *    25		  getColValue             获取列数据
 *      26		  setColValue             设置列数据
 * *    39      closeExpandedRow        关闭任何的展开视图
 * *    43      openListView            最大化多表中表体卡片列表
 * 		44 		  openMaxTable            最大化多表体表格
 * * 	48      getMetaValByKey         根据key键获取模板中数据
 * 		49      setColEditableByKey     设置某一列的编辑性
 * *    54		  getModalDataByIndex     获取侧拉数据(根据index)
 * 		55		  showEditAreaKey 		    设置侧拉某控件显隐
 * 		56      toggleCardTable         控制主表的收起展开
 * *    60      setQueryCondition       统一给表体和侧拉添加参照顾虑
 */

/**
 *  设置行侧拉编辑性
 * @author renyjk
 * @param  {string} tableId   meta的id号
 * @param  {string || array} key    字段编码
 * @param  {boolean}  flag      是否可编辑
 */
export function setModelEdit(tableId, key, flag) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    if (isString(key)) key = [key];

    let meta = store.getMeta();
    if (meta && meta.gridrelation && meta.gridrelation[tableId]) {
        let destEditAreaCode = meta.gridrelation[tableId].destEditAreaCode;
        if (isArray(destEditAreaCode)) {
            destEditAreaCode.forEach(editcode => {
                isArray(key) &&
                    key.forEach(code => {
                        meta = setDestBrowseProps.call(this, editcode, meta, code, { disabled: !flag });
                    });
            });
        }
    }

    store.setStore(['meta'], meta);
}

function setDestBrowseProps(destBrowseAreaCode, meta, code, props) {
    let items = meta[destBrowseAreaCode] && meta[destBrowseAreaCode].items;
    if (isArray(items)) {
        let itemIndex = items.findIndex(ele => ele.attrcode === code);
        if (itemIndex !== -1) {
            items[itemIndex] = {
                ...items[itemIndex],
                ...props,
            };
        }
    }
    return meta;
}
/**
 * 设置列的显示性
 * @author renyjk
 * @param {string} tableId
 * @param {object} data
 */
export function setColVisibleByKey(tableId, data) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;

    if (isObject(data)) {
        let hideKeys = data.hideKeys;
        let showKeys = data.showKeys;

        let meta = store.getMeta();
        //let mainAreaMeta = meta[tableId];
        let destBrowseAreaCode = '';
        let destEditAreaCode = [];
        if (meta.gridrelation && meta.gridrelation[tableId]) {
            //下拉模板
            destBrowseAreaCode = meta.gridrelation[tableId].destBrowseAreaCode;
            //侧拉模板
            destEditAreaCode = meta.gridrelation[tableId].destEditAreaCode;
        }

        //设置隐藏
        if (isArray(hideKeys) && hideKeys.length) {
            hideKeys.forEach(code => {
                store.setColumn(tableId, { [code]: { visible: false } }, false);
                if (destBrowseAreaCode) {
                    meta = setDestBrowseProps.call(this, destBrowseAreaCode, meta, code, { visible: false });
                }
                if (isArray(destEditAreaCode) && destEditAreaCode.length) {
                    destEditAreaCode.forEach(editcode => {
                        meta = setDestBrowseProps.call(this, editcode, meta, code, { visible: false });
                    });
                }
            });
        }

        //设置显示
        if (isArray(showKeys) && showKeys.length > 0) {
            showKeys.forEach(code => {
                store.setColumn(tableId, { [code]: { visible: true } }, false);
                if (destBrowseAreaCode) {
                    meta = setDestBrowseProps.call(this, destBrowseAreaCode, meta, code, { visible: true });
                }
                if (isArray(destEditAreaCode) && destEditAreaCode.length > 0) {
                    destEditAreaCode.forEach(editcode => {
                        meta = setDestBrowseProps.call(this, editcode, meta, code, { visible: true });
                    });
                }
            });
        }

        // 统一更新表格
        store.setStore(['meta'], meta);
    }
}

/**
 * 批量修改某列数据
 * @author renyjk
 * @param {string} tableId
 * @param {array} colArr
 */
export function setColsValue(tableId, colArr) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    let rows = getAllRows.call(this, tableId);
    if (rows.length) {
        rows = rows.map(item => {
            if (item.status === ROW_STATUS.origin) item.status = ROW_STATUS.edit;
            return item;
        });
        if (isArray(colArr) && colArr.length) {
            colArr.forEach(item => {
                let { key, data = {} } = item;
                store.setColumn(tableId, { [key]: data }, false);
            });
        }
    }

    store.refresh();
}

/**
 * 打开侧拉
 * @author renyjk
 * @param {string} tableId
 * @param {string} type
 * @param {object} record
 * @param {number} index
 * @param {function} callback
 * @param {boolean} flag
 */
export function openModel(tableId, type = 'browse', record, index, callback, flag = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    let meta = store.getMeta();
    let destEditAreaCode = [];
    if (meta.gridrelation && meta.gridrelation[tableId]) {
        destEditAreaCode = meta.gridrelation[tableId].destEditAreaCode;
    }
    let hideModel = destEditAreaCode.every(editAreaCode => !meta[editAreaCode].areaVisible);
    if (hideModel) {
        //TODO
        toast({
            color: 'danger',
            groupOperation: true,
            isNode: true,
            //TextArr: [json['table0022'], json['table0023'], json['table0024']],
            // 无内容，模板中该内容已被隐藏
            // groupOperationMsg: [json['table0032']],
            mark: 'card-table-toastx',
        });
        return;
    }

    // 内部存normalIndex 
    index = store.getTrueRowIndex({ rowIndex: index }, 'filter', 'normal');

    store.showSideBox(type, record, index);
    isFunction(callback) && callback();

    //定位到当前编辑行
    //focusRowByIndex(tableId, index)
}

/**
 * 关闭侧拉
 * @author renyjk
 * @param {string} tableId
 */
export function closeModel(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    store.closeSideBox();
}

/**隐藏列
 * @author renyjk
 * @param {string} tableId
 * @param {string || array} key
 */
export function hideColByKey(tableId, key) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    if (isString(key)) key = [key];
    key.forEach(code => {
        store.setColumn(tableId, { [code]: { visible: false } }, false);
    });

    // 统一更新一次
    store.refresh();
}

/**设置列属性
 * @author renyjk
 * @param {string} tableId
 */
export function setColumn(tableId, value, isShouldUpdate) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;

    store.setColumn(tableId, value, isShouldUpdate);
    // store.setColumn(tableId, { [code]: { visible: false } }, false);
}

/**显示列
 * @author renyjk
 * @param {string} tableId
 * @param {string || array} key
 */
export function showColByKey(tableId, key) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    if (isString(key)) key = [key];
    key.forEach(code => {
        store.setColumn(tableId, { [code]: { visible: true } }, false);
    });

    // 统一更新一次
    store.refresh();
}

/**获取表格某列数据
 * @author renyjk
 * @param {string} tableId
 * @param {string} key
 * @param {boolean} flag  增加flag标识位，判断是否为多表头，默认是false，不是多表头
 *
 * flag参数好像没有用 ?? TODO
 *
 * @param {boolean} isDel  是否获得删除行的数据
 */
export function getColValue(tableId, key, flag = false, isDel = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    let rows = getAllRows.call(this, tableId);
    let newData = [];
    rows.forEach(item => {
        if (!isDel) {
            if (item.status !== ROW_STATUS.delete) {
                newData.push(item.values[key]);
            }
        } else {
            newData.push(item.values[key]);
        }
    });
    return JSON.parse(JSON.stringify(newData));
}

/**
 * 设置表格某列为同一个数据
 * @author renyjk
 * @param {string} tableId
 * @param {string} key
 * @param {object} obj
 */
export function setColValue(tableId, key, { display, value, scale }) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    let rows = getAllRows.call(this, tableId);

    rows = rows.map(item => {
        const { rowid, status } = item;
        // 已经被删除的行不处理
        if (status == ROW_STATUS.delete) {
            return item;
        }
        if (status == ROW_STATUS.origin) {
            item.status = ROW_STATUS.edit;
        }
        if (!isObject(item.values[key])) {
            item.values[key] = {};
        }
        if (!isUndefined(value)) {
            item.values[key].value = value;
            // 给新复制的行存旧值
            saveChangedRowsOldValue.call(this, tableId, rowid, key, value);
        }
        !isUndefined(display) && (item.values[key].display = display);
        !isUndefined(scale) && (item.values[key].scale = scale);
        return item;
    });

    store.setData({ data: rows });
}

/**
 * TODO expandedRowKeys待处理
 * 最大化多表中表体卡片列表
 * @author renyjk
 * @param {string} tableId
 * @param {boolean} flag
 * @param {array} expandedRowKeys
 */
export function openListView(tableId, flag, expandedRowKeys) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    store.openListView(flag);
}

/**
 * 最大化多表中表格
 * @author renyjk
 * @param {string} tableId
 * @param {boolean} flag
 */
export function openMaxTable(tableId, flag) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    store.openMaxView(flag);
}

/**
 * 获取表格的最大化状态
 * @author renyjk
 * @param {string} tableId
 */
export function isMaxView(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    return store.isMaxView();
}

/**
  根据key键获取模板中数据
 * @author renyjk
 * @param {string} tableId
 * @param {string} key
 * @param {boolean} flag 增加flag标识位，判断是否为多表头，默认是false，不是多表头
 */
export function getMetaValByKey(tableId, key, flag = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    let items = store.getMeta()[tableId].items;
    if (isArray(items)) {
        let temp = [];
        if (flag) {
            temp = _getAllItem(items).filter(item => item.attrcode === key);
        } else {
            temp = items.filter(item => item.attrcode === key);
        }
        return temp.length > 0 ? temp[0] : null;
    }
    return null;
}

/**
  根据key键获取模板中数据
 * @author renyjk
 * @param {string} tableId
 * @param {string} key
 * @param {boolean} flag 增加flag标识位，判断是否为多表头，默认是false，不是多表头
 */
export function setColEditableByKey(tableId, key, flag = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    let meta = store.getMeta();
    let items = meta && meta[tableId] && meta[tableId].items;
    let destEditAreaCode =
        meta.gridrelation && meta.gridrelation[tableId] && meta.gridrelation[tableId].destEditAreaCode;
    if (isArray(items)) {
        // 处理多表头
        let fn = (items, key) => {
            items.map(item => {
                if (item.children) {
                    fn(item.children, key);
                }
                if (item.attrcode === key || key.includes(item.attrcode)) {
                    item.disabled = flag;
                    /*兼容设置侧拉框同字段的编辑性start 米明天试试，看看好使不 之后在来完善先注释掉*/
                    if (destEditAreaCode) {
                        destEditAreaCode.forEach(eve => {
                            //便利侧拉模版数组里的每项，所对应的模版
                            meta[eve].items.forEach(formItem => {
                                if (formItem.attrcode === item.attrcode) {
                                    formItem.disabled = flag;
                                }
                            });
                        });
                    }
                    /*兼容设置侧拉框同字段的编辑性end*/
                }
            });
        };
        fn(items, key);
        store.setMeta(meta);
    }
}
/**32
 * 共享  郭扬让加的，他们现在再用
 * 刷新表格高度  这是特殊场景使用，一般是表格上方部分，高度变化，表格需要不断适应时使用
 */
export function updateTableHeight() {
    PubSub.publish(OTHERCOMPLETE, true);
}

/**
 * 根据index获取侧拉数据
 * @param  tableId   meta的id号
 * @param  index     行序号-1，从0开始
 */
export function getModalDataByIndex(tableId, index) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    let allRows = getAllRows.call(this, tableId);
    const editRows = _getEditRows(store, tableId);
    const editRowsCode = editRows.map(item => item.attrcode);
    let result = {};
    // 这里index也不需要处理 getAllRows获取的数据相当于 filter 视图
    if (allRows[index] && allRows[index].values) {
        let values = allRows[index].values;
        for (let key in values) {
            if (editRowsCode.includes(key)) {
                result[key] = values[key];
            }
        }
    }
    return result;
}

/**
 * 设置侧拉某控件显隐
 * @author renyjk
 * @param {string} tableId
 * @param {string} key
 * @param {boolean} flag
 */
export function showEditAreaKey(tableId, key, flag = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    let meta = store.getMeta();
    const editRows = _getEditRows(store, tableId);

    let [isStr, isArr] = [typeof key === 'string', isArray(key)];
    if (editRows && (isStr || isArr)) {
        editRows.map(elem => {
            if (isStr && elem.attrcode === key) {
                elem.visible = flag;
            }
            if (isArr && key.includes(elem.attrcode)) {
                elem.visible = flag;
            }
        });
        store.setMeta(meta);
    }
}

/**
 * 控制主表的收起展开
 * @author renyjk
 * @param {string} tableId
 * @param {boolean} flag
 */
export function toggleCardTable(tableId, flag = false) {
    tableId = isArray(tableId) ? tableId : [tableId];
    tableId.forEach(item => {
        let store = existenceCheck.call(this, item);
        if (store) {
            let tableVisible = store.getStore("tableSwitch").tableVisible;
            store.toggleTableShow(flag);
            if (tableVisible !== flag) {
                isFunction(store.onHeadAngleToggle) &&
                    store.onHeadAngleToggle({ ...this.props, ...this.output }, item, flag);
            }
        }
    });
}

/**
   统一给表体和侧拉添加参照顾虑
 * @author renyjk
 * @param {string} tableId
 * @param {object} data
 * data 就是要添加参照过滤的字段和对应参照过滤的数据格式 { name: QueryCondition, age: QueryCondition }
 */
export function setQueryCondition(tableId, data, shouldForceUpdate = true) {
    const keys = Object.keys(data); //所要设置参照过滤的字段数组
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    let meta = store.getMeta();
    let gridrelation = meta.gridrelation[tableId]; //模版配置项
    gridrelation.tabRelation.forEach(tabItem => {
        //遍历tab页的每个表体的对应字段的参照过滤
        meta[tabItem].items.forEach(item => {
            keys.forEach(key => {
                const { attrcode, children } = item;
                if (!isUndefined(children)) {
                    //判断和并列的情况
                    children.forEach(child => {
                        const { attrcode } = child;
                        if (key === attrcode) {
                            item.queryCondition = data[key];
                        }
                    });
                } else {
                    if (key === attrcode) {
                        item.queryCondition = data[key];
                    }
                }
            });
        });
    });
    if (isArray(gridrelation.destEditAreaCode)) {
        gridrelation.destEditAreaCode.forEach(formItem => {
            //遍历侧拉的每个部分的对应字段的参照过滤
            meta[formItem].items.forEach(item => {
                keys.forEach(key => {
                    if (key === item.attrcode) {
                        item.queryCondition = data[key];
                    }
                });
            });
        });
    }
    store.setMeta(meta, tableId, shouldForceUpdate && !this.isUpdatePage);
}

//---------------- 孙表API ----------------//
/**
 * 设置孙表数据
 * @author renyjk
 */
export function setGrandTableData({ rowid, parentId, tableId, data, shouldForceUpdate, callback, isCache = true, isTop = false, isDiffUpdate = false }) {
    let childStore = existenceCheck.call(this, tableId);
    if (!childStore) return;

    let parentStore = existenceCheck.call(this, parentId);
    if (!parentStore) return;
    parentStore.setGrandTableData({ tableId, rowid, data, isCache, shouldForceUpdate, isDiffUpdate });
    isFunction(callback) && callback({ ...this.props, ...this.output }, tableId);
}

/**
 * 获取全部孙表数据
 * @author renyjk
 */
export function getAllGrandData({ parentId, isDeepCopy }) {
    let parentStore = existenceCheck.call(this, parentId);
    if (!parentStore) return;
    return parentStore.getAllGrandData(isDeepCopy);
}

export function getViewGrandData({ parentId, view }) {
    let parentStore = existenceCheck.call(this, parentId);
    if (!parentStore) return;
    return parentStore.getViewGrandData(view);
}

/**
 * 删除完整的孙表数据中的数据
 * @author renyjk
 * parentId 父表id
 * rowidList rowid数组
 * delAll 是否全部删除
 */
export function delCacheGrandData({ parentId, rowidList = [], delAll = false }) {
    let parentStore = existenceCheck.call(this, parentId);
    if (!parentStore) return;
    parentStore.deleteGrandData({ rowidList, delAll });
}

/**
 * 通过pkcode获取子表对应的rowid
 * @author renyjk
 */
export function getRowIdFromPkcode({ parentId, plcodeValue }) {
    let parentStore = existenceCheck.call(this, parentId);
    if (!parentStore) return;
    let pkcode = parentStore.getStore('cardTable_pkcode');

    let data = getAllRows.call(this, parentId);
    let rowid = '';
    if (data) {
        data.forEach(row => {
            if (row.values[pkcode] && row.values[pkcode].value === plcodeValue) {
                rowid = row.rowid;
            }
        });
    }
    return rowid;
}

//-------------------- 内部方法--------------------//
/**
 * 拆解item项中有children的元素
 * @param  {Array}  items  传入的meta中items数组
 */
function _getAllItem(items) {
    if (!isArray(items)) return [];
    let list = [];
    let process = tree => {
        tree.forEach(item => {
            let { children, title, ...temp } = item;
            if (!children) {
                list.push(temp);
            }
            children && process(children);
        });
    };
    process(items);
    return list;
}

/**
 * 侧拉表单分组获取所有相关的items
 */
function _getEditRows(store, tableId) {
    let meta = store.getMeta();
    let editTableId = meta.gridrelation.hasOwnProperty(tableId)
        ? meta.gridrelation[tableId].destEditAreaCode ? meta.gridrelation[tableId].destEditAreaCode : []
        : [];

    // wangyang 强转数组
    if (Object.prototype.toString.call(editTableId) !== '[object Array]') {
        editTableId = Array(editTableId);
    }

    return editTableId.reduce((prev, editId) => {
        const item = meta[editId] ? meta[editId].items : [];
        prev = [...prev, ...item];
        return prev;
    }, []);
}

/**
 * 存储自定义的数据, 用于自定义列的筛选
 */

export function saveFilterCustomColData(tableId, { attrcode, rowId, value }) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    store.setCache(['customColData', attrcode, rowId], value);
}

/****74 获得当前行****/
export function getSideParentCurrentIndex(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;

    // 取出内部的 再转成外部的
    let index = store.getStore(['tableSideBox', 'index']);

    return store.getTrueRowIndex({ rowIndex: index }, 'normal', 'filter');
}

/**68
 * 获取行的状态
 * add by  zhangheng 18/06/21
 * @param {*} tableId 表格id
 * @param {*} index 行索引   数组或者数字
 */
export function updateTable(tableIds, autoFocus) {
    tableIds = Array.isArray(tableIds) ? tableIds : [tableIds];
    tableIds.forEach(tableId => {
        //   let cell = autoFocus && getCellDom(tableId);
        //   let cellIdentity = cell && getCellIdentity(cell);

        let store = existenceCheck.call(this, tableId);
        if (!store) {
            return;
        }
        //store.setTableProps('focusIndex', -1, false);
        let data = store.getData();
        store.saveRowsOldValue({ rows: data });
        let activeTableCode = store.getStore('activeTableCode');
        //若是多页签表格 只更新当前激活页签对应table 组件
        if (activeTableCode === tableId) {
            store.refresh();
        }


        // lastCellItemFocus(cellIdentity);
    });
}

// liuming excel 新方法
export function updateAfterBatchChange(param) {
    let { areaCode } = param;
    let store = existenceCheck.call(this, areaCode);
    if (!store) {
        return;
    }
    return store.updateAfterBatchChange(this, param);
}
//内部使用，不让业务组用
export function updateAndAddRows(param) {
    let { tableId } = param;
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    return store._updateAndAddRows(param);
}


/**
 *  根据wensocket推送值，更新表格数据
 * refreshData
 */

export function updateDataByRefresh(tableId, pkname, refreshData, saga_errormesg) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    if (Array.isArray(refreshData)) {
        let data = getAllRows.call(this, tableId, false);
        let refreshLen = refreshData.length;
        data.map((item, index) => {
            let values = item.values;
            for (let i = 0; i < refreshLen; i++) {
                //找到相应行,更新对应字段的value
                if (values[pkname] && values[pkname].value === refreshData[i][pkname]) {
                    for (let pop in refreshData[i]) {
                        if (values[pop]) {
                            values[pop].value = refreshData[i][pop];
                        } else {
                            values[pop] = { value: refreshData[i][pop] };
                        }

                        //有错误信息时，将错误信息数据放表格行
                        if (pop === 'saga_status' && refreshData[i][pop] === '1' && saga_errormesg) {
                            values.saga_errormesg = saga_errormesg;
                        }
                    }
                    break;
                }
            }
            return item;
        });
        store.setData({ data });
    }
}

export function refresh(tableId) {
    const store = existenceCheck.call(this, tableId);
    if (store) {
        store.anoterTable()
    }

}