export const name = 'NCCExtend';

export const emptyf = function () {
};

export const enable = window.NCCExtendEable || true;

export default {
    name,
    emptyf,
    enable
};