/*
 * @Descripttion: 
 * @version: 
 * @Author: liyxt
 * @Date: 2020-07-28 14:12:36
 * @LastEditors: bbq
 * @LastEditTime: 2020-08-24 10:57:52
 */
if (NODE_ENV !== 'development' && window.console) {
	var con = {};
	Object.keys(window.console).forEach(key => {
		con[key] = function() {};
	});
	window.console = Object.assign(window.console, con)
}

if (NODE_ENV !== 'development') {
    // eslint-disable-next-line no-undef
    console.log(`%c platform was built from ${BRANCH} at: ${PLATFORM_VERSION} `, 'background:#222;color:#bada55');
}

import 'babel-polyfill';
import createPage from '../../components/createPage.js';
import { promptBox, print, printer, output, ConnectSocket } from '@platform/components';
import * as base from '@platform/base';
import * as high from '@platform/components';
import { getTheme, setTheme, addFontClass } from '@platform/api';
import ExtendStyle from '../../FastExtend/ExtendStyle';

high.Refer = base.Refer;
high.BillTrack = base.BillTrack;
high.DagreChart= base.DagreChart;
high.BillErrorTrack = base.BillErrorTrack;
const DragWithCom = high.DragWidthCom;

let oldbase = {};
const importMap = {
    Breadcrumb: { NCBreadcrumbItem: 'Item' },
    Checkbox: { CheckboxGroup: 'CheckboxGroup' },
    Dnd: { GridLayout: 'GridLayout' },
    Form: { NCFormItem: 'FormItem' },
    Menu: {
        NCSubMenu: 'SubMenu',
        NCMenuItemGroup: 'ItemGroup',
        NCDivider: 'Divider',
        Item: 'Item',
    },
    Select: { NCOption: 'Option' },
    Step: { NCSteps: 'Steps' },
    Tabs: { NCTabPane: 'TabPane' },
    Tree: { NCTreeNode: 'TreeNode' },
    Upload: { Dragger: 'Dragger' },
    ButtonGroup: { NCButton: 'Button' },
    Modal: { Header: 'Header', Title: 'Title', Body: 'Body', Footer: 'Footer' },
    MultiLangText: { MultiOption: 'MultiOption' },
    Radio: {
        NCRadioGroup: 'RadioGroup',
        NCRadioButton: 'RadioButton',
    },
};

function bindNC(entries, output) {
    Object.keys(entries).forEach(key => {
        output['NC' + key] = entries[key];
        if (importMap[key]) {
            Object.keys(importMap[key]).forEach(subkey => {
                output['NC' + key][subkey] = entries[key][importMap[key][subkey]];
            })
        }
    })
}

bindNC(base, oldbase);

oldbase.NCScrollElement = base.Anchor.ScrollElement;
oldbase.NCScrollLink = base.Anchor.ScrollLink;
oldbase.NCFormControl = base.Input;
oldbase.EmptyAreaTip = base.EmptyAreaTip;
oldbase.NCNumber = base.NumberInput
oldbase.NCDatePicker.NCRangePicker = base.RangePicker;
oldbase.NCDatePicker.NCYearPicker = base.YearPicker;

let USUAL_KEYS = {};
Object.entries(oldbase.NCHotKeys.USUAL_KEYS).forEach(([key, value]) => {
    USUAL_KEYS['NC_' + key] = value;
});
oldbase.NCHotKeys.USUAL_KEYS = { ...(oldbase.NCHotKeys.USUAL_KEYS || {}), ...USUAL_KEYS };

ExtendStyle();

require('../../static/font/iconfont.css');
//添加标准单据样式
require('../../public/standardStyle/index.less');
// 添加给业务组的主题
require('../../public/standardStyle/theme-cls.less');

const themeTypes = ['default', 'red', 'green', 'black', 'blue', 'yellow'];
// setTheme({ themeType: 'red', themeTypes, baseName: 'nc-lightapp-front' });
// setTheme({ themeType: 'green', themeTypes, baseName: 'nc-lightapp-front' });
getTheme().then(themeType => {setTheme({ themeType, themeTypes, baseName: 'nc-lightapp-front' });addFontClass();});

const tableTotal = base.Table;

export * from '@platform/api';
// export * from 'core';
import { useJS } from 'core/api/useJS.js'
export {
    createPage,
    oldbase as base,
    high,
    promptBox,
    print,
    printer,
    ConnectSocket,
    output,
    tableTotal,
    DragWithCom,
    useJS,
};
