/*
 * @Descripttion: 兼容老的模式
 * @version: 
 * @Author: bbq
 * @Date: 2020-06-08 16:27:19
 * @LastEditors: bbq
 * @LastEditTime: 2021-01-15 14:20:42
 */
import React from 'react';
import { utils, CONFIG } from '@platform/table-core';

import { clearOneTypeToast, getCookie, toast, linkTo, PubSub, getLangCode } from '@platform/api';
import { beforeAndAfter, getCheckedExtendMethod } from '../../../../FastExtend/utils';

import EditTable from '@platform/edit-table';

const useEditTable = EditTable.useEditTable;

const {
    isFunction,
    isArray,
    isObject,
    warningOnce,
    isString,
    isUndefined,
    isPromise,
    isWrong,
    isNumber,
    getPageSizeStorage
} = utils;

const { ROW_STATUS } = CONFIG;
// 页面其他区域变化表格重新计算标识
const OTHERCOMPLETE = 'otherComplete';

/**
 *     ***********      方 法 目 录    ************
 *
 *     PS： 按习惯和常用性排序，新增方法请追加
 *
 *     序号      	英文名称              	 作用
 * 		1     	delRow       	        根据index的删除行方法
 * 		2     	getNumberOfRows        根据id获取表格中所有的行的数量
 * 		3     	getAllRows             获取表格内所有行数据（包含删除项目）
 * 		4     	getAllData     	 	   获取表格内全数据（包含删除项目）
 * 		5     	getAllRowsRemoveKeys   通过传入的键过滤不想要的数据
 * 		6     	addRow     		       通过index值新增行
 * 		7     	hideEditTableColByKey  根据id和column的key隐藏某列
 * 		8     	showEditTableColByKey  根据id和column的key显示某列
 *      9     	setValByKeyAndIndex    根据行序号设置表格某行某个字段值
 *      10      setTableData           设置表格数据，同时缓存数据，取消时调用
 * 		11    	cancelEdit      	   取消编辑
 * 		12   	setStatus     		   设置表格状态
 *      14      getCurrentStatus       获取表格状态
 * 		16      getChangedRows         获取变化行信息
 *      17      pasteRow               复制粘贴行，默认粘贴到该行下方
 *      18      setValByKeyAndRowId    根据rowid设置表格某行某个字段值
 * 		19      getValByKeyAndIndex    根据index获取表格某行某个字段值  0代表第一行  行序号可有可没有
 * 		20      getValByKeyAndRowId    根据rowId设置获取某行某个字段值
 * 		21      setEditableByKey       通过rowid和键设置表格某行某个字段编辑性
 * 		22      getColValue            获取表格某列数据
 * 		23      setColValue            设置表格某列数据
 *      24      getEditTableCacheDataById     获取表格初始数据
 *      25      filterEmptyRows               保存时 移除没有输入内容 所有的空行
 *      26      getTableCheckedRows           获取表格选中行
 *      27      selectAllTableRows            选中表格所有行
 *      28      reverseTableSelected          反转表格选中
 *      29      deleteTableRowsByIndex             通过index删除行
 *      30      deleteRowsByRowId             通过rowid删除行
 *      31      openEditModel                 打开编辑弹窗回调
 *      32      closeModel                关闭编辑弹窗回调
 *      33      focusRowByIndex               保存选中的行index
 *      34      setRowByIndex                 设置一行数据通过index
 *      35      getFilterRows                 搜索表格中特定数据的方法，
 *      36      setFiltrateTableData          搜索设置搜索后表格数据的方法，
 *      37      batchChangeTableData          批量更改表格数据
 *      38      setEditableRowByRowId         通过rowId设置表格某些行的编辑性
 *      39      setEditableRowByIndex         通过Index设置表格某些行的编辑性
 *      40      setEditableRowKeyByRowId      通过rowId设置表格某些行的某些字段编辑性
 *      41       setCheckboxDisabled            设置每行第一个Checkbox是否禁用
 *      42       setAllCheckboxAble         设置设置所有多选框禁用和启用的方法
 *      43       selectTableRows                设置某些行的选中状态
 *      44       setEditableRowKeyByIndex        通过index设置表格某些行的某些字段编辑性
 *      45       moveRow                         移动行的方法
 *      46       setRowStatus                    设置行的状态
 *      47       getRowStatus                    获取行的状态
 *      48       getClickRowIndex                获取点击行
 *      49       setClickRowIndex                设置点击行
 *      50       updateTableData                 通过rowid更新表格数据
 *      51       setRowPosition                  设置行位置
 *      52       getTableItemData                获得手动选中过的单元格信息
 *      53       setColScale                     设置某些表格某些列的精度
 *      54       checkRequired                   检验必输项的方法, 检验输入的行
 *      55       checkVisible                    检测某列是否隐藏的方法
 *      58       getTablePageInfo                获取分页信息包括pageindex  pagesize
 *      59       insertRowsAfterIndex            多行数据插入到index行后
 *      66       setEditableKeyEdit              批量设置表格的单元格的编辑性性
 */

// 订阅查询区变化常量
const COMPLETE = 'areaChangeComplete';

/**
 * 检测组件对象是否存在 bbq
 * @param {*} id 
 */
const existenceCheck = function (id) {
    let store = this.editTableDataSource[id]
    if (!store) {
        warningOnce(false, `所操作的表格中无ID为${id}的数据`);
        return;
    }
    return store;
}

/**
 * 检测行的index是否存在 bbq
 * @param {*} id 
 */
const indexCheck = function (id, index, view) {
    let store = existenceCheck.call(this, id);
    if (!store) { return; }
    let viewData = store.getViewData(view);
    if (isNaN(index) || index > viewData.length || index < 0) {
        warningOnce(index, '传入的第二个参数为行index值，须为大于等于0且小于等于总行数减一的整数');
        return -1;
    }
    return index;
}

// 处理旧值函数  bbq
export function saveChangedRowsOldValue(moduleId, rowid, attrcode, value) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) { return; }
    store.saveRowOldValue(rowid, attrcode, value);
}

/**
 * bbq
 * 对旧值处理的函数, 这原来是标识是index,但是增加筛选后,
 * 对筛选结果进行编辑,使用index作为标识存取新旧值,会导致存取值错误,
 * 所以改成现在rowid为标识, 但是对旧值处理的方法,已经对外暴露,如果出现问题,需要业务组配合修改,
 * 将对新旧值的处理换成rowid为标识
 * */
export function getChangedRowsOldValue(moduleId, rowid, attrcode) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) { return; }
    return store.getRowOldValue(rowid, attrcode);
}

// 删除旧值函数 bbq
export function delChangedRowsOldValue(moduleId, rowid, attrcode) {
    let store = existenceCheck.call(this, moduleId);
    if (!store) { return; }
    store.deleteRowOldValue(rowid, attrcode);
}

/**
 * bbq
 * @name:  创建表格
 * @test: test font
 * @msg: 
 * @param {type} 
 * @return: 
 */
export function createEditTable(moduleId, config = {}) {
    // return <EditTableContainer moduleId={moduleId} pageScope={this} config={config} />;
    let pageScope = this,
        store = pageScope.editTableDataSource[moduleId];
    if (!store) {
        store = useEditTable();
        pageScope.editTableDataSource[moduleId] = store;
        console.warn('没有store 请检查下  use方法');
        // 如果有缓存值 则做一下  Store 的赋值操作  把setTableData里的else操作提到这里了
        pageScope.editTableDataSource.myEditData = pageScope.editTableDataSource.myEditData || {};
        if (pageScope.editTableDataSource.myEditData[moduleId]) {
            store.setTableData(pageScope.editTableDataSource.myEditData[moduleId]); // TODO Check 
            store.setTableProps('focusIndex', -1, false);
            delete pageScope.editTableDataSource.myEditData[moduleId];
        }
    }

    // console.log('editTableStore', store);

    // TODO config 转化  moduleId 转化
    let {
        onRowClick, onRowDoubleClick,
        statusChange, onSelected, onSelectedAll, selectedChange,
        onBeforeEvent, onAfterEvent, inputChange,
        rowClassList,
        isAddRow, addRowCallback, addRowDefaultValue,
        showPagination,
        handlePageInfoChange,
        // 这是表格高度 不是行高
        height,
        adaptionHeight,
        setCellClass,
        onBatchSelected,
        alloweFilter,
        onCloseModel,
        tableModelConfirm,
        fieldid,
        ...others
    } = config;
    store.setConfig(config)//把config放到store里
    // 挂载方法  不一定bind
    isFunction(statusChange) && (store.statusChange = config.statusChange = statusChange.bind(this));
    isFunction(selectedChange) && (store.selectedChange = config.selectedChange = selectedChange.bind(this));
    isFunction(onSelected) && (store.onSelected = config.onSelected = onSelected.bind(this));

    // renderItem 适配
    let renderItems = {};
    if (pageScope.state.renderItem && pageScope.state.renderItem.table) {
        renderItems = (pageScope.state.renderItem.table || {})[moduleId] || {};
    }
    // 表格是否自适应高度
    let adaption = !!(isUndefined(height) && adaptionHeight);

    return <EditTable
        onCloseModel={data => {
            isFunction(onCloseModel) && onCloseModel.call(
                pageScope,
                { ...pageScope.props, ...pageScope.output },
                moduleId,
                data,
            );
        }}
        tableModelConfirm={data => {
            isFunction(tableModelConfirm) && tableModelConfirm.call(
                pageScope,
                { ...pageScope.props, ...pageScope.output },
                moduleId,
                data,
            );
        }}
        fieldid={fieldid}
        cellClassName={({ record, rowIndex, attrcode }) => {
            // 转换一下index
            rowIndex = store.getTrueRowIndex({ rowIndex: rowIndex }, 'normal', 'filter');

            return isFunction(setCellClass) && setCellClass(rowIndex, record, attrcode);
        }}
        onRowClick={(record, outputIndex, e) => {
            // 转换一下index
            outputIndex = store.getTrueRowIndex({ rowIndex: outputIndex }, 'normal', 'filter');

            return isFunction(onRowClick) && onRowClick.call(
                pageScope,
                { ...pageScope.props, ...pageScope.output },
                moduleId,
                record,
                outputIndex,
                e,
            )
        }}
        onRowDoubleClick={(record, outputIndex, e) => {
            // 转换一下index
            outputIndex = store.getTrueRowIndex({ rowIndex: outputIndex }, 'normal', 'filter');

            return isFunction(onRowDoubleClick) && onRowDoubleClick.call(
                pageScope,
                record,
                outputIndex,
                { ...pageScope.props, ...pageScope.output },
                e,
            )
        }}
        onSelected={(tableId, record, selected, outputIndex, isUnique) => {

            // 转换一下index
            outputIndex = store.getTrueRowIndex({ rowIndex: outputIndex }, 'normal', 'filter');

            isFunction(onSelected) && onSelected.call(
                pageScope,
                { ...pageScope.props, ...pageScope.output },
                tableId,
                record,
                outputIndex,
                selected,
                isUnique,
            )
        }}
        selectedChange={(tableId, newLen, oldLen) => {
            isFunction(selectedChange) && selectedChange.call(pageScope, { ...pageScope.props, ...pageScope.output }, tableId, newLen, oldLen);
        }}
        onSelectedAll={(tableId, checkedAll, len) => {
            isFunction(onSelectedAll) && onSelectedAll.call(pageScope, { ...pageScope.props, ...pageScope.output }, tableId, checkedAll, len);
        }}
        autoAddRow={{
            enabled: isAddRow,
            callback: newRow => {
                addRowCallback && addRowCallback(moduleId, newRow);
            },
            defaultValue: addRowDefaultValue,
        }}
        onChange={({ rowKey, rowKeyValue, rowIndex, attrcode, value, column, e }) => {
            // 为郭祉琪加的针对input类型时,这两个字段的特殊校验,换算率
            if (column.itemtype === 'input' && ['vchangerate', 'vqtunitrate'].includes(column.attrcode)) {
                // eslint-disable-next-line no-useless-escape
                let reg = /^[0-9\/\.]+$/;
                if (value && !reg.test(value)) { //只能输入数字和/
                    return;
                }
            }
            //胡杰李忠博加
            if (isFunction(config.inputChange)) {
                // 转换一下index
                rowIndex = store.getTrueRowIndex({ rowIndex }, 'normal', 'filter');
                let callbackValue = config.inputChange({ index: rowIndex, item: column, valueChange: value.value });
                if (!callbackValue) {
                    return;
                }
            }
        }}
        onBeforeEvent={async params => {
            let {
                record, rowKey, rowKeyValue, rowIndex,
                attrcode, column, value = {},
                event,
                triggerType = 'onChange', // onChange || onBlur
                isTriggerModal = false,
            } = params;

            // 转换一下index // 影响客开 暂不处理
            // if (isFunction(onBeforeEvent)) {
            rowIndex = store.getTrueRowIndex({ rowIndex }, 'normal', 'filter');
            // }

            let fxParams = {
                moduleId,
                record,
                attrcode: attrcode,
                methods: pageScope.output,
                column,
                index: rowIndex,
                val: record.values[attrcode],
            };

            return await beforeAndAfter(
                pageScope,
                { moduleId, attrcode: attrcode, fxType: 'onEditTableBeforeEvent', fxParams },
                () => {
                    // console.log('onBeforeEvent', 'ssssssssssss')
                    return isFunction(onBeforeEvent) ? onBeforeEvent(
                        { ...pageScope.props, ...pageScope.output },
                        moduleId,
                        column,
                        rowIndex,
                        value,
                        record,
                    ) : true;
                }) !== false;
        }}
        onAfterEvent={params => {
            let {
                record, rowKey, rowKeyValue, rowIndex,
                attrcode, column, value = {},
                oldValue = {},
                componentValue,
                event,
                triggerType = 'onChange', // onChange || onBlur
                isTriggerModal = false,
            } = params;

            let val = value.value;

            // 为郭祉祺加的针对input类型时,这两个字段的特殊校验,换算率
            if (column.itemtype === 'input' && ['vchangerate', 'vqtunitrate'].includes(attrcode)) {
                let reg = /^([1-9][0-9]*|0)(\.\d{1,})?(\/)(([1-9][0-9]*)(\.\d{1,})?|(\d\.\d{1,}))$/;
                if (val && val.length < 1000 && !reg.test(val)) {
                    if (!isTriggerModal) {
                        store.setCellProps(rowKeyValue, attrcode, { isEdit: false });
                    }
                    store.setCellProps(rowKeyValue, attrcode, { value: null });
                    // toast({
                    //     content: `${json['table0025']}'1/2'!`,
                    //     color: 'danger',
                    //     groupOperation: true,
                    //     isNode: true,
                    //     TextArr: [json['table0022'], json['table0023'], json['table0024']]
                    // });
                    return;
                }
            }

            let changedRows = [];

            // editItem.props.hasOwnProperty('isMultiSelectedEnabled') && (isMul = editItem.props.isMultiSelectedEnabled);
            let isMulti = column.isMultiSelectedEnabled;
            if (column.refcode) {
                let refcode = column.refcode.replace('.js', '');
                if (refcode.startsWith('/')) refcode = refcode.substring(1);
                let refer = window[refcode] && window[refcode].default();
                if (refer && refer.props && refer.props.isMultiSelectedEnabled) {
                    isMulti = refer.props.isMultiSelectedEnabled;
                }
            }
            // 多选参照的判断
            let valueArr = [];
            if (isMulti && oldValue) {
                let oldValArr = isString(oldValue.value) ? oldValue.value.split(',') :
                    (isArray(oldValue.value) ? oldValue.value : [oldValue.value]);

                valueArr = isString(value.value) ? value.value.split(',') :
                    (isArray(value.value) ? value.value : [value.value]);

                valueArr.forEach((val, i) => {
                    changedRows.push({
                        rowid: record.rowid,
                        newvalue: { value: val || '' },
                        oldvalue: { value: oldValArr[0] || '' },
                    });
                });

            } else {
                changedRows.push({
                    rowid: record[rowKey],
                    newvalue: { value: val || '' },
                    oldvalue: { value: oldValue.value || '' },
                });
            }

            store.saveRowOldValue(record[rowKey], attrcode, valueArr.length > 0 ? valueArr[0] : val);


            // 转换一下index // 影响客开 暂不处理
            // if (isFunction(onAfterEvent)) {
            rowIndex = store.getTrueRowIndex({ rowIndex }, 'normal', 'filter');
            // }

            if (column.editAfterFlag && pageScope.handleRelationItems) {
                pageScope.handleRelationItems({
                    type: 'table',
                    areaCode: moduleId,
                    key: attrcode,
                    changedrows: changedRows,
                    index: rowIndex,
                    rowid: record[rowKey],
                    record: record,
                    column: column,
                    callback: () => {
                        let fxParams = {
                            moduleId,
                            record: record,
                            attrcode: attrcode,
                            methods: pageScope.output,
                            val: column.itemtype === 'refer' || column.itemtype === 'residtxt' ? componentValue : value.value,
                            changedRows,
                            index: rowIndex,
                            type: 'table',
                            actionTag: triggerType === 'onBlur' ? 'blur' : 'change',
                        };
                        beforeAndAfter(
                            pageScope,
                            { moduleId, attrcode: attrcode, fxType: 'onEditTableAfterEvent', fxParams },
                            () => {
                                // 客开先不加了
                                isFunction(onAfterEvent) && onAfterEvent(
                                    {
                                        ...pageScope.props,
                                        ...pageScope.output,
                                    },
                                    moduleId,
                                    attrcode,
                                    // 特殊类型值的返回
                                    column.itemtype === 'refer' || column.itemtype === 'residtxt' ? componentValue : value.value,
                                    changedRows,
                                    rowIndex,
                                    record,
                                    isTriggerModal ? 'modal' : 'line',
                                    triggerType === 'onBlur' ? 'blur' : 'change',
                                    event,
                                );
                            });
                    },
                });
            } else {
                let fxParams = {
                    moduleId,
                    record: record,
                    attrcode: attrcode,
                    methods: pageScope.output,
                    val: column.itemtype === 'refer' || column.itemtype === 'residtxt' ? componentValue : value.value,
                    changedRows,
                    index: rowIndex,
                    type: 'table',
                    actionTag: triggerType === 'onBlur' ? 'blur' : 'change',
                };
                beforeAndAfter(
                    pageScope,
                    { moduleId, attrcode: attrcode, fxType: 'onEditTableAfterEvent', fxParams },
                    () => {
                        console.log('onAfterEvent', 'onAfterEventonAfterEventonAfterEventonAfterEventonAfterEvent');
                        isFunction(onAfterEvent) && onAfterEvent(
                            {
                                ...pageScope.props,
                                ...pageScope.output,
                            },
                            moduleId,
                            attrcode,
                            // 特殊类型值的返回
                            column.itemtype === 'refer' || column.itemtype === 'residtxt' ? componentValue : value.value,
                            changedRows,
                            rowIndex,
                            record,
                            isTriggerModal ? 'modal' : 'line',
                            triggerType === 'onBlur' ? 'blur' : 'change',
                            event,
                        );
                    });
            }
        }}
        rowClassName={rowClassList}
        store={store}
        editTableComponentUpdate={props => {
            // console.log('editTableComponentUpdate', props);
            getCheckedExtendMethod(pageScope, 'updateEditTable')({ pageScope, data: store.getViewData(), props, useOn: 'create' });
        }}
        afterLangload={(json, store) => {
            // console.log(this, json);
            pageScope.editTableDataSource._langJson = json || {};
        }}
        onBatchSelected={!onBatchSelected ? false : rows => {
            return onBatchSelected && onBatchSelected({
                ...pageScope.props,
                ...pageScope.output,
            }, moduleId, rows);
        }}
        showPagination={showPagination}
        onPageInfoChange={(pks, total) => {
            isFunction(handlePageInfoChange) && handlePageInfoChange({ ...pageScope.props, ...pageScope.output },
                config,
                pks,
                total);
        }}
        tableId={moduleId}
        bodyStyle={{ minHeight: config.height || 300 }}
        useFixedHeader={true}
        scroll={{
            x: true,
            y: config.height || 300,
        }}
        adaptionHeight={adaption}
        onCellHyperlinkTo={(record, attrcode, value, tableId) => {
            linkTo(pageScope.state.meta.pageid, tableId, attrcode, value.value);
        }}
        renderItems={renderItems}
        high_filter={{ alloweFilter }}
        // 快捷键 解耦
        getHotKeyConfig={() => {
            return {
                pageScope: pageScope,
                autoFocusDeferred: this.autoFocusDeferred,
                meta: this.state.meta,
                withHotKeyChange: this.withHotKeyChange,
                orderOfHotKey: this.orderOfHotKey,
                onLastFormEnter: config.onLastFormEnter,
            };
        }}
        {...others}
    />
}

/*-------------------当前--------------------*/

/*-------------------工具--------------------*/

/*-------------------行--------------------*/

/**
 * bbq
 * @description: 
 * @param {type} 
 * @return: 
 */
export function delRow(tableId, index, isDeepDel) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return; }
    store.deleteTableRows({ rowIndexs: index }, isDeepDel, true, undefined, 'filter');
}

// bbq
export function deleteTableRowsByIndex(tableId, index, isDeepDel, callback) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return; }
    store.deleteTableRows({ rowIndexs: index }, isDeepDel, true, () => {
        isFunction(callback) && callback();
    }, 'filter');

    let newLen = store.getSelectedRows(false, 'filter').length;
    isFunction(store.selectedChange) && store.selectedChange({ ...this.props, ...this.output }, tableId, newLen);
}

// bbq
export function deleteTableRowsByRowId(tableId, rowid, isDeepDel) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return; }
    store.deleteTableRows({ rowKeyValues: rowid }, isDeepDel);
    let newLen = store.getSelectedRows(false, 'filter').length;
    isFunction(store.selectedChange) && store.selectedChange({ ...this.props, ...this.output }, tableId, newLen);
}

// bbq
export function getAllRows(tableId, isDeepCopy = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return this.editTableDataSource.myEditData[tableId] ? this.editTableDataSource.myEditData[tableId].rows : []; }
    return store.getAllRows(isDeepCopy);
}

// bbq
export function getVisibleRows(tableId, isDeepCopy = true, isFilter = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    return store.getVisibleRows(isDeepCopy, isFilter ? 'normal' : 'filter');
}

// bbq
export function getNumberOfRows(tableId, flag) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return 0;
    }
    return flag ? getAllRows.call(this, tableId, false).length : getVisibleRows.call(this, tableId, false).length;
}

// bbq
export function getAllData(tableId, flag = true) {
    let rows = getAllRows.call(this, tableId, flag);
    let data = {
        areaType: 'table',
        rows,
        areacode: null,
    };
    return data;
}

// 获取某个视图的数据
export function getViewData(tableId, view = 'normal', isDeepCopy = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return 0;
    }
    let data = store.getViewData(view);
    return data;
}

// bbq
export function getAllRowsRemoveKeys(tableId, keys) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    return store.getAllRowsRemoveKeys(keys);
}

// bbq
export function addRow(tableId, index, autoFocus = false, defaultValue, callback, isAutoAddRow, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    if (!defaultValue) defaultValue = {};
    store.addTableRow(index, defaultValue, !this.isUpdatePage && shouldForceUpdate, {
        autoFocus,
        isAutoAddRow,
        view: 'filter',
        callback: () => {
            callback && callback.call(this, tableId);
        },
    });

    isFunction(store.statusChange) && store.statusChange(store.getTableProps('status'));
}

// bbq
export function filterEmptyRows(tableId, keys, rule = 'except', isDeepDel = true) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }
    store.removeEmptyRows({ keys, rule, shouldForceUpdate: !this.isUpdatePage, deep: isDeepDel });
}

// TODO
export function getCurrentRecord(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    const rows = getAllRows.call(this, tableId);
    let currentIndex = store.getTableProps('currentIndex');
    // 这里用allRows也没有问题，因为allRows里处理了删除数据放到了最后，所以还是过滤的视图
    const currentRecord = rows[currentIndex];
    return currentRecord;
}

// TODO
export function focusRowByIndex(tableId, index, callback) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }

    let normalIndex = store.getTrueRowIndex({ rowIndex: index }, 'filter', 'normal');
    // TODO setCurrnetInfo 
    // TODO 设置选中行 和 聚焦行
    const rowKey = store.getTableProps('rowKey');
    store.setSelectedIndex([], 'clear', false);
    store.setSelectedIndex([{ rowKey, rowIndex: index, pattern: 'add' }], undefined, false, 'filter');

    store.setTableProps('focusIndex', normalIndex, false);
    store.setTableProps('currentIndex', index, true, () => {
        store.setTableProps('focusIndex', -1, false);
        isFunction(callback) && callback();
    });

}

// bbq
export function setRowByIndex(tableId, record, index) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    store.updateTableDataByIndexs([{ index, data: record }], undefined, true, 'filter');
}

// bbq
export function setEditableRowByRowId(tableId, rowid, flag, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    store.setRowsCellPropsByKey(rowid, { disabled: !flag }, !this.isUpdatePage && shouldForceUpdate);
}

// bbq
export function setEditableRowByIndex(tableId, index, flag, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    store.setRowsCellPropsByIndex(index, { disabled: !flag }, !this.isUpdatePage && shouldForceUpdate, undefined, 'filter');
}

// bbq
export function setEditableRowKeyByRowId(tableId, rowid, key, flag, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    store.setCellsProps({ rowKeyValues: rowid, attrcodes: key, value: { disabled: !flag }, shouldForceUpdate: !this.isUpdatePage && shouldForceUpdate });
}

// bbq
export function getFilterRows(tableId, key, value) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    let langCode = getLangCode();

    return store.getFilterRows(key, value, langCode);
}

// bbq
export function setFiltrateTableData(tableId, key, value, isShowAll) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    let langCode = getLangCode();
    return store.setFiltrateTableData(key, value, isShowAll, langCode, tableId);
}

// bbq
export function moveRow(tableId, indexStart, indexEnd) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return; }
    store.moveRow(indexStart, indexEnd);
}

// bbq
export function setRowStatus(tableId, index, flag) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    store.setRowsProps({ rowIndexs: index, view: 'filter' }, { status: flag }, !this.isUpdatePage);
}

// bbq
export function getRowStatus(tableId, index) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    return store.getRowProps({ rowIndex: index, view: 'filter' }, 'status');
}

// bbq
export function getClickRowIndex(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return null; }

    let data = store.getTableProps("currentInfo");

    if (data && (data.index || data.index === 0)) {
        data.index = store.getTrueRowIndex({ rowIndex: data.index }, 'normal', 'filter');
    }

    return data;
}

// bbq
export function setClickRowIndex(tableId, data) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return null; }

    if (data && (data.index || data.index === 0)) {
        data.index = store.getTrueRowIndex({ rowIndex: data.index }, 'filter', 'normal')
    }

    return store.setTableProps("currentInfo", data, false);
}

// TODO
export function setRowPosition(tableId, indexStart, position) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    return store.setRowPosition(indexStart, position);
}

// bbq
export function insertRowsAfterIndex(tableId, data, index) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    store.insertRowsAfterIndex(data, index, true, 'filter');
}

// bbq
export function insertDataByIndexs(tableId, record) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    store.insertDataByIndexs(record, false, true, 'filter');
    // TODO
    //     let cell = autoFocus && getCellDom(tableId);
    //   let cellIdentity = cell && getCellIdentity(cell);
    return {
        // ...myCardScope.state.table,
        rows: store.getData(),
    }
}

// bbq
export function updateRows(tableId, data, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    store.updateRows(data, shouldForceUpdate);
}

// bbq
export function updateRowsProps(tableId, data, props = {}, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    store.updateRowsProps(data, props, shouldForceUpdate);
}

// bbq
export function resetTableCaches(tableId, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    store.resetTableCaches(shouldForceUpdate);
}

// bbq
export function updateDataByIndexs(tableId, record, flag = false, isDel = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    // if (isDel) {
    //     // 过滤点当前删除状态的数据
    //     !myEditScope.filterAllData && (myEditTable.rows = tableData.filter(item => {
    //       return item.status !== '3';
    //     }));
    //     myEditScope.filterAllData && (myEditScope.filterAllData = tableData.filter(item => {
    //       return item.status !== '3';
    //     }));
    //   }
    store.updateTableDataByIndexs(record, flag, true, 'filter');
}

/*-------------------列--------------------*/

// bbq
export function getColValue(tableId, key) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    let rows = store.getData();
    // 因为筛选而加的代码
    let newData = {
        value: [],
        display: [],
    };
    rows.find(item => {
        newData.value.push(item.values[key].value);
        if (item.values[key].display) {
            newData.display.push(item.values[key].display);
        }
    });
    return newData;
}

// bbq
export function setColValue(tableId, key, { display, value, scale }, isSetEdit = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    let rows = store.getData();
    rows.find(item => {
        const { status, rowid } = item;
        // 已经被删除的行不处理
        if (status == ROW_STATUS.delete) {
            return;
        }
        if (status == ROW_STATUS.origin && isSetEdit) {
            item.status = ROW_STATUS.edit;
        }
        if (!isObject(item.values[key])) {
            item.values[key] = {};
        }
        if (!isUndefined(value)) {
            item.values[key].value = value;
            // 给新复制的行存旧值
            saveChangedRowsOldValue.call(this, tableId, rowid, key, value);
        }
        !isUndefined(display) && (item.values[key].display = display);
        !isUndefined(scale) && (item.values[key].scale = scale);
    });
    store.setData({ data: rows });
}

// TODO  这个方法态暴力
export function setColScale(list) {
    list.forEach(eve => {
        const { areacode, fieldcode, scale } = eve;
        this.state.meta[areacode].items.forEach(item => {
            if (item.attrcode === fieldcode) {
                item.scale = scale;
            }
        });
    });
    /**重新设置模版精度*/
    this.meta.setMeta(this.state.meta);
}

/**隐藏列
 * @author renyjk
 * @param {string} tableId
 * @param {string || array} key
 */
export function hideColByKey(tableId, key) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    if (isString(key)) key = [key];
    key.forEach(code => {
        store.setColumn(tableId, { [code]: { visible: false } }, false);
    });

    // 统一更新一次
    store.refresh();
}

/**显示列
 * @author renyjk
 * @param {string} tableId
 * @param {string || array} key
 */
export function showColByKey(tableId, key) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    if (isString(key)) key = [key];
    key.forEach(code => {
        store.setColumn(tableId, { [code]: { visible: true } }, false);
    });

    // 统一更新一次
    store.refresh();
}

/*-------------------单元格--------------------*/
// bbq
export function setValByKeyAndIndex(tableId, index, key, cellValue, isSetEdit = true, isUpdateBatch = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    store.setValByKeyAndIndex(index, key, cellValue, { isSetEdit, isUpdateBatch, shouldForceUpdate: !this.isUpdatePage, view: 'filter' })
}

// bbq
export function setValByKeyAndRowId(tableId, rowid, key, cellValue, isSetEdit = true, isUpdateBatch = false) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    if (!rowid) {
        return;
    }
    const row = store.getRows({ rowKeyValues: rowid })[0];
    if (!row) {
        return;
    }
    store.setValByKeyAndRowId(rowid, key, cellValue, { isSetEdit, isUpdateBatch, shouldForceUpdate: !this.isUpdatePage })
}

// bbq
export function getValByKeyAndIndex(tableId, index, key) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    let len = store.getData().length;
    if (len === 0) {
        console.log('无数据');
        return;
    }

    index = indexCheck.call(this, tableId, index, 'filter');
    if (index === -1) { return; }

    return store.getValByKeyAndIndex(index, key, 'filter');
}

// bbq
export function getValByKeyAndRowId(tableId, rowid, key) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    if (!rowid) {
        return;
    }
    return store.getValByKeyAndRowKey(rowid, key);
}

// bbq
export function setEditableByKey(tableId, rowid, key, flag) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    store.setCellProps(rowid, key, { disabled: !flag }, !this.isUpdatePage);
}

// bbq
export function setEditableRowKeyByIndex(tableId, index, key, flag, shouldForceUpdate = true) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    store.setCellsProps({
        rowIndexs: index,
        attrcodes: key,
        value: { disabled: !flag },
        shouldForceUpdate: !this.isUpdatePage && shouldForceUpdate,
        view: 'filter',
    });
}

// TODO  父级禁用  子集是否禁用
export function checkVisible(tableId, colKey) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }
    let column = store.getColumn(tableId, colKey) || {};
    return column.visible;
}

/*-------------------表格--------------------*/
// bbq
export function setTableData(tableId, data, isCache = true, isTop = false) {
    if (!data || !data.rows || !Array.isArray(data.rows)) {
        warningOnce(data, '传入的第二个参数为所设置的数据，数据格式是对象，且有个rows属性，rows的内容是数组');
        return;
    }
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        this.editTableDataSource.myEditData = this.editTableDataSource.myEditData || {};
        this.editTableDataSource.myEditData[tableId] = data;
        // 设置值的逻辑  迁移到 createEditTable 方法里
        return;
    }
    // 设置表格数据
    data = store.setTableData(data, { isCache, isTop });

    // 解决组件销毁后，再次赋值，赋到了原来缓存的组件上，导致新的组件没有值的问题
    this.editTableDataSource.myEditData = this.editTableDataSource.myEditData || {};
    this.editTableDataSource.myEditData[tableId] = data;

    // 调用selectedChange事件
    isFunction(store.selectedChange) && store.selectedChange({ ...this.props, ...this.output }, tableId, 0);
}

// bbq
export function cancelEdit(tableId, callback) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    store.setTableProps('status', 'browse', false);
    clearOneTypeToast('danger');
    clearRequiredStatus.call(this, tableId);

    setTableData.call(
        this,
        tableId,
        JSON.parse(JSON.stringify(store.getCache(['afterDel']) || (store.getCache(['base']) || { rows: [] }))),
    );
    // 重新计算表格高度
    PubSub.publish(COMPLETE, true);
    callback && callback.call(this);
}

//  清除校验状态
export function clearRequiredStatus(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    let validateFailed = store.getCache('validateFailed') || {};

    for (let rowKeyValue in validateFailed) {
        (validateFailed[rowKeyValue] || []).forEach(attrcode => {
            store.setCellProps(rowKeyValue, attrcode, { validated: true }, false);
        })
    }

    // 去除校验状态
    store.setCache('validateFailed', null);
    store.setCache('firstValidateFaild', null);
}

// bbq
export function setStatus(tableId, status, callback) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    let oldStatus = store.getTableProps('status') || 'browse';
    // 切换成浏览态的时候把所有的toast清掉
    if (oldStatus !== 'browse' && status == 'browse') {
        clearOneTypeToast('danger');
        clearRequiredStatus.call(this, tableId);
    }

    store.setTableStatus(status, { shouldForceUpdate: !this.isUpdatePage });

    isFunction(store.statusChange) && store.statusChange(status);


    isFunction(callback) && callback.call(
        this,
        tableId,
        status,
        JSON.parse(JSON.stringify(store.getCache(['base']))),
        store,
        // this.editTableDataSource[tableId].state.table,  // 这个不知道业务有没有用过
    );
}

// bbq
export function getStatus(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    return store.getTableProps('status') || 'browse';
}

// bbq
export function getChangedRows(tableId) {
    //判断修改的行，值是否和改之前一样，如果一样，把status设为0
    let store = existenceCheck.call(this, tableId);
    if (!store) { return []; }
    return store.getChangedRows();
}

// bbq
export function pasteRow(tableId, data, index) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    // filter => normal
    index = index === undefined ? 0 : store.getTrueRowIndex({ rowIndex: index }, 'filter') + 1;

    store.pasteRow({ copyRow: data, index, view: 'filter' });
}

// bbq
export function getCacheDataById(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    let data = store.getCache(['base']) || {};
    return JSON.parse(JSON.stringify(data));
}

// bbq
export function getCacheForEditModal(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    let data = store.getCache(['beforeEdit']) || {};
    return JSON.parse(JSON.stringify(data));
}

/**
  根据key键获取模板中数据
 * @author renyjk
 * @param {string} tableId
 * @param {string} key
 * @param {boolean} flag 增加flag标识位，判断是否为多表头，默认是false，不是多表头
 */
export function setColEditableByKey(tableId, key, flag = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    let meta = store.getMeta();
    let items = meta && meta[tableId] && meta[tableId].items;
    let destEditAreaCode =
        meta.gridrelation &&
        meta.gridrelation[tableId] &&
        meta.gridrelation[tableId].destEditAreaCode;
    if (isArray(items)) {
        // 处理多表头
        let fn = (items, key) => {
            items.map(item => {
                if (item.children) {
                    fn(item.children, key);
                }
                if (item.attrcode === key || key.includes(item.attrcode)) {
                    item.disabled = flag;
                    /*兼容设置侧拉框同字段的编辑性start 米明天试试，看看好使不 之后在来完善先注释掉*/
                    if (destEditAreaCode) {
                        destEditAreaCode.forEach(eve => {
                            //便利侧拉模版数组里的每项，所对应的模版
                            meta[eve].items.forEach(formItem => {
                                if (formItem.attrcode === item.attrcode) {
                                    formItem.disabled = flag;
                                }
                            });
                        });
                    }
                    /*兼容设置侧拉框同字段的编辑性end*/
                }
            });
        };
        fn(items, key);
        store.setMeta(meta);
    }
}

// bbq
export function updateTableData(tableId, data, callback, isCache = true) {
    if (!isObject(data) || !isArray(data.rows)) {
        warningOnce(data, '传入的第二个参数为所设置的数据，数据格式是对象，且有个rows属性，rows的内容是数组');
        return;
    }

    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    store.updateTableData(data, isCache);

    isFunction(callback) && callback();
}

/**
 * bbq
 * @description: 表格校验
 * @param {type} 
 * @return: 
 */
export function checkRequired(tableId, rows, autoFocus = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    let json = store.getCache('langJson');

    let metaItems = store.getArrayMeta(tableId);

    if (!metaItems) {
        warningOnce(false, `所操作的表格中无ID为${tableId}的meta`);
        return false;
    }

    if (isArray(rows)) {
        const allToast = {}; //保存所有检验提示信息

        // 校验
        const validateFailed = {};
        let firstValidateFaild = null;
        const rowKey = store.getTableProps('rowKey');

        // 过滤不可见
        metaItems = metaItems.filter(e => e.visible);

        metaItems.forEach(item => {
            //将数据中没有的列附上空对象，保证校验的准确性
            const { attrcode, required, label } = item;
            if (required) {
                rows.forEach((row, index) => {
                    let { status, values = {} } = row;
                    if (status !== ROW_STATUS.delete) { // 去除假删除的
                        if (isUndefined(values[attrcode])) {
                            values[attrcode] = {};
                        }
                        let value = values[attrcode].value;
                        if (isWrong(value) || (isArray(value) && value.length === 0)) {
                            // 记录需要提示的值
                            allToast[index] = allToast[index] || [];
                            allToast[index].push(`[${label}]`);

                            if (!firstValidateFaild) {
                                firstValidateFaild = [row[rowKey], attrcode];
                            }

                            // 设置校验状态
                            validateFailed[row[rowKey]] = validateFailed[row[rowKey]] || [];
                            validateFailed[row[rowKey]].push(attrcode);
                            store.setCache('validateFailed', validateFailed);
                            store.setCellProps(row[rowKey], attrcode, { validated: false }, false);
                        }
                    }
                });
            }
        });

        if (Object.keys(allToast).length > 0) {
            let finalContent = ''; //最后展示的content
            console.log(store, validateFailed, firstValidateFaild, 'validateFailed');
            validateFailed && store.refresh();

            // 聚焦到首个
            if (firstValidateFaild && autoFocus) {
                // cellselectorkey="hhghg-34343m43-434343wws@single1"
                let cellFocusElement = document.querySelector(`.table-cell-wrapper[cellselectorkey="${firstValidateFaild[0]}@${firstValidateFaild[1]}"] [tabindex="0"]`);

                if (cellFocusElement) {
                    cellFocusElement.ncExecuteFocus = true;
                    cellFocusElement.focus();
                    cellFocusElement.ncExecuteFocus = false;
                }
            }

            Object.keys(allToast).forEach(item => {
                //对输出的校验内容进行格式转化
                finalContent += `${json && json['table_first']}${+item + 1} ${json && json['table_row']}: ${allToast[item].join(
                    '，'
                )}；`;
            });
            toast({
                content: `${json && json['table_no_empty']}：`,
                color: 'danger',
                TextArr: [json['table0022'], json['table0023'], json['table0024']],
                groupOperation: true,
                groupOperationMsg: finalContent.split('；').map(item => (item ? item + '；' : item)),
                mark: 'edit-table-toastx'
            });
            return false;
        } else {
            // 清理错误消息
            // let validateFailed = store.getCache('validateFailed');
            // if (validateFailed) {
            //     for (let rowKeyValue in validateFailed) {
            //         if (validateFailed[rowKeyValue]) {
            //             validateFailed[rowKeyValue].forEach(attrcode => {
            //                 store.setCellProps(rowKeyValue, attrcode, { validated: true }, false);
            //             })
            //         }
            //     }
            //     store.refresh();
            // }
            return true;
        }
    } else {
        warningOnce(false, '所传入的第二个参数rows应是数组，请传入数组');
        return false;
    }
}

// TODO 未检验
export function updateDifference(tableId, data) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    let testDataType = Array.isArray(data.rows);
    if (testDataType) {
        let tableData = getAllRows.call(this, tableId, false);
        data.rows.map(item => {
            let { rowid, values, status: rowStatus } = item;
            if (isObject(values)) {
                // 此处同时修改了数据状态为 ‘1’
                let status =
                    typeof rowStatus == 'undefined' || rowStatus == ROW_STATUS.origin ? ROW_STATUS.edit : rowStatus;
                tableData.map(i => {
                    if (i.rowid == rowid) {
                        i.status = status;
                        Object.keys(values).forEach(key => {
                            i.values[key] = values[key];
                        });
                        // 优化存旧值
                        const { values: vals, rowid } = i;
                        for (let keys in vals) {
                            const OldVal = vals[keys] ? vals[keys].value : null;
                            saveChangedRowsOldValue.call(this, tableId, rowid, keys, OldVal);
                        }
                    }
                });
            }
        });
        // 更新
        store.refresh();
    }
}

// bbq
export function updateTable(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    store.setTableProps('focusIndex', -1, false);
    let data = store.getData();
    store.saveRowsOldValue({ rows: data });

    store.refresh();
}

// bbq
export function setEditableKeyEdit(tableId, data) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    let rows = store.getData();
    if (!rows.length || !data.length) {
        return console.log('empty data');
    }
    let rowKey = store.getTableProps('rowKey');

    rows.forEach((item, rowsIndex) => {
        data.forEach(eve => {
            const { index, keys } = eve;
            // 改为 rowKey 来判断
            if (eve[rowKey] === item[rowKey]) {
                const { values } = item;
                Object.keys(keys).forEach(key => {
                    let _isObj = isObject(values[key]);
                    if (!_isObj) {
                        values[key] = {};
                    }
                    const disabledValue = !keys[key] ? 'on' : 'off'; //获得编辑状态
                    values[key].disabled = disabledValue;
                });
            }
        });
    });

    store.setData({ data: rows, shouldForceUpdate: !this.isUpdatePage });
}

/**32
 * bbq
 * 共享  郭扬让加的，他们现在再用
 * 刷新表格高度  这是特殊场景使用，一般是表格上方部分，高度变化，表格需要不断适应时使用
 */
export function updateTableHeight() {
    PubSub.publish(OTHERCOMPLETE, true);
}

// bbq
export function updateDataByRowId(tableId, data, isCache = false, isDel = true) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return [];
    }

    store.updateTableDataByRowId(data, isCache, !this.isUpdatePage, isDel);
}

/*-------------------高阶组件--------------------*/

// bbq
export function getCheckedRows(tableId, isDeepCopy = true) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return [];
    }

    return store.getSelectedRows(isDeepCopy, 'filter');
}

// bbq
export function selectAllRows(tableId, checked) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return [];
    }

    let config = store.getConfig();
    let { crossPageSelect, pkname } = config;
    if (crossPageSelect && pkname) {
        if (checked) {
            let allpks = store.getStore('allpks')
            store.setStore(['checkInfo', 'crossPageSelectIndex'], new Set(allpks), false)
            store.setStore(['checkInfo', 'checkedAllpage'], true, false)
        } else {
            store.setStore(['checkInfo', 'crossPageSelectIndex'], new Set(), false)
            store.setStore(['checkInfo', 'checkedAllpage'], false, false)
        }
    }
    return store.setAllRowsSelected(checked, !this.isUpdatePage, () => {
        let newLen = store.getSelectedRows(false, 'filter').length;
        isFunction(store.selectedChange) && store.selectedChange({ ...this.props, ...this.output }, tableId, newLen);
    });
}

// bbq
export function reverseSelected(tableId) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return [];
    }

    store.reverseRowsSelected(!this.isUpdatePage)

    let newLen = store.getSelectedRows(false, 'filter').length;
    isFunction(store.selectedChange) && store.selectedChange({ ...this.props, ...this.output }, tableId, newLen);
}

// TODO  弹窗
export function openModel(tableId, type, record = {}, index, autoFocus = true) {

    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    let sideBoxConfig = store.getStore('sideBoxConfig') || {};
    sideBoxConfig = { ...sideBoxConfig, show: true };

    //弹出之后，禁止后面的滚动事件
    let html = document.getElementsByTagName('html');
    html[0].style.overflow = 'hidden';

    if (type == 'edit') {
        index = store.getTrueRowIndex({ rowIndex: index }, 'filter', 'normal');
        sideBoxConfig.record = record;
        sideBoxConfig.index = index;
        sideBoxConfig.operateType = type;
        sideBoxConfig.status = 'open';
        let data = store.getViewData();
        store.setCache(['beforeEdit'], JSON.parse(JSON.stringify({ rows: data })));
    } else if (type == 'add') {
        sideBoxConfig.operateType = type;
        sideBoxConfig.status = 'open';
        let addIndex = store.getViewData().length;
        let record = store.addTableRow(addIndex, {}, false, { autoFocus });
        sideBoxConfig.record = record;
        sideBoxConfig.index = addIndex;
        // 自动聚焦
        // if (autoFocus) {
        //     let items = store.getArrayMeta(tableId);
        //     for (let i = 0; i < items.length; i++) {
        //         if (
        //             items[i].attrcode != 'numberindex' &&
        //             (typeof items[i].disabled == 'undefined' || items[i].disabled == false) &&
        //             (typeof items[i].visible != 'undefined' && items[i].visible == true)
        //         ) {
        //             rows[addIndex].values[items[i].attrcode].isEdit = true;
        //             break;
        //         }
        //     }
        // }
    }

    store.setStore('sideBoxConfig', sideBoxConfig);
}

// TODO 弹窗
export function closeModel(tableId) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    let sideBoxConfig = store.getStore('sideBoxConfig') || {};
    sideBoxConfig = { ...sideBoxConfig, show: false, status: 'close' };
    store.setStore('sideBoxConfig', sideBoxConfig);
    //弹出之后，禁止后面的滚动事件
    let html = document.getElementsByTagName('html');
    html[0].style.overflow = '';

    return false;
}

//  批改
export function batchChangeTableData(tableId) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return null;
    }

    return store.batchChangeTableData();
}

// bbq
export function setCheckboxDisabled(tableId, index, flag) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    // let rows = getAllRows.call(this, tableId, false);
    // 不包含假删除的数据
    let visibleRows = store.getViewData('filter');

    if (Array.isArray(index)) {
        let isFlag = true;
        index.forEach(eve => {
            if (visibleRows.length - 1 >= eve && eve >= 0) {
                // rows[eve].disabled = !flag;
                store.setRowProps({ rowIndex: eve, view: 'filter' }, { disabled: !flag }, false);
            } else {
                isFlag = false;
                warningOnce(false, '所传入的数组中，数值不符合要求');
            }
        });
        if (isFlag) {
            if ([...new Set(index)].length === visibleRows.length) {
                let checkInfo = store.getCheckInfo();
                checkInfo.disabledAll = !flag;
                store.setCheckInfo({ checkInfo, shouldForceUpdate: false });
            }
            store.refresh();
            return true;
        } else {
            return false;
        }
    } else if (visibleRows.length - 1 >= index && index >= 0) {
        // rows[index].disabled = !flag;
        store.setRowProps({ rowIndex: index, view: 'filter' }, { disabled: !flag }, true);
        return true;
    }
}

// bbq
export function setAllCheckboxAble(tableId, flag) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    // add by bbqin 禁用所有checkbox时  先判断是否是聚焦的 聚焦就将焦点 移动一下，火狐复制粘贴快捷键焦丢失的问题
    let activeEl = document && document.activeElement;
    let container = document.getElementById(`hot-key-${tableId}`);
    if (!flag && container && container.contains(activeEl) && activeEl.type === 'checkbox') {
        document.body.focus();
    }

    let checkInfo = store.getCheckInfo();
    checkInfo.disabledAll = !flag;
    store.setCheckInfo({ checkInfo, shouldForceUpdate: false });

    let data = store.getData();
    let rowKey = store.getTableProps('rowKey');

    data.forEach(item => {
        store.setRowProps({ rowKeyValue: item[rowKey] }, { disabled: !flag }, false);
    });

    //  更新
    store.refresh();
}

// TODO
export function selectTableRows(tableId, index, flag) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    store.setRowsSelected({ rowIndexs: index, view: 'filter' }, flag);
}

// TODO  批改
export function getTableItemData(tableId) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    // {
    //     batchChangeIndex,
    //     batchChangeKey,
    //     batchChangeValue,
    //     batchChangeDisplay,
    // }

    let oldBatchData = store.getCache('oldBatchData');
    if (oldBatchData && (oldBatchData.batchChangeIndex || oldBatchData.batchChangeIndex === 0)) {
        oldBatchData.batchChangeIndex = store.getTrueRowIndex({ rowIndex: oldBatchData.batchChangeIndex }, 'normal', 'filter');
    }

    return oldBatchData;
}

// bbq
export function getTablePageInfo(tableId) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }

    let prePageSize = getPageSizeStorage(this.state.meta, tableId);

    let { pageIndex = 0, pageSize = prePageSize } = store.getPageInfo();

    return {
        pageIndex: pageIndex > 0 ? pageIndex - 1 : 0,
        pageSize,
    };
}

// TODO 不明用途的api
// 这个要找刘铭 确认下如何处理
export function setCheckboxFix(tableId, flag) {
    let store = existenceCheck.call(this, tableId);

    if (!store) {
        return;
    }
    // myEditTable.checkboxFix = flag;

    store.refresh();
}

// TODO 排序
export function getSortParam(tableId) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    return store.getStore(['sortParam']);
}

// TODO  云原生
export function updateDataByRefresh(tableId, pkname, refreshData, saga_errormesg) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    if (Array.isArray(refreshData)) {
        let data = getAllRows.call(this, tableId, false);
        let refreshLen = refreshData.length;
        data.map((item, index) => {
            let values = item.values;
            for (let i = 0; i < refreshLen; i++) {
                //找到相应行,更新对应字段的value
                if (values[pkname] && values[pkname].value === refreshData[i][pkname]) {
                    for (let pop in refreshData[i]) {
                        if (values[pop]) {
                            values[pop].value = refreshData[i][pop];
                        } else {
                            values[pop] = { value: refreshData[i][pop] };
                        }

                        //有错误信息时，将错误信息数据放表格行
                        if (pop === 'saga_status' && refreshData[i][pop] === '1' && saga_errormesg) {
                            values.saga_errormesg = saga_errormesg;
                        }
                    }
                    break;
                }
            }
            return item;
        });
        store.setData({ data });
    }
}

// TODO 过滤
export function saveFilterCustomColData(tableId, { attrcode, rowId, value }) {
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }

    store.setCache(['customColData', attrcode, rowId], value);

    // const tableScope = this.editTableDataSource[tableId];
    // if (!tableScope) {
    //     warningOnce(false, '当前操作表格实例不存在');
    // }
    // let { filterCustomColData } = tableScope;
    // if (filterCustomColData) {
    //     if (filterCustomColData[attrcode]) {
    //         if (filterCustomColData[attrcode][rowId] !== value) {
    //             // 相同的值不重复赋值
    //             filterCustomColData[attrcode][rowId] = value;
    //         }
    //     } else {
    //         filterCustomColData[attrcode] = {};
    //         filterCustomColData[attrcode][rowId] = value;
    //     }
    // } else {
    //     tableScope['filterCustomColData'] = {};
    //     tableScope['filterCustomColData'][attrcode] = {};
    //     tableScope['filterCustomColData'][attrcode][rowId] = value;
    // }
}

// liuming excel 新方法
export function updateAfterBatchChange(param) {
    let { areaCode } = param
    let store = existenceCheck.call(this, areaCode);
    if (!store) { return; }
    return store.updateAfterBatchChange(this, param)
}
//liuming excel 内部使用，不让业务组用
export function updateAndAddRows(param) {
    let { tableId } = param;
    let store = existenceCheck.call(this, tableId);
    if (!store) {
        return;
    }
    return store._updateAndAddRows(param);
}



/**
   统一给表体和侧拉添加参照顾虑
 * @author renyjk
 * @param {string} tableId
 * @param {object} data
 * data 就是要添加参照过滤的字段和对应参照过滤的数据格式 { name: QueryCondition, age: QueryCondition }
 */
export function setQueryCondition(tableId, data, shouldForceUpdate = true) {
    const keys = Object.keys(data); //所要设置参照过滤的字段数组
    let store = existenceCheck.call(this, tableId);
    if (!store) return;
    let items = store.getArrayMeta();
    items.forEach(item => {
        keys.forEach(key => {
            if (key === item.attrcode) {
                // item.queryCondition = data[key];
                store.setColumn(tableId, { [key]: { queryCondition: data[key] } }, false);
            }
        });
    });
    shouldForceUpdate && !this.isUpdatePage && store.refresh();;
}