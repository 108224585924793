
import EditTable from '@platform/edit-table';
import * as editTableAPI from './compatible';

//const useEditTable = EditTable.useEditTable;

// TODO
export default function editTableCompatible() {
    this.register('editTable'); // 生成 this.editTableDataSource[id]
    return {
        //store: useEditTable,
        ...apiBindPage.call(this, editTableAPI),
    };
}

function apiBindPage(editTableAPI) {
    let res = {};
    for (let key in editTableAPI) {
        res[key] = editTableAPI[key].bind(this);
    }
    return res;
}

editTableCompatible.component = EditTable;
editTableCompatible.displayName = 'EditTable';
editTableCompatible.unfinished = ['editTableUnFinishedApi'];