// 使用方法回调的方式进行扩展
import { ViewModel, pageTo, getAppUrl } from '@platform/api'
import * as feConfig from './const';

// 这个东西呢  有三种做法  i.变量（get set）  ii.静态变量（static）  iii.全局变量
const ExtendModuleName = 'NCCExtend';

const srcriptAlreadyExist = src => {
    let allScript = document.getElementsByTagName('script');
    let srcriptUrl = Array.from(allScript).map(element => {
        return element.getAttribute('src');
    });
    let index = srcriptUrl.findIndex(val => val === src);
    return index !== -1;
};
//liuming CONFIG引用修改
const isPageModal = true
/**
 * 查找resources加载的文件路径里某一层级的文件名
 * n:Number 在resources的第几层
 */
function getUrlPathName(n) {
    // hash 示例
    // "ifr=/nccloud/resources/price/price/adjustprice/main/index.html#/list&c=403000800&p=403000800_list&ar=0001Z81000000003QAX4&n=价格调整单&b1=供应链&b2=销售价格&b3=销售调价"
    let hash = '';
    if (isPageModal) {
        let pageStore = getAppUrl().pageurl;
        hash = decodeURIComponent(decodeURIComponent(pageStore));
    } else {
        hash = decodeURIComponent(decodeURIComponent(window.top.location.hash));
    }
    let matchStr = hash.match(/ifr=\/nccloud\/resources\/(\S*).html/) || hash.match(/ifr=\/(\S*).html/);
    let urlPathArr = matchStr && matchStr[1] && matchStr[1].split('/') || ['none+', 'none+'];
    return urlPathArr[n];
}

/**
 * 获取扩展路径
 */
function getExtendUrl(billinfo = {}) {
    console.log(billinfo, '页面基础信息');
    let domainCode = getUrlPathName(0),
        moduleCode = getUrlPathName(1),
        appcode = billinfo.appcode || pageTo.getAppCode(),
        pagecode = billinfo.pagecode || pageTo.getPageCode();
    // console.log(domainCode, moduleCode, appcode, pagecode);
    return domainCode && moduleCode && appcode && pagecode ? `extend_${domainCode}/${moduleCode}/${appcode}/${pagecode}` : null;
}

class MethodsModel {
    constructor() {
        // TODO  暂不处理
    }

    // 加载文件 mutativeLoadScrip
    install(callback, { billinfo }) {
        if(pageTo.getSearchParam('sec_develop')!=='Y'){
            return;
        }
        if (!feConfig.enable) {
            console.log('本页面禁止加载二开文件~');
            return false;
        }
        // 缓存数据
        this.billinfo = billinfo;
        //加载二开的文件--liuxis
        let extendUrl = getExtendUrl(billinfo);
        if (extendUrl) {
            let src = `../../../../${ExtendModuleName}/${extendUrl}/index.js`;
            let extendPath = `${ExtendModuleName}/${extendUrl}/index`;
            console.log(src);
            let NCCExtendAlreadyExist = srcriptAlreadyExist(src);
            if (!NCCExtendAlreadyExist) { //如果一个页面使用多个createPage，只加载一次二开文件
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = src;
                /**readyStaprefer/refer.te:
                 *  uninitialized: 默认状态
                    loading: 开始加载
                    loaded: 加载完成
                    interactive: 加载完成但尚不可用
                    complete: 所有数据已经准备好
                 */
                if (script.readyState) {
                    script.addEventListener('readstatechange', readStateChange);
                } else {
                    script.addEventListener('load', saveMethods);
                }
                script.addEventListener('error', onError);
                function readStateChange() {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        script.removeEventListener('readstatechange', readStateChange);
                    }
                }
                function onError() {
                    // document.body.removeChild(script);
                    console.log('file load error NCCExtend ' + src);
                }
                function saveMethods() {
                    let allMethods = null;
                    // 二开注册对象 export default 输出
                    if (window[extendPath] instanceof Object && window[extendPath].default instanceof Object) {
                        allMethods = window[extendPath].default;
                    } else if (window[ExtendModuleName] instanceof Object) { //二开注册对象挂在 window.NCCExtend
                        allMethods = window[ExtendModuleName];
                    }
                    // if (window[ExtendModuleName] instanceof Object) {
                    if (allMethods) {
                        // 提供两个方法给  模板加载和数据加载使用
                        if (allMethods.dataAlreadyLoaded && typeof allMethods.dataAlreadyLoaded === 'function') {
                            ViewModel.dataAlreadyLoaded = allMethods.dataAlreadyLoaded;
                        }
                        if (allMethods.templateAlreadyLoaded && typeof allMethods.templateAlreadyLoaded === 'function') {
                            ViewModel.templateAlreadyLoaded = allMethods.templateAlreadyLoaded;
                        }
                        if (allMethods.beforeAjaxSend && typeof allMethods.beforeAjaxSend === 'function') {
                            ViewModel.beforeAjaxSend = allMethods.beforeAjaxSend;
                        }

                        if (allMethods.localExtendRouter && typeof allMethods.localExtendRouter === 'function') {
                            ViewModel.localExtendRouter = allMethods.localExtendRouter;
                        }
                        callback(allMethods);
                    }
                }
                try {
                    document.body && document.body.appendChild(script);
                    // script = null;
                } catch (e) {
                    console.log('script load error , not found src');
                }
            }
        }

    }

    // 移除文件
    removeScript(src) {
        let $script = document.querySelector(`[src="${src}"]`);
        $script && document.body.removeChild($script);
    }

    // 卸载文件
    unstall() {
        // TODO 卸载二开文件
        //销毁二开绑定在window上的事件
        let extendUrl = getExtendUrl(this.billinfo);
        if (extendUrl) {
            // let name = `NCCExtend${appcode}_${pagecode}`;
            if (window[ExtendModuleName]) window[ExtendModuleName] = null;
            this.removeScript(`../../../../${ExtendModuleName}/${extendUrl}/index.js`);
        }
    }
}

export default MethodsModel;