import React, { Component } from 'react';

import formCompatible from './Form/output';
import cardTableCompatible from './Table/appTable/CardTable/output';
import simpleTableCompatible from './Table/appTable/SimpleTable/output';
import editTableCompatible from './Table/appTable/EditTable/output';
import TransferTable from '@platform/transfer-table';
import treeTableCompatible from '@platform/tree-table';
// import searchCompatible from './Search/output';

import {
    pageTo,
    isObj,
    isUndefined,
    isFunction,
    isIE,
    ViewModel,
    delEmptyData,
    getMetaKey,
    gzip as Gzip,
    ajax,
    getMultiLang,
    findFocusModuleId,
    executeAutoFocus,
    controlAutoFocus,
    getFocusRowIndex,
    getAuthorityOfNotification,
    noticeToUser,
    setGlobalStorage,
    getGlobalStorage,
    getAppReqParam,
    formDownload,
    PubSub,
    sum,
} from '@platform/api';

import fastExtend from '../FastExtend';
import { getCheckedExtendMethod, feConfigName, getCheckedMethod } from '../FastExtend/utils';

import {
    Socket,
    CrossServiceBtns,
    Button,
    ButtonApp,
    CardPagination,
    BillHeadInfo,
    InsertTable,
    AsyncTree,
    SyncTree,
    CreateModal,
    CreateNCModal,
    DragWidthCom,
    CreateSearch,
    mutiInit,
    promptBox,
} from '@platform/components';

import Qs from 'qs';

function outputWrapper(name, EXPORTS) {
    let output = {};
    !Array.isArray(EXPORTS) && (EXPORTS = [EXPORTS]);
    EXPORTS.forEach(EXPORT => {
        if (EXPORT) {
            Object.keys(EXPORT).forEach(key => {
                output[key] = EXPORT[key].bind(this);
            })
        } else {
            console.warn(`${name}组件没有加载`)
        }
    })
    return output;
}

export default ({
    initTemplate,
    mutiLangCode,
    billinfo, //界面同时存在一个billtype类型object;或者多个billtype  array
    domainName,
    hashChangeCallBack,
    relationAfterEventCallBack,
    orderOfHotKey,
    appAutoFocus,
    historyBack,
} = {}) => App => {
    class Page extends Component {
        use = { search() { } }

        componentNames = [];

        register = (name, store) => {
            this.componentNames.push(name);
            this.use[name] = this.use[name] || ((...ids) => {
                ids.forEach(id => {
                    this[name + 'DataSource'] = this[name + 'DataSource'] || {};
                    this[name + 'DataSource'][id] = this[name + 'DataSource'][id] || (store || this[name]._store)();
                })
            })
        }

        // 数据
        formDataSource = {};
        simpleTableDataSource = {};
        editTableDataSource = {};
        cardTableDataSource = {};
        searchDataSource = {};
        treeTableDataSource = {};
        metaData = {}

        constructor(props) {
            super(props);
            this.myState = {
                oprationIndex: -1, // 存注册后的按钮数据
                buttonsComponent: {},
                buttonsLangJson: {
                    lang: null,
                    intl: null,
                },
                stopOprationBtnsRenderAreas: {}, // 存放禁止操作列render的区域
                oprationButtons: {},
                appButtons: {},
                buttons: [], // 存注册后的按钮数据
                btnInfo: {}, // 存放按钮的显示隐藏，禁用状态，主要次要，弹窗提示等信息
                transferTable: {
                    totalTemplateKeys: {
                        columns: [],
                        bodyColumn: [],
                        fullColumns: [],
                    },
                },
                status: {
                    totalStatusOfTransferTable: false,
                    headTotalStatusOfTransferTable: false,
                    fullTotalStatusOfTransferTable: false,
                    bodyTotalStatusOfTransferTable: false,
                    traversalStatus: false, // 遍历状态
                },
                intl: {}, // 放置多语
                jsonIntl: {}, // 放置多语
                transferList: {}, // 下游转单
                search: { keySessionS: [] },
            };
            //存储表单组件this
            this.myForm = {};

            //云原生--出错时渲染按钮区
            this.myCrossServiceBtn = {};
            //云原生--暂时按钮状态值
            this.myCrossServiceState = {};
            //云原生--错误单据交互，存单据整体信息
            this.nativeSocketBillInfo = {};
            this.syncTreeDataSource = {};
            this.asyncTreeDataSource = {};

            //全局的pagecode参数
            this.globalPageCode = '';
            if (billinfo && billinfo.pagecode) {
                this.globalPageCode = billinfo.pagecode;
            }

            // 业务组在进行页面跳转时候传递的hash参数
            this.hashChangeCallBack = hashChangeCallBack;

            //注册平台编辑后回调
            this.relationAfterEventCallBack = relationAfterEventCallBack;

            //存储翻页组件this
            this.myCardPagination;

            this.myTable = { myEditTable: {}, myMulTable: {} }; //用来存储表格中，tableId 对应的 表格组件 
            this.myTableData = { myEditData: {}, myMulData: {} }; //用来存储表格当没有获取ref时的表格数据的

            // 多组件间的信息交流控制器
            this.infoExchangeController = {
                // 这个方法无用了  嗯 别问我为什么不删  大概是回忆吧
                // 里面存放一些组件之间的通信信息  这个目前用于  查询区组件和表格之类的组件进行沟通
                onHeightChange: area => {
                    if (!area) {
                        // area 包括 'search' 'page' table 等
                        return false;
                    }
                },
                // 把 PubSub 挂进来更好
                ...PubSub,
            };

            /**
                 *
                 * @type {{
                 *  orderOfHotKey: type Array, 用于排列tabChange的顺序
                 *  withHotKeyChange: type Boolean 是否使用hotKey的开关
                 *  }}
                 */
            this.orderOfHotKey = orderOfHotKey || [];
            this.withHotKeyChange = this.orderOfHotKey.length > 0;
            // add by bbqin 当前页面是否自动聚焦
            this.appAutoFocus = appAutoFocus !== false;

            //业务组控制执行自动聚焦的时机 false -> 平台控制， true -> 业务组控制
            this.autoFocusDeferred = false;

            this.state = {
                status,
                meta: {},
                form: {},
                cardPagination: {},
                formBack: {},
                formGroupData: {},
                showFormIcon: {},
                groupsId: [],
                button: {}, // 存createButton 创建的按钮
                buttons: [], // 存注册后的按钮数据
                btnInfo: {}, // 存放按钮的显示隐藏，禁用状态，主要次要，弹窗提示等信息
                table: {},
                anchorNav: {},
                dragDown: {},
                insertTable: {}, //嵌套类型表格
                transferTable: {}, //转单表格
                transferList: {}, //转单列表

                renderItem: {},
                treeData: {},
                modal: {},
                tableModeldata: {},
                treeTableCol: {},
                referItem: {},
                intl: {},

                formItemWidth: {},
                autoFocusFormItem: {},
                // uploader: {}, // 上传组件
                tabOrder: [],
                tab: '',
                tabData: {},
            };

            this.billinfo = billinfo;
            this.currentBill = {};
            this.context = {};
            this.formItemAttrFlag = {};

            //以下是提供给业务组的方法
            this.meta = {
                getMeta: () => {
                    // 兼容一下数据被引用导致的错误  by bbqin
                    let meta = this.state.meta,
                        res = {};
                    for (let key of Object.keys(meta)) {
                        // res[key] = JSON.parse(JSON.stringify(meta[key]));
                        res[key] = meta[key];
                        if (meta[key] && meta[key].items) {
                            if (meta[key].moduletype === 'table') {
                                res[key].items = meta[key].items.get ? meta[key].items.get() : meta[key].items; // getArrayData
                            }
                            // 表单处理 因为区分主子表 所以 逻辑有问题 暂时先不处理
                            if (meta[key].moduletype === 'form') {
                                let DataSource = this['formDataSource'] || {},
                                    currentSource = {};
                                // 当前store是否存在 是否为主表ID 
                                if (DataSource[key]) {
                                    currentSource = DataSource[key]
                                } else {
                                    // 不存在时 判断是否为子表ID, 找到子表ID对应的主表ID;
                                    let formrelation = (meta && meta.formrelation) || {};
                                    Object.keys(formrelation).forEach(item => {
                                        if (Array.isArray(formrelation[item]) && formrelation[item].includes(key) && DataSource[item]) {
                                            currentSource = DataSource[item]
                                        } else {
                                            // 暂时先移除 页签字段 todo
                                            // 多页签数据处理 假如不是子表时  需进行判断是否为多页签子表 
                                            let formassociatedrelation = (meta && meta.formassociatedrelation) || {};
                                            Object.keys(formassociatedrelation).forEach(tabsID => {
                                                if (Array.isArray(formassociatedrelation[tabsID].formtabs) && formassociatedrelation[tabsID].formtabs.includes(item)) {
                                                    currentSource = DataSource[tabsID]
                                                }
                                            })
                                        }
                                    })
                                }
                                // 初始化之后才进行 根据store 取值 为初始化  还是根据meta 自身状态
                                if (currentSource && typeof currentSource.getMetaItems == 'function') {
                                    let currentItems = currentSource.getMetaItems(key) || [];
                                    if (currentItems.length !== 0) {
                                        // 能取到items 再把items复值给items 取不到就使用原有meta数据
                                        res[key].items = currentItems
                                    }
                                } else {
                                    res[key].items = meta[key].items;
                                }

                            }
                        }
                    }
                    return res;
                },
                setMeta: (meta, callback) => {

                    // 二开回调
                    getCheckedExtendMethod(this, 'beforeMetaUpdate')(meta, this.output);

                    Object.keys(meta).forEach(id => {
                        if (meta[id] && meta[id].moduletype) {
                            this.componentNames.find(name => {
                                let dataSources = this[name + 'DataSource'];
                                if (dataSources && dataSources[id]) {
                                    // 使用use方法注册过的话，会有dataSource[id]
                                    // TODO:meta的格式
                                    dataSources[id].setMeta(meta, id, false, this.app_context);
                                    return true;
                                }
                            })
                        }
                    });
                    this.setState({ meta }, () => {
                        callback && callback();
                        // 二开回调
                        getCheckedExtendMethod(this, 'afterMetaUpdate')(meta, this.output);
                    });
                },
                addMeta: (meta, callback) => {
                    // 如果是属性 gridrelation / formrelation 要合并而不是覆盖
                    if (meta.gridrelation && this.state.meta.gridrelation) {
                        for (let pop in this.state.meta.gridrelation) {
                            if (!meta.gridrelation[pop]) {
                                meta.gridrelation[pop] = this.state.meta.gridrelation[pop];
                            }
                        }
                    }
                    if (meta.formrelation && this.state.meta.formrelation) {
                        for (let pop in this.state.meta.formrelation) {
                            if (!meta.formrelation[pop]) {
                                meta.formrelation[pop] = this.state.meta.formrelation[pop];
                            }
                        }
                    }
                    if (meta.containerrelation && this.state.meta.containerrelation) {
                        for (let pop in this.state.meta.containerrelation) {
                            if (!meta.containerrelation[pop]) {
                                meta.containerrelation[pop] = this.state.meta.containerrelation[pop];
                            }
                        }
                    }
                    // 表单多页签关联关系  要合并而不是覆盖
                    if (meta.formassociatedrelation && this.state.meta.formassociatedrelation) {
                        for (let pop in this.state.meta.formassociatedrelation) {
                            if (!meta.formassociatedrelation[pop]) {
                                meta.formassociatedrelation[pop] = this.state.meta.formassociatedrelation[pop];
                            }
                        }
                    }
                    getCheckedExtendMethod(this, 'beforeMetaUpdate')(meta, this.output);
                    this.meta.setMeta(Object.assign(this.state.meta, meta), () => {
                        callback && callback();
                        // 二开回调
                        getCheckedExtendMethod(this, 'afterMetaUpdate')(this.state.meta, this.output);
                    })
                },
                removeMeta: meta => {
                    if (this.state.meta.hasOwnProperty(meta)) {
                        delete this.state.meta[meta];
                        this.setState({ meta: this.state.meta });
                    }
                },
                // 上面这个预留 多表头将来会写到meta中使用
                multipleMeta: data => {
                    let gridMultiple = data.gridMultiple;
                    if (gridMultiple) {
                        let tempArr = Object.keys(gridMultiple);
                        if (tempArr.length) {
                            tempArr.forEach(item => {
                                // item为每个键id  如 revecont_b
                                let mulArrItem = data.gridMultiple[item];
                                mulArrItem.map(one => {
                                    let d = {
                                        label: one.name,
                                        attrcode: one.code,
                                        visible: true,
                                        children: [],
                                    };
                                    let flag = {};
                                    one.children.map(everyOne => {
                                        // everyOne 为数组的每个元素
                                        data[item].items.map((meta, index) => {
                                            if (meta.attrcode == everyOne) {
                                                d.children.push(meta);
                                                if (!flag[item]) {
                                                    data[item].items.splice(index, 1, d);
                                                } else {
                                                    data[item].items.splice(index, 1);
                                                }
                                                flag[item] = true;
                                            }
                                        });
                                    });
                                });
                            });
                        }
                    }
                    return data;
                },
                // 多表头结构处理
                handleMultiple: (data, relation) => {
                    if (relation) {
                        let tempArr = Object.keys(relation);
                        if (tempArr.length) {
                            tempArr.forEach(item => {
                                // item为每个键id  如 revecont_b
                                let mulArrItem = relation[item];
                                mulArrItem.map(one => {
                                    this.meta.handleChildMultiple(data, item, one);
                                });
                            });
                        }
                    }
                    return data;
                },
                // 多表头递归处理多级表头 zhanghengh
                handleChildMultiple: (data, item, one, first = true) => {
                    const visible = isUndefined(one.visible) ? true : !!one.visible;
                    let d = {
                        label: one.name,
                        attrcode: one.code,
                        visible,
                        children: [],
                    };
                    let flag = {};
                    one.children.map(everyOne => {
                        data[item].items.map((meta, index) => {
                            if (meta.attrcode == everyOne) {
                                d.children.push(meta);
                                if (first && !flag[item]) {
                                    data[item].items.splice(index, 1, d);
                                } else {
                                    data[item].items.splice(index, 1);
                                }
                                flag[item] = true;
                            } else if (isObj(everyOne) && everyOne.hasOwnProperty('children')) {
                                let multipleItem = this.meta.handleChildMultiple(data, item, everyOne, false);
                                if (multipleItem.children.length > 0) {
                                    d.children.push(multipleItem);
                                }
                            }
                        });
                    });
                    return d;
                },
                // 以下是wangtaos为司库云-C添加，误删
                renderTabs: (meta, tabOrder, showTab, callback) => {
                    showTab = showTab && Array.isArray(showTab) ? showTab : tabOrder;
                    meta.gridrelation[(tabOrder && tabOrder[0]) || 'tab'].tabRelation = [...showTab];
                    meta.gridrelation['extra_&&&'] = { ...meta.gridrelation[(tabOrder && tabOrder[0]) || 'tab'] };
                    meta['extra_&&&'] = { items: [] };
                    this.setState(
                        {
                            meta,
                            tabOrder,
                            tab: showTab[0],
                        },
                        () => {
                            callback && callback();
                        },
                    );
                },
            };
            this.primordialTable = {}; //用来存储通过ref获取到的原生table
            this.batchData = {}; //用来存储批量修改的每个表格的数据
            this.editTableInitValue = {};
            this.tableChangedRowsOldValue = {}; // 以id划分对象
            this.formOldValues = {};
            this.formItemAttrFlag = {};
            this.formStatusFlag = {};
            this.formSetFormValues = {};
            this.formsetFormVisible = {};
            this.setFormVisibleByForm = {};

            this.copyMetaBeforePkorg = {};

            // 方法
            this.form = getCompatibleApi.call(this, formCompatible);
            this.table = getCompatibleApi.call(this, simpleTableCompatible)
            this.editTable = getCompatibleApi.call(this, editTableCompatible)
            this.cardTable = getCompatibleApi.call(this, cardTableCompatible)
            // this.search = getCompatibleApi.call(this, searchCompatible)

            //	树状表组件
            // this.treeTableManyCol = outputWrapper.call(this, 'treeTableManyCol', treeTableCompatible);
            this.treeTableManyCol = getCompatibleApi.call(this, treeTableCompatible);

            this.search = outputWrapper.call(this, 'search', CreateSearch)

            this.cardPagination = outputWrapper.call(this, 'cardPagination', CardPagination)

            this.BillHeadInfo = outputWrapper.call(this, 'BillHeadInfo', BillHeadInfo);

            this.socket = outputWrapper.call(this, 'socket', Socket);

            this.button = outputWrapper.call(this, 'button', [CrossServiceBtns, Button, ButtonApp])

            // 上传下载
            // this.Uploader = Uploader.bind(this);

            // 可拖拽宽度的dom盒子组件
            this.DragWidthCom = DragWidthCom;

            //简单搜索
            //内置新增，refer及其他条件查询

            //嵌套类型表格
            this.insertTable = outputWrapper.call(this, 'insertTable', InsertTable);

            //拉单-已选列表
            this.transferTable = outputWrapper.call(this, 'transferTable', TransferTable);

            //创建异步树组件
            this.asyncTree = outputWrapper.call(this, 'asyncTree', AsyncTree)

            //创建同步树组件
            this.syncTree = outputWrapper.call(this, 'syncTree', SyncTree);

            // 下载组件
            this.downLoad = ({ data, url }) => {
                formDownload({ params: data, url, enctype: 1 })
            };

            // 审批流程图组件
            // this.approveDetail = { create: approveDetail.bind(this) };

            //简单提示框组件
            this.modal = outputWrapper.call(this, 'modal', CreateModal);

            // 带四种提示符框组件
            this.NCmodal = outputWrapper.call(this, 'NCmodal', CreateNCModal)

            // 用自定义组件替换表单组件
            this.renderItem = (moduletype, moduleId, id, newItem) => {
                // 暂时先处理表单
                if (moduletype === 'form') {
                    let DataSource = this[moduletype + 'DataSource'] || {},
                        currentSource = {};
                    // 当前store是否存在 是否为主表ID 
                    if (DataSource[moduleId]) {
                        currentSource = DataSource[moduleId]
                    } else {
                        // 不存在时 判断是否为子表ID, 找到子表ID对应的主表ID;
                        let formrelation = this.state.meta && this.state.meta.formrelation;
                        formrelation && Object.keys(formrelation || {}).forEach(item => {
                            if (Array.isArray(formrelation[item]) && formrelation[item].includes(moduleId) && DataSource[item]) {
                                currentSource = DataSource[item]
                            }
                        })
                    }
                    // let dataSources = this[moduletype + 'DataSource'][moduleId] || { replaceItem: () => { } };
                    typeof currentSource.replaceItem == 'function' && currentSource.replaceItem(id, newItem)
                } else {
                    let { renderItem } = this.state;
                    this.state.renderItem[moduletype] = this.state.renderItem[moduletype] || {};
                    this.state.renderItem[moduletype][moduleId] = this.state.renderItem[moduletype][moduleId] || {};
                    this.state.renderItem[moduletype][moduleId][id] = newItem;
                    this.setState({ renderItem });
                }
            };

            //得到页面所有数据
            this.getPageDataByMeta = () => {
                let metaObj = this.meta.getMeta();
                let saveData = {};
                for (let id in metaObj) {
                    if (metaObj[id].moduletype && metaObj[id].moduletype === 'form') {
                        saveData[id] = this.form.getAllFormValue(id);
                    } else if (metaObj[id].moduletype && metaObj[id].moduletype === 'table') {
                        saveData[id] = this.editTable.getAllRows(id);
                    }
                }
                return saveData;
            };

            //创建主子表数据
            this.createMasterChildData = (pageid, head_code, body_code, tabletype, rows) => {
                let saveData = {
                    pageid,
                    head: {},
                    body: {},
                };
                let metaObj = this.meta.getMeta();
                //saveData.templetid = metaObj.pageid && metaObj.pageid;
                if (this.currentBill && this.currentBill.templetid) {
                    saveData.templetid = this.currentBill.templetid;
                } else {
                    saveData.templetid = metaObj.pageid && metaObj.pageid;
                }
                if (metaObj[head_code] && metaObj[head_code].moduletype && metaObj[head_code].moduletype === 'form') {
                    saveData.head[head_code] = this.form.getAllFormValue(head_code);
                    saveData.head[head_code].areacode = head_code;
                }
                if (metaObj[body_code] && metaObj[body_code].moduletype && metaObj[body_code].moduletype === 'table') {
                    if (rows) {
                        saveData.body[body_code] = {
                            areaType: 'table',
                            rows,
                            areacode: null,
                        };
                    } else {
                        if (tabletype && tabletype === 'editTable') {
                            saveData.body[body_code] = this.editTable.getAllData(body_code);
                        } else {
                            saveData.body[body_code] = this.cardTable.getAllData(body_code);
                        }
                    }
                    saveData.body[body_code].areacode = body_code;
                }
                return saveData;
            };

            //sikuyun创建主子表数据,wangtaos添加
            this.createTabsMasterChildData = (pageid, head_code, body_code) => {
                let saveData = {
                    pageid,
                    head: {},
                    body: {},
                };
                let metaObj = this.meta.getMeta();
                saveData.templetid = metaObj.pageid && metaObj.pageid;
                if (metaObj[head_code] && metaObj[head_code].moduletype && metaObj[head_code].moduletype === 'form') {
                    saveData.head[head_code] = this.form.getAllFormValue(head_code);
                    saveData.head[head_code].areacode = head_code;
                }
                if (metaObj[body_code] && metaObj[body_code].moduletype && metaObj[body_code].moduletype === 'table') {
                    saveData.body[body_code] = this.cardTable.getAllTabsData(body_code);

                    saveData.body[body_code].areacode = body_code;
                }
                return saveData;
            };

            //创建主子表简化数据
            this.createMasterChildDataSimple = (pageid, head_code, body_code, tabletype, isDeleteEmptyItem = false) => {
                let saveData = {
                    pageid,
                    head: {},
                    body: {},
                };
                let metaObj = this.meta.getMeta();
                //saveData.templetid = metaObj.pageid && metaObj.pageid;
                if (this.currentBill && this.currentBill.templetid) {
                    saveData.templetid = this.currentBill.templetid;
                } else {
                    saveData.templetid = metaObj.pageid && metaObj.pageid;
                }
                if (metaObj[head_code] && metaObj[head_code].moduletype && metaObj[head_code].moduletype === 'form') {
                    //saveData.head[head_code] = deleteEmptyData(this.form.getAllFormValue(head_code));
                    saveData.head[head_code] = this.form.getAllFormValueSimple(head_code);
                    saveData.head[head_code].areacode = head_code;
                }

                if (metaObj[body_code] && metaObj[body_code].moduletype && metaObj[body_code].moduletype === 'table') {
                    if (tabletype && tabletype === 'editTable') {
                        // inner_use 为平台内部使用方案，否则为资产使用方案(叶勋)
                        saveData.body[body_code] = delEmptyData(this.editTable.getAllData(body_code), 'inner_use', isDeleteEmptyItem);
                    } else {
                        saveData.body[body_code] = delEmptyData(this.cardTable.getAllData(body_code), 'inner_use', isDeleteEmptyItem);
                    }

                    saveData.body[body_code].areacode = body_code;
                }
                return saveData;
            };

            this.requestMeta = (data, appcode, pagecode, callback, ssc_templetid, isCache = true, urlAppcode) => {
                //更新pagecode，createUIDOM传过来的pagecode优先级高于billinfo
                this.globalPageCode = pagecode;

                let reqData = [
                    {
                        rqUrl: '/platform/templet/querypage.do',
                        rqJson: `{\n  \"pagecode\": \"${pagecode}\",\n  \"appcode\": \"${appcode}\"\n}`,
                        rqCode: 'template',
                    },
                    {
                        rqUrl: '/platform/appregister/queryallbtns.do',
                        rqJson: `{\n  \"pagecode\": \"${pagecode}\",\n  \"appcode\": \"${appcode}\"\n}`,
                        rqCode: 'button',
                    },
                    {
                        rqUrl: '/platform/appregister/queryappcontext.do',
                        rqJson: `{\n  \"appcode\": \"${urlAppcode || appcode}\"}`,
                        rqCode: 'context',
                    },
                ];
                if (ssc_templetid) {
                    reqData[0] = {
                        rqUrl: '/platform/templet/querypage.do',
                        rqJson: `{\n  \"pagecode\": \"${pagecode}\",\n  \"appcode\": \"${appcode}\",\n  \"templetid\": \"${ssc_templetid}\"\n}`,
                        rqCode: 'template',
                    };
                }
                if (data && data.reqDataQuerypage) {
                    reqData[0] = data.reqDataQuerypage;
                }

                if (data && data.reqDataQueryallbtns) {
                    reqData[1] = data.reqDataQueryallbtns;
                }

                if (data && data.reqDataQueryappcontext) {
                    reqData[2] = data.reqDataQueryappcontext;
                }

                //querypage: compareTs: true时,模板改变时，会返回模板数据，否则返回 false
                let storeCipher = localStorage.getItem('storeCipher') != '0';
                //let keySessionS = 'appTempletStorage_' + appcode + '_' + pagecode;
                let keySessionS = getMetaKey(appcode, pagecode);
                let g = new Gzip();
                this.myState.search.keySessionS.push(keySessionS);
                let meta = {};

                //先判断有无缓存，有缓存，再查一下模板是不是被改过，改过更新缓存，没改什么也不做；若无缓存，不带走ts发请求查询
                if (isCache) {
                    //业务需要缓存
                    meta = getGlobalStorage('localStorage', keySessionS);
                    //先解密后 解压
                    if (storeCipher) {
                        //meta = meta && decrypt(meta, aeskey)
                        meta = meta && g.unzip(meta);
                    } else {
                        meta = meta && JSON.parse(meta);
                    }

                    if (meta) {
                        //有缓存
                        //查看是否需要更新缓存：
                        let ts = '';
                        if (meta.template && meta.template.ts) {
                            ts = meta.template.ts;
                        }
                        //先将meta给业务组使用，若
                        // //抛给业务组使用，待更新模板更新后，再调用一次业务处理meta方法
                        // // 二开回调
                        // getCheckedExtendMethod(this, 'templateAlreadyLoaded')(meta);
                        // if (meta.template && typeof callback == 'function') {
                        //     callback(meta);  //业务组回调中做了提示，会导致业务组的回调调用多次 提示可以            
                        // }
                        //-----------加载断层问题，暂时先更新一次meta; 待后台ts不同时 不再返回button和context再优化-----------//
                        //--------- 单页应用的切换过程中，直接使用模板缓存，不再请求模板，也不再对比ts
                        if (meta.template && window.record_spa_appcode && window.record_spa_appcode === appcode) {
                            let storeMeta = JSON.parse(JSON.stringify(meta));
                            //使用 addMeta 防止业务之前有setMeta
                            this.app_context = storeMeta.context;
                            //this.meta.addMeta(storeMeta.template);
                             // 二开回调
                             getCheckedExtendMethod(this, 'templateAlreadyLoaded')(storeMeta);
                             //抛给业务组使用
                             if (typeof callback == 'function') {
                                 setTimeout(()=>{
                                    callback(storeMeta);
                                 },0)    
                             }
                             return;
                            /**
                             * 直接setMeta 不调用业务回调会引起的问题：
                             * setMeta后，会调用createForm 表单会取一次status 
                             * 判断完ts后再执行 业务回调，业务代码修改了区域的status，不会再生效
                             * 解决方案：1. 表单获取meta status的时候 做判断，有该属性，且没有使用API setStatus时，再去meta的status
                             * 2.业务统一用法：所有组件改变状态都使用组件的API进行更新
                            */
                        }
                        if(!window.record_spa_appcode){
                            window.record_spa_appcode = appcode;
                        }
                       //-----------加载断层问题，暂时先更新一次meta; 待后台ts不同时 不再返回button和context再优化----------//

                        reqData[0].rqJson = `{\n  \"pagecode\": \"${pagecode}\",\n  \"appcode\": \"${appcode}\"\n, \n  \"compareTs\":true\n, \n  \"ts\":\"${ts}\"\n}`;
                        this.refreshMeta(g, meta, reqData, callback, storeCipher, keySessionS, appcode, pagecode, true);
                    } else {
                        //无缓存，不带ts发请求，查询模板数据
                        this.refreshMeta(g, {}, reqData, callback, storeCipher, keySessionS, appcode, pagecode, false);
                    }
                } else {
                    //业务不要缓存
                    this.refreshMeta(g, {}, reqData, callback, storeCipher, keySessionS, appcode, pagecode, false);
                }
            };

            this.refreshMeta = (gzip, cacheMeta, reqData, callback, storeCipher, keySessionS, appcode, pagecode, isExeCacheMeta) => {
                ajax({
                    url: '/nccloud/platform/pub/mergerequest.do',
                    data: reqData,
                    sysAppcode: appcode,
                    success: res => {
                        if (res.data) {
                            let jsonMeta = '';
                            this.app_context = res.data.context;
                            cacheMeta.context = res.data.context;

                            //更新按钮
                            cacheMeta.button = res.data.button;

                            if (res.data.template) {
                                let meta = res.data;

                                //将 appcode 和 pagecode 挂在区域上
                                let MetaTemp = meta.template;
                                for (let MetaItem of Object.keys(MetaTemp)) {
                                    if (MetaTemp[MetaItem].moduletype && MetaTemp[MetaItem].moduletype === 'table') {
                                        MetaTemp[MetaItem].appcode = appcode;
                                        MetaTemp[MetaItem].pagecode = pagecode;
                                        MetaTemp[MetaItem].templateCode = MetaTemp.code;
                                        MetaTemp[MetaItem].pageid = MetaTemp.pageid;
                                    }
                                }

                                jsonMeta = JSON.stringify(meta);

                                // 二开回调
                                getCheckedExtendMethod(this, 'templateAlreadyLoaded')(meta);

                                //抛给业务组使用
                                if (callback && typeof callback == 'function') {
                                    callback(meta);
                                }

                            } else {

                                //这里还是要执行业务数据，因为context和button也是每次都可能更新的
                                jsonMeta = JSON.stringify(cacheMeta);

                                // 二开回调
                                getCheckedExtendMethod(this, 'templateAlreadyLoaded')(cacheMeta);

                                if (callback && typeof callback == 'function') {
                                    callback(cacheMeta);
                                }
                            }

                            //-----------------加密缓存，并对缓存数据更新-----------------//
                            //先压缩，后加密
                            let newMeta = jsonMeta;
                            if (storeCipher) {
                                let gmeta = jsonMeta && gzip.zip(jsonMeta);
                                // newMeta = gmeta && encrypt(gmeta, aeskey);
                                newMeta = gmeta;
                            }
                            try {
                                setGlobalStorage('localStorage', keySessionS, newMeta);
                            } catch (e) {
                                Object.keys(localStorage).forEach(item => {
                                    if (item.includes('appTempletStorage_')) {
                                        delete localStorage[item];
                                    }
                                });
                                setGlobalStorage('localStorage', keySessionS, newMeta);
                            }
                        } else {
                            // 二开回调
                            if (!isExeCacheMeta) {
                                getCheckedExtendMethod(this, 'templateAlreadyLoaded')(cacheMeta);
                                if (callback && typeof callback == 'function') {
                                    callback(cacheMeta);
                                }
                            }

                        }
                    },
                });
            };

            //绘制页面
            this.createUIDom = (data, callback, isCache) => {
                window.actionName = '合并请求';
                if (data.billtype) {
                    //根据billtype查appcode
                    let sencetype = '1';
                    ajax({
                        url: '/nccloud/platform/pub/getappurl.do',
                        method: 'post',
                        data: {
                            billpk: null,
                            billtypecode: data.billtype,
                            pagecode: data.pagecode || null,
                            sence: data.sence || sencetype,
                        },
                        success: result => {
                            //查询成功后请求模板
                            if (result.success && result.data && result.data.appPageVO) {
                                let appcode = result.data.appcode;
                                let pagecode = data.pagecode || result.data.appPageVO.pagecode;
                                this.requestMeta(data, appcode, pagecode, callback, null, isCache);
                            }
                        },
                    });
                } else {
                    let appReqParam = getAppReqParam();
                    let code = appReqParam.appcode;
                    let page = appReqParam.pagecode;
                    let ssc_templetid = appReqParam.ssc_templetid;

                    let pagecode = data.pagecode || page;
                    let appcode = data.appcode || code;

                    this.requestMeta(data, appcode, pagecode, callback, ssc_templetid, isCache, code);
                }
            };

            //创建一主多子简化数据
            this.createExtCardDataSimple = (pageid, head_code, bodys_code, isDeleteEmptyItem = false) => {
                let saveData = {
                    pageid,
                    head: {},
                    bodys: {},
                };
                let metaObj = this.meta.getMeta();
                //saveData.templetid = metaObj.pageid && metaObj.pageid;
                if (this.currentBill && this.currentBill.templetid) {
                    saveData.templetid = this.currentBill.templetid;
                } else {
                    saveData.templetid = metaObj.pageid && metaObj.pageid;
                }
                if (metaObj[head_code] && metaObj[head_code].moduletype && metaObj[head_code].moduletype === 'form') {
                    saveData.head[head_code] = this.form.getAllFormValueSimple(head_code);
                    saveData.head[head_code].areacode = head_code;
                }
                if (bodys_code && bodys_code instanceof Array && bodys_code.length > 0) {
                    bodys_code.forEach(body_code => {
                        if (
                            metaObj[body_code] &&
                            metaObj[body_code].moduletype &&
                            metaObj[body_code].moduletype === 'table'
                        ) {
                            saveData.bodys[body_code] = delEmptyData(this.cardTable.getAllData(body_code), 'inner_use', isDeleteEmptyItem);
                            saveData.bodys[body_code].areacode = body_code;
                        }
                    });
                }
                return saveData;
            };
            //创建一主多子数据
            this.createExtCardData = (pageid, head_code, bodys_code, rows) => {
                let saveData = {
                    pageid,
                    head: {},
                    bodys: {},
                };
                let metaObj = this.meta.getMeta();
                //saveData.templetid = metaObj.pageid && metaObj.pageid;
                if (this.currentBill && this.currentBill.templetid) {
                    saveData.templetid = this.currentBill.templetid;
                } else {
                    saveData.templetid = metaObj.pageid && metaObj.pageid;
                }
                if (metaObj[head_code] && metaObj[head_code].moduletype && metaObj[head_code].moduletype === 'form') {
                    saveData.head[head_code] = this.form.getAllFormValue(head_code);
                    saveData.head[head_code].areacode = head_code;
                }
                if (bodys_code && bodys_code instanceof Array && bodys_code.length > 0) {
                    bodys_code.forEach(body_code => {
                        if (
                            metaObj[body_code] &&
                            metaObj[body_code].moduletype &&
                            metaObj[body_code].moduletype === 'table'
                        ) {
                            if (rows) {
                                saveData.bodys[body_code] = {
                                    areaType: 'table',
                                    rows,
                                    areacode: null,
                                };
                            } else {
                                saveData.bodys[body_code] = this.cardTable.getAllData(body_code);
                            }

                            saveData.bodys[body_code].areacode = body_code;
                        }
                    });
                }
                return saveData;
            };

            //wangtaos添加，司库云-C专属使用
            this.createTabsCardData = (pageid, head_code, bodys_code) => {
                let saveData = {
                    pageid,
                    head: {},
                    bodys: {},
                };
                let { tabData, tabOrder, tab } = this.state;
                let moduleId = tabOrder[0];
                let table = this.myTable[moduleId].state.table;
                let metaObj = this.meta.getMeta();
                if (metaObj[head_code] && metaObj[head_code].moduletype && metaObj[head_code].moduletype === 'form') {
                    saveData.head[head_code] = this.form.getAllFormValue(head_code);
                    saveData.head[head_code].areacode = head_code;
                }
                if (bodys_code && bodys_code instanceof Array && bodys_code.length > 0) {
                    bodys_code.forEach(body_code => {
                        if (
                            metaObj[body_code] &&
                            metaObj[body_code].moduletype &&
                            metaObj[body_code].moduletype === 'table'
                        ) {
                            saveData.bodys[body_code] = {
                                rows: JSON.parse(
                                    JSON.stringify(body_code === tab ? table.rows : tabData[body_code].rows),
                                ),
                                areaType: 'table',
                                areacode: body_code,
                            };
                        }
                    });
                }
                return saveData;
            };

            this.getSearchParam = pop => {
                return pageTo.getSearchParam(pop);
            };
            //路由组件
            this.pushTo = (page, params) => {
                pageTo.pushTo(page, params, this.hashChangeCallBack);
            };
            //跳转页面
            this.linkTo = (page, params) => {
                pageTo.linkTo(page, params, this.hashChangeCallBack);
            };

            this.openTo = (page, params, parentParam) => {
                pageTo.openTo(page, params, parentParam);
            };

            this.specialOpenTo = (page, params, getWinObj, parentParam) => {
                pageTo.specialOpenTo(page, params, getWinObj, parentParam);
            };

            // 业务组用来在组件某些地方自行设置hashChangeCallback的方法
            this.setHashChangeCallback = callback => {
                if (callback && typeof callback === 'function') {
                    this.hashChangeCallBack = callback;
                }
            };

            //共享-编辑关联项执行完的回调
            this.setRelationAfterEventCallBack = callback => {
                if (callback && typeof callback === 'function') {
                    this.relationAfterEventCallBack = callback;
                }
            };

            //业务组在非createPage时机，通过调用API传递过来的 billinfo
            this.setRelationItemBillinfo = billinfo => {
                if (billinfo) {
                    this.billinfo = billinfo;
                }
            };

            //返回跳转
            this.linkBack = () => {
                pageTo.linkBack();
            };

            //打开nccloud以外的应用
            this.openOut = page => {
                pageTo.openOut(page);
            };

            //获取页面参数
            this.getUrlParam = pop => {
                return pageTo.getUrlParam.call(this, pop);
            };

            //重置浏览器id地址
            this.setUrlParam = params => {
                pageTo.setUrlParam(params, this.hashChangeCallBack);
            };

            //删除url参数
            this.delUrlParam = str => {
                pageTo.delUrlParam(str, this.hashChangeCallBack);
            };

            //新增浏览器参数
            this.addUrlParam = popObj => {
                pageTo.addUrlParam(popObj, this.hashChangeCallBack);
            };

            //获取小应用编码
            this.getAppCode = () => {
                return pageTo.getAppCode();
            };

            //获取页面编码
            this.getPageCode = () => {
                return pageTo.getPageCode();
            };

            //得到单个表单的编辑后数据
            this.createFormAfterEventData = (pageid, form_code, moduleId, key, value, formMetaCode) => {
                let formData = { pageid };
                let metaObj = this.meta.getMeta();
                //formData.templetid = metaObj.pageid && metaObj.pageid;
                if (this.currentBill && this.currentBill.templetid) {
                    formData.templetid = this.currentBill.templetid;
                } else {
                    formData.templetid = metaObj.pageid && metaObj.pageid;
                }
                if (
                    metaObj &&
                    metaObj[form_code] &&
                    metaObj[form_code].moduletype &&
                    metaObj[form_code].moduletype === 'form'
                ) {
                    formData[form_code] = this.form.getAllFormValue(form_code);
                    formData[form_code].areacode = form_code;
                }
                let result = {
                    attrcode: key,
                    newvalue: value,
                    oldvalue: this.formDataSource[moduleId] ? this.formDataSource[moduleId].getOldValue(key) : null,
                    form: formData,
                };
                if (formMetaCode) {
                    result.areacode = formMetaCode;
                }
                return result;
            };

            //得到表头编辑后结构:一主一子 和 一主多子
            this.createHeadAfterEventData = (
                pageid,
                head_code,
                body_code,
                moduleId,
                key,
                value,
                formMetaCode,
                tabletype,
            ) => {
                let cardData = {};
                if (typeof body_code === 'string') {
                    cardData = this.createMasterChildData(pageid, head_code, body_code, tabletype);
                    //cardData = this.createMasterChildDataSimple(pageid, head_code, body_code, tabletype);
                } else if (body_code instanceof Array) {
                    cardData = this.createExtCardData(pageid, head_code, body_code);
                    //cardData = this.createExtCardDataSimple(pageid, head_code, body_code);
                }

                let result = {
                    attrcode: key,
                    newvalue: value,
                    // 需要从表单api中取值 后面让wanglong优化
                    // oldvalue: this.formOldValues[moduleId] ? this.formOldValues[moduleId][key] : null,
                    oldvalue: this.formDataSource[moduleId] ? this.formDataSource[moduleId].getOldValue(key) : null,
                    card: cardData,
                };

                if (formMetaCode) {
                    result.areacode = formMetaCode;
                }
                return result;
            };

            //改写createHeadAfterEventData，一主多子，wangtaos为司库云提供
            this.createTabsAfterEventData = (
                pageid,
                head_code,
                body_code,
                moduleId,
                key,
                value,
                formMetaCode,
                tabletype,
            ) => {
                let cardData = {};
                if (typeof body_code === 'string') {
                    cardData = this.createMasterChildData(pageid, head_code, body_code, tabletype);
                } else if (body_code instanceof Array) {
                    cardData = this.createTabsCardData(pageid, head_code, body_code);
                }
                let result = {
                    attrcode: key,
                    newvalue: value,
                    oldvalue: this.formDataSource[moduleId] ? this.formDataSource[moduleId].getOldValue(key) : null,
                    card: cardData,
                };
                if (formMetaCode) {
                    result.areacode = formMetaCode;
                }
                return result;
            };

            //得到单个表格的编辑后数据
            this.createGridAfterEventData = (pageid, grid_code, moduleId, key, changedrows, index, tabletype, rows, tableMetaCode) => {
                let gridData = { pageid };
                let metaObj = this.meta.getMeta();
                //gridData.templetid = metaObj.pageid && metaObj.pageid;
                if (this.currentBill && this.currentBill.templetid) {
                    gridData.templetid = this.currentBill.templetid;
                } else {
                    gridData.templetid = metaObj.pageid && metaObj.pageid;
                }
                if (
                    metaObj &&
                    metaObj[grid_code] &&
                    metaObj[grid_code].moduletype &&
                    metaObj[grid_code].moduletype === 'table'
                ) {
                    if (rows) {
                        gridData[grid_code] = {
                            areaType: 'table',
                            rows,
                            areacode: null,
                        };
                    } else {
                        if (tabletype && tabletype === 'cardTable') {
                            gridData[grid_code] = this.cardTable.getAllData(grid_code);
                        } else {
                            gridData[grid_code] = this.editTable.getAllData(grid_code);
                        }
                    }

                    gridData[grid_code].areacode = tableMetaCode ? tableMetaCode : grid_code;
                }
                return {
                    attrcode: key,
                    changedrows,
                    grid: gridData,
                    index,
                };
            };

            //得到表体编辑后结构:一主一子 和 一主多子
            // pageid, head_code, body_code 自己传入
            // moduleId 为 moduleId
            // key 为 item.attrcode
            // rowid ?
            // newvalue
            this.createBodyAfterEventData = (
                pageid,
                head_code,
                body_code,
                moduleId,
                key,
                changedrows,
                index,
                tabletype,
                rows,
                tableMetaCode
            ) => {
                let cardData = {};
                if (typeof body_code === 'string') {
                    cardData = this.createMasterChildData(pageid, head_code, body_code, tabletype, rows);
                    //cardData = this.createMasterChildDataSimple(pageid, head_code, body_code, tabletype);
                    return {
                        attrcode: key,
                        changedrows,
                        card: cardData,
                        index,
                    };
                } else if (body_code instanceof Array) {
                    cardData = this.createExtCardData(pageid, head_code, body_code, rows);
                    //cardData = this.createExtCardDataSimple(pageid, head_code, body_code);
                    return {
                        areacode: tableMetaCode ? tableMetaCode : moduleId,
                        attrcode: key,
                        changedrows,
                        card: cardData,
                        index,
                    };
                }
            };

            // 改写平台createBodyAfterEventData，为sikuyun专属使用，wangtaos添加
            this.createTabsBodyAfterEventData = (
                pageid,
                head_code,
                body_code,
                moduleId,
                key,
                changedrows,
                index,
                tabletype,
            ) => {
                let cardData = {};
                if (typeof body_code === 'string') {
                    cardData = this.createTabsMasterChildData(pageid, head_code, body_code, tabletype);
                    return {
                        attrcode: key,
                        changedrows,
                        card: cardData,
                        index,
                    };
                } else if (body_code instanceof Array) {
                    cardData = this.createTabsCardData(pageid, head_code, body_code);
                    return {
                        areacode: this.state.tab,
                        attrcode: key,
                        changedrows,
                        card: cardData,
                        index,
                    };
                }
            };

            /*
                * 获取表头和表格当前行数据
                */
            this.createCurrentRowAfterEventData = (pageid, head_code, body_code, moduleId, key, changedrows, index) => {
                let saveData = {
                    pageid,
                    head: {},
                };

                let body = {};
                let metaObj = this.meta.getMeta();

                //获取表头数据
                if (metaObj[head_code] && metaObj[head_code].moduletype && metaObj[head_code].moduletype === 'form') {
                    saveData.head[head_code] = this.form.getAllFormValue(head_code);
                    saveData.head[head_code].areacode = head_code;
                }

                //获取表格数据
                if (metaObj[moduleId] && metaObj[moduleId].moduletype && metaObj[moduleId].moduletype === 'table') {
                    body[moduleId] = this.cardTable.getDataByIndex(moduleId, index);
                    body[moduleId].areacode = moduleId;
                }

                if (typeof body_code === 'string') {
                    saveData['body'] = body;
                }
                if (Array.isArray(body_code)) {
                    saveData['bodys'] = body;
                }

                return {
                    areacode: moduleId,
                    attrcode: key,
                    changedrows,
                    card: saveData,
                    index,
                };
            };

            //获取关联项
            this.getrelationItems = MetaItem => {
                if (MetaItem && MetaItem instanceof Array) {
                    let relationItems = [];
                    MetaItem.forEach(item => {
                        if (item.editAfterFlag && item.editAfterFlag === true) {
                            relationItems.push(item.attrcode);
                        }
                    });
                    //console.log(relationItems);
                    return relationItems;
                }
            };

            //得到单个表格的编辑后数据--只取当前编辑行
            this.createGridAfterEventRecord = (pageid, grid_code, moduleId, key, changedrows, record, tableMetaCode) => {
                let gridData = { pageid };
                let metaObj = this.meta.getMeta();
                //gridData.templetid = metaObj.pageid && metaObj.pageid;
                if (this.currentBill && this.currentBill.templetid) {
                    gridData.templetid = this.currentBill.templetid;
                } else {
                    gridData.templetid = metaObj.pageid && metaObj.pageid;
                }
                if (
                    metaObj &&
                    metaObj[grid_code] &&
                    metaObj[grid_code].moduletype &&
                    metaObj[grid_code].moduletype === 'table'
                ) {
                    gridData[grid_code] = { rows: [record] };
                    gridData[grid_code].areacode = tableMetaCode ? tableMetaCode : grid_code;
                }
                return {
                    attrcode: key,
                    changedrows,
                    grid: gridData,
                    index: 0,
                };
            };

            //得到表头编辑后结构:一主一子 和 一主多子---只取表头数据
            this.createHeadAfterEventRecord = (pageid, head_code, body_code, moduleId, key, value, formMetaCode) => {
                let cardData = {
                    pageid,
                    head: {},
                };
                let metaObj = this.meta.getMeta();
                if (this.currentBill && this.currentBill.templetid) {
                    cardData.templetid = this.currentBill.templetid;
                } else {
                    cardData.templetid = metaObj.pageid && metaObj.pageid;
                }
                if (metaObj[head_code] && metaObj[head_code].moduletype && metaObj[head_code].moduletype === 'form') {
                    cardData.head[head_code] = this.form.getAllFormValue(head_code);
                    cardData.head[head_code].areacode = head_code;
                }
                if (typeof body_code === 'string') {
                    cardData.body = {};
                    if (
                        metaObj[body_code] &&
                        metaObj[body_code].moduletype &&
                        metaObj[body_code].moduletype === 'table'
                    ) {
                        cardData.body[body_code] = { rows: [] };
                        cardData.body[body_code].areacode = body_code;
                    }
                } else if (body_code instanceof Array) {
                    cardData.bodys = {};
                    if (body_code && body_code instanceof Array && body_code.length > 0) {
                        body_code.forEach(code => {
                            if (metaObj[code] && metaObj[code].moduletype && metaObj[code].moduletype === 'table') {
                                cardData.bodys[code] = { rows: [] };
                                cardData.bodys[code].areacode = code;
                            }
                        });
                    }
                    //cardData = this.createExtCardDataSimple(pageid, head_code, body_code);
                }

                let result = {
                    attrcode: key,
                    newvalue: value,
                    oldvalue: this.formDataSource[moduleId] ? this.formDataSource[moduleId].getOldValue(key) : null,
                    card: cardData,
                };

                if (formMetaCode) {
                    result.areacode = formMetaCode;
                }
                return result;
            };

            //得到卡片的编辑后数据:一主一子 和 一主多子--只取当前编辑行
            this.createBodyAfterEventRecord = (pageid, head_code, body_code, moduleId, key, changedrows, record, tableMetaCode) => {
                let cardData = {
                    pageid,
                    head: {},
                };
                let metaObj = this.meta.getMeta();
                if (this.currentBill && this.currentBill.templetid) {
                    cardData.templetid = this.currentBill.templetid;
                } else {
                    cardData.templetid = metaObj.pageid && metaObj.pageid;
                }
                if (metaObj[head_code] && metaObj[head_code].moduletype && metaObj[head_code].moduletype === 'form') {
                    cardData.head[head_code] = this.form.getAllFormValue(head_code);
                    cardData.head[head_code].areacode = head_code;
                }
                if (typeof body_code === 'string') {
                    cardData.body = {};
                    if (
                        metaObj[body_code] &&
                        metaObj[body_code].moduletype &&
                        metaObj[body_code].moduletype === 'table'
                    ) {
                        cardData.body[body_code] = { rows: [record] };
                        cardData.body[body_code].areacode = tableMetaCode || body_code;
                    }
                    return {
                        attrcode: key,
                        changedrows,
                        card: cardData,
                        index: 0,
                    };
                } else if (body_code instanceof Array) {
                    cardData.bodys = {};
                    if (body_code && body_code instanceof Array && body_code.length > 0) {
                        body_code.forEach(code => {
                            if (metaObj[code] && metaObj[code].moduletype && metaObj[code].moduletype === 'table') {
                                if (code == moduleId) {
                                    cardData.bodys[code] = { rows: [record] };
                                } else {
                                    cardData.bodys[code] = { rows: [] };
                                }
                                cardData.bodys[code].areacode = code == moduleId ? tableMetaCode || code : code;
                            }
                        });
                    }
                    return {
                        areacode: tableMetaCode ? tableMetaCode : moduleId,
                        attrcode: key,
                        changedrows,
                        card: cardData,
                        index: 0,
                    };
                }
            };

            this.handleSumChildCol = (editformula, tabletype) => {
                //  可能有多个公式,使用分号隔开
                let formula = editformula.split('->sumchildcol');
                let masterInfo = formula[0];
                let childInfo = formula[1].split('(')[1].split(')')[0];
                let [formAreaCode, formAttrcode] = masterInfo.split('.');
                let [tableAreaCode, tableAttrcode] = childInfo.split('.');
                let sums = 0;
                if (tabletype === 'editTable') {
                    //获取表格当前列数据，合计到表头
                    let colValues = this.editTable.getColValue(tableAreaCode, tableAttrcode);
                    if (colValues && Array.isArray(colValues.value)) {
                        let vals = colValues.value;
                        vals.forEach((val) => {
                            sums = sum(sums, val);
                        })
                    }
                } else {
                    //获取表格当前列数据，合计到表头
                    let colValues = this.cardTable.getColValue(tableAreaCode, tableAttrcode);
                    if (colValues && Array.isArray(colValues)) {
                        colValues.forEach((item) => {
                            if (item && item.value) {
                                sums = sum(sums, item.value);
                            }
                        })
                    }
                }
                //合计数值显示到表头
                this.form.setFormItemsValue(formAreaCode, { [formAttrcode]: { value: sums } });
            }
            //编辑关联项---编辑公式
            //合计公式 前端处理：editformula: 主表区域编码.主表字段code->sumchildcol(子表区域编码.子表字段code)
            this.handleRelationItems = afterData => {
                const {
                    type = 'table',
                    areaCode,
                    key,
                    value,
                    callback,
                    changedrows,
                    index,
                    rowid,
                    formMetaCode,
                    record,
                    tableMetaCode,
                    //下面参数 liuming excel 专用 
                    rows,
                    column,
                } = afterData;

                if (this.billinfo) {
                    this.currentBill = JSON.parse(JSON.stringify(this.billinfo));

                    //档案：一个节点多个卡片,多个表单、或者多个表格，判断当前是哪个界面
                    if (this.billinfo instanceof Array && this.billinfo.length) {
                        for (let i = 0; i < this.billinfo.length; i++) {
                            if (type === 'form') {
                                if (areaCode === this.billinfo[i].headcode) {
                                    this.currentBill = this.billinfo[i];
                                    break;
                                }
                            }
                            if (type === 'table') {
                                let billBodyCode = this.billinfo[i].bodycode;
                                if (typeof billBodyCode === 'string' && areaCode === billBodyCode) {
                                    this.currentBill = this.billinfo[i];
                                    break;
                                }
                                if (billBodyCode instanceof Array && billBodyCode.includes(areaCode)) {
                                    this.currentBill = this.billinfo[i];
                                    break;
                                }
                            }
                        }
                    }

                    let { billtype, pagecode, headcode, bodycode, tabletype = '', tabs = false } = this.currentBill;

                    //资金多页表格编辑关联项
                    if (tabs) {
                        this.handleTabRelationItems(afterData);
                        return;
                    }

                    //共享服务领域适配表格
                    if (bodycode === 'findByRelation') {
                        let meta = this.meta.getMeta();
                        if (meta && meta.gridrelation) {
                            bodycode = Object.keys(meta.gridrelation);
                        }
                    }
                    if (!pagecode) {
                        pagecode = this.getSearchParam('p');
                    }
                    // eslint-disable-next-line one-var-declaration-per-line
                    let url, data, success;
                    //单个表单 或 单个表格
                    if (billtype === 'form' || billtype === 'grid') {
                        if (type === 'form') {
                            url = '/nccloud/platform/pub/formafteredit.do';
                            data = this.createFormAfterEventData(
                                pagecode,
                                areaCode,
                                areaCode,
                                key,
                                value,
                                formMetaCode,
                            );
                        } else if (type === 'table') {
                            url = '/nccloud/platform/pub/gridafteredit.do';
                            //data = this.createGridAfterEventData(pagecode, areaCode, areaCode, key, changedrows, index, tabletype);
                            if (record) {
                                //新增功能---差异化处理
                                let newRecord = JSON.parse(JSON.stringify(record || {}));
                                data = this.createGridAfterEventRecord(
                                    pagecode,
                                    areaCode,
                                    areaCode,
                                    key,
                                    changedrows,
                                    newRecord,
                                    tableMetaCode
                                );
                            } else {
                                data = this.createGridAfterEventData(
                                    pagecode,
                                    areaCode,
                                    areaCode,
                                    key,
                                    changedrows,
                                    index,
                                    tabletype,
                                    rows,
                                    tableMetaCode
                                );
                            }
                        }
                    }
                    //单据
                    if (billtype === 'card' || billtype === 'extcard') {
                        let tablecode;
                        if (Object.prototype.toString.call(bodycode) === '[object Object]') {
                            tablecode = Object.keys(bodycode);
                            if (tablecode.length == 1) {
                                tablecode = tablecode[0];
                            }
                        } else {
                            tablecode = bodycode;
                        }
                        if (type === 'form') {
                            //编辑表头
                            url = '/nccloud/platform/pub/cardheadafteredit.do';
                            if (billtype === 'extcard') {
                                url = '/nccloud/platform/pub/extcardheadafteredit.do';
                            }
                            //优化：只取表头数据
                            data = this.createHeadAfterEventRecord(
                                pagecode,
                                headcode,
                                tablecode,
                                areaCode,
                                key,
                                value,
                                formMetaCode,
                            );
                        } else if (type === 'table') {
                            //编辑表格的合计公式:editformula: 主表区域编码.主表字段code->sumchildcol(子表区域编码.子表字段code) 
                            if (column && column.editformula && typeof column.editformula === 'string') {
                                let editformula = column.editformula;
                                if (editformula.length < 1000 && /\S+.\S+->sumchildcol(\S+.\S+)\S+/.test(editformula)) {
                                    if (editformula.includes(';')) {
                                        let editformulaArr = editformula.split(';');
                                        let sumformulaLen = 0;
                                        editformulaArr.forEach(singleformula => {
                                            if (singleformula.length < 1000 && /\S+.\S+->sumchildcol(\S+.\S+)\S+/.test(singleformula)) {
                                                sumformulaLen++;
                                                this.handleSumChildCol(singleformula, tabletype);
                                            }
                                        })
                                        if (sumformulaLen === editformulaArr.length) {
                                            //只配置了合计公式，则不需要走后台
                                            return;
                                        }
                                    } else {
                                        this.handleSumChildCol(editformula, tabletype);
                                        //只配置了合计公式，则不需要走后台
                                        return;
                                    }
                                }
                            }
                            //其他公式：
                            // 编辑表格
                            url = '/nccloud/platform/pub/cardbodyafteredit.do';
                            if (billtype === 'extcard') {
                                url = '/nccloud/platform/pub/extcardbodyafteredit.do';
                            }
                            if (record) {
                                //差异化处理--只取当前行
                                let newRecord = JSON.parse(JSON.stringify(record || {}));
                                data = this.createBodyAfterEventRecord(
                                    pagecode,
                                    headcode,
                                    tablecode,
                                    areaCode,
                                    key,
                                    changedrows,
                                    newRecord,
                                    tableMetaCode
                                );
                            } else {
                                data = this.createBodyAfterEventData(
                                    pagecode,
                                    headcode,
                                    tablecode,
                                    areaCode,
                                    key,
                                    changedrows,
                                    index,
                                    tabletype,
                                    rows,
                                    tableMetaCode
                                );
                            }
                        }
                    }
                    //请求成功的回调函数
                    success = res => {
                        let tableTypeObj = {};
                        //单个表单
                        if (billtype === 'form' && type === 'form') {
                            if (res.data && res.data && res.data[areaCode]) {
                                this.form.setAllFormValue({ [areaCode]: res.data[areaCode] }, false, false, key);
                            }
                        }
                        //单个表格
                        if (billtype === 'grid' && type === 'table') {
                            if (res.data && res.data && res.data[areaCode]) {
                                if (tabletype && tabletype === 'cardTable') {
                                    //this.cardTable.setTableData(areaCode, res.data[areaCode], null, false);
                                    // this.cardTable.updateDataByIndexs(areaCode, [{
                                    //     index: index,
                                    //     data: res.data[areaCode].rows[index]
                                    // }]);
                                    //新增功能---差异化处理
                                    if (rows) {
                                        this.cardTable.updateAndAddRows({ rows: res.data[areaCode].rows, tableId: areaCode })
                                    } else {
                                        this.cardTable.updateDataByRowId(areaCode, res.data[areaCode], false, false);
                                    }
                                    tableTypeObj[areaCode] = 'cardTable';
                                } else {
                                    //this.editTable.setTableData(areaCode, res.data[areaCode], false);
                                    //新增功能---差异化处理
                                    this.editTable.updateDataByRowId(areaCode, res.data[areaCode], false, false);
                                    tableTypeObj[areaCode] = 'editTable';
                                }
                            }
                        }
                        //单据
                        if (billtype === 'card' || billtype === 'extcard') {
                            //表头赋值
                            if (res.data && res.data.head && res.data.head[headcode]) {
                                this.form.setAllFormValue({ [headcode]: res.data.head[headcode] }, false, false, key);
                            }
                            if (type === 'table') {
                                let tablecode;
                                if (Object.prototype.toString.call(bodycode) === '[object Object]') {
                                    tablecode = Object.keys(bodycode);
                                    if (tablecode.length == 1) {
                                        tablecode = tablecode[0];
                                    }
                                } else {
                                    tablecode = bodycode;
                                }

                                if (typeof tablecode === 'string') {
                                    if (res.data && res.data.body && res.data.body) {
                                        if (tabletype && tabletype === 'editTable' && res.data.body[tablecode]) {
                                            tableTypeObj[tablecode] = 'editTable';
                                            //this.editTable.setTableData(bodycode, res.data.body[bodycode], false);
                                            //新增功能---差异化处理
                                            this.editTable.updateDataByRowId(
                                                tablecode,
                                                res.data.body[tablecode],
                                                false,
                                                false,
                                            );
                                        } else {
                                            tableTypeObj[tablecode] = 'cardTable';
                                            //this.cardTable.setTableData(bodycode, res.data.body[bodycode], null, false);
                                            //新增功能---差异化处理
                                            if (tableMetaCode && res.data.body[tableMetaCode] && !res.data.body[tablecode]) {
                                                res.data.body[tablecode] = res.data.body[tableMetaCode];
                                            }

                                            if (rows) {
                                                this.cardTable.updateAndAddRows({ rows: res.data.body[tablecode].rows, tableId: tablecode })
                                            } else {
                                                this.cardTable.updateDataByRowId(
                                                    tablecode,
                                                    res.data.body[tablecode],
                                                    false,
                                                    false
                                                );
                                            }

                                        }
                                    }
                                } else if (tablecode instanceof Array) {
                                    tablecode.forEach(code => {
                                        if (res.data && res.data.bodys) {
                                            if (tabletype && tabletype === 'editTable' && res.data.bodys[code]) {
                                                tableTypeObj[code] = 'editTable';
                                                //this.editTable.setTableData(code, res.data.bodys[code], false);
                                                //新增功能---差异化处理
                                                this.editTable.updateDataByRowId(
                                                    code,
                                                    res.data.bodys[code],
                                                    false,
                                                    false
                                                );
                                            } else {
                                                tableTypeObj[code] = 'cardTable';
                                                //this.cardTable.setTableData(code, res.data.bodys[code], null, false);
                                                //新增功能---差异化处理
                                                if (tableMetaCode && res.data.bodys[tableMetaCode] && !res.data.bodys[code]) {
                                                    res.data.bodys[code] = res.data.bodys[tableMetaCode];
                                                }

                                                if (rows && code === areaCode) {
                                                    this.cardTable.updateAndAddRows({ rows: res.data.bodys[code].rows, tableId: code })
                                                } else {
                                                    this.cardTable.updateDataByRowId(
                                                        code,
                                                        res.data.bodys[code],
                                                        false,
                                                        false,
                                                    );
                                                }

                                            }
                                        }
                                    });
                                }
                            }
                        }
                        if (res.formulamsg && res.formulamsg instanceof Array && res.formulamsg.length > 0) {
                            this.dealFormulamsg(res.formulamsg, tableTypeObj, null, null, index);
                        }
                        isFunction(callback) && callback(this);
                        if (res && typeof this.relationAfterEventCallBack === 'function') {
                            this.relationAfterEventCallBack(this.output, key, areaCode, data, res.data, index, rowid); //表体行新旧值
                        }
                    };
                    //发送查询关联项事件
                    if (url && data && success) {
                        ajax({
                            url,
                            method: 'post',
                            async: false,
                            data,
                            success,
                        });
                    }
                } else {
                    isFunction(callback) && callback(this);
                }
            };

            //验证公式,显示公式
            this.dealFormulamsg = (formulamsg, tableTypeObj, callback, data, currentIndex) => {
                if (!tableTypeObj) {
                    let { bodycode } = this.billinfo;
                    if (Object.prototype.toString.call(bodycode) === '[object Object]') {
                        tableTypeObj = bodycode;
                    }
                }
                if (formulamsg && formulamsg instanceof Array && formulamsg.length > 0) {
                    let mesArr = [];
                    let n = 0;
                    let m = 0;
                    formulamsg.forEach(item => {
                        if (item.type === '5') {
                            let meta = this.meta.getMeta();
                            if (item.render && meta[item.render.areacode]) {
                                let index = meta[item.render.areacode].items.findIndex(ele => {
                                    return ele.attrcode === item.render.attrcode;
                                });
                                if (index !== -1 && item.render && item.render.color) {
                                    meta[item.render.areacode].items[index].color = item.render.color;
                                    this.meta.setMeta(meta);
                                }
                            }
                        }
                        if (item.type === '3' || item.type === '4') {
                            let meta = this.meta.getMeta();
                            if (item.render && meta[item.render.areacode]) {
                                let index = meta[item.render.areacode].items.findIndex(ele => {
                                    return ele.attrcode === item.render.attrcode;
                                });
                                if (index != -1) {
                                    if (meta[item.render.areacode].moduletype === 'form') {
                                        let currentcode = item.render.areacode;
                                        let maincode = meta[currentcode].relationcode ? meta[currentcode].relationcode : currentcode;
                                        this.form.setFormItemsDisabled(maincode, {
                                            [item.render.attrcode]: !item.render.editflag
                                        });
                                    } else if (
                                        meta[item.render.areacode].moduletype === 'table' &&
                                        tableTypeObj &&
                                        tableTypeObj[item.render.areacode]
                                    ) {
                                        if (tableTypeObj[item.render.areacode] === 'editTable') {
                                            this.editTable.setEditableRowKeyByIndex(
                                                item.render.areacode,
                                                //+item.render.rownum,
                                                typeof currentIndex === 'undefined' ? +item.render.rownum : currentIndex,
                                                item.render.attrcode,
                                                item.render.editflag,
                                            );
                                        }
                                        if (tableTypeObj[item.render.areacode] === 'cardTable') {
                                            this.cardTable.setEditableByIndex(
                                                item.render.areacode,
                                                //+item.render.rownum,
                                                typeof currentIndex === 'undefined' ? +item.render.rownum : currentIndex,
                                                item.render.attrcode,
                                                item.render.editflag,
                                            );
                                        }
                                    }
                                }
                            }
                        }
                        if (item.type === '1') {
                            if (item.message) {
                                mesArr.push({
                                    title: '提示',
                                    color: 'info',
                                    content: item.message,
                                    noCancelBtn: true,
                                    type: '1',
                                });
                            }
                        }
                        // type为2说明配置了error类型的验证公式，公式不满足，后台抛异常，ajax会走error
                        // 公式满足，直接保存，前端不需要处理

                        // if (item.type === '2') {
                        //     if (item.message) {
                        //         mesArr.push({
                        //             title: '错误',
                        //             color: 'danger',
                        //             content: item.message,
                        //             noCancelBtn: true,
                        //             type: '2',
                        //         });
                        //     }
                        // }
                        // type为0说明配置了确认类型的验证公式，只有用户点击确定按钮后，才走error
                        if (item.type === '0') {
                            if (item.message) {
                                n++;
                                mesArr.push({
                                    title: '确认',
                                    color: 'success',
                                    content: item.message,
                                    noCancelBtn: false,
                                    type: '0',
                                });
                            }
                        }
                    });

                    if (n === 0 && typeof callback === 'function' && data) {
                        callback(data);
                    }

                    outPromptByArr(mesArr, 0);

                    function outPromptByArr(Arr, index) {
                        if (index < Arr.length) {
                            promptBox({
                                title: Arr[index].title,
                                color: Arr[index].color,
                                content: Arr[index].content,
                                noCancelBtn: Arr[index].noCancelBtn,
                                beSureBtnClick: () => {
                                    if (Arr[index].type === '0') {
                                        m++;
                                        if (m === n) {
                                            if (callback && data) {
                                                callback(data);
                                            }
                                        }
                                    }
                                    outPromptByArr(Arr, ++index);
                                },
                                cancelBtnClick: () => {
                                    if (Arr[index].type === '0') {
                                        m > 0 ? m-- : m;
                                    }
                                    outPromptByArr(Arr, ++index);
                                },
                            });
                        }
                    }
                }
            };

            /**表格点击保存按钮 弹出确认框点击确认再进行保存
                 * data 单据数据
                 * tableTypeObj 界面上所有表格的类型 非必输
                 * billType 单据类型 非必输
                 * callback 点击确认的时候执行的自定义回调
                 */
            this.validateToSave = (data, callback, tableTypeObj, billType) => {
                if (!billType) {
                    billType = this.billinfo.billtype;
                }
                let meta = this.meta.getMeta();
                if (meta && meta.validateFlag) {
                    let resData = new Promise(resolve => {
                        ajax({
                            url: '/nccloud/platform/formula/validate.do',
                            data: {
                                type: billType,
                                userJson: JSON.stringify(data),
                            },
                            success(res) {
                                if (res.success) {
                                    resolve(res.formulamsg);
                                }
                            },
                        });
                    });
                    resData.then(formulamsg => {
                        if (formulamsg && formulamsg !== {}) {
                            this.dealFormulamsg.call(this, formulamsg, tableTypeObj, callback, data);
                        } else {
                            callback(data);
                        }
                    });
                } else {
                    callback(data);
                }
            };

            //复写handleRelationItems，司库云多页签使用，wangtaos添加
            this.handleTabRelationItems = afterData => {
                const {
                    type = 'table',
                    areaCode,
                    key,
                    value,
                    callback,
                    changedrows,
                    index,
                    rowid,
                    formMetaCode,
                    record,
                } = afterData;
                if (this.billinfo) {
                    let { billtype, pagecode, headcode, bodycode, tabletype = '' } = this.billinfo;
                    if (bodycode === 'findByRelation') {
                        let meta = this.meta.getMeta();
                        if (meta && meta.gridrelation) {
                            bodycode = Object.keys(meta.gridrelation);
                        }
                    }
                    if (!pagecode) {
                        pagecode = this.getSearchParam('p');
                    }
                    // eslint-disable-next-line one-var-declaration-per-line
                    let url, data, success;
                    //单个表单 或 单个表格
                    if (billtype === 'form' || billtype === 'grid') {
                        if (type === 'form') {
                            url = '/nccloud/platform/pub/formafteredit.do';
                            data = this.createFormAfterEventData(pagecode, areaCode, areaCode, key, value);
                        } else if (type === 'table') {
                            url = '/nccloud/platform/pub/gridafteredit.do';
                            data = this.createGridAfterEventData(
                                pagecode,
                                areaCode,
                                areaCode,
                                key,
                                changedrows,
                                index,
                                tabletype,
                            );
                        }
                    }
                    //单据
                    if (billtype === 'card' || billtype === 'extcard') {
                        let tablecode;
                        if (Object.prototype.toString.call(bodycode) === '[object Object]') {
                            tablecode = Object.keys(bodycode);
                            if (tablecode.length == 1) {
                                tablecode = tablecode[0];
                            }
                        } else {
                            tablecode = bodycode;
                        }
                        if (type === 'form') {
                            url = '/nccloud/platform/pub/cardheadafteredit.do';
                            if (billtype === 'extcard') {
                                url = '/nccloud/platform/pub/extcardheadafteredit.do';
                            }
                            data = this.createTabsAfterEventData(
                                pagecode,
                                headcode,
                                tablecode,
                                areaCode,
                                key,
                                value,
                                formMetaCode,
                                tabletype,
                            );
                            if (billtype === 'card') {
                                //新增功能---差异化处理
                                for (let pop in data.card.body) {
                                    data.card.body[pop] = {
                                        ...data.card.body[pop],
                                        rows: [],
                                    };
                                }
                            }
                        } else if (type === 'table') {
                            url = '/nccloud/platform/pub/cardbodyafteredit.do';
                            if (billtype === 'extcard') {
                                url = '/nccloud/platform/pub/extcardbodyafteredit.do';
                            }
                            data = this.createTabsBodyAfterEventData(
                                pagecode,
                                headcode,
                                tablecode,
                                areaCode,
                                key,
                                changedrows,
                                index,
                                tabletype,
                            );
                            if (billtype === 'card' && record && tabletype != 'editTable') {
                                //新增功能---差异化处理
                                data.card.body[tablecode] = {
                                    ...data.card.body[tablecode],
                                    rows: [record],
                                };
                                data.index = 0;
                            }
                        }
                    }
                    //请求成功的回调函数
                    success = res => {
                        let tableTypeObj = {};
                        //单个表单
                        if (billtype === 'form' && type === 'form') {
                            if (res.data && res.data && res.data[areaCode]) {
                                this.form.setAllFormValue({ [areaCode]: res.data[areaCode] }, false, false, key);
                            }
                        }
                        //单个表格
                        if (billtype === 'grid' && type === 'table') {
                            if (res.data && res.data && res.data[areaCode]) {
                                if (tabletype && tabletype === 'cardTable') {
                                    this.cardTable.updateTabDataByIndexs(areaCode, [
                                        {
                                            index,
                                            data: res.data[areaCode].rows[index],
                                        },
                                    ]);
                                    tableTypeObj[areaCode] = 'cardTable';
                                }
                            }
                        }
                        //单据
                        if (billtype === 'card' || billtype === 'extcard') {
                            //表头赋值
                            if (res.data && res.data.head && res.data.head[headcode]) {
                                this.form.setAllFormValue({ [headcode]: res.data.head[headcode] }, false, false, key);
                            }
                            //表体赋值
                            if (type === 'table') {
                                if (typeof bodycode === 'string') {
                                    if (res.data && res.data.body && res.data.body[bodycode]) {
                                        if (tabletype && tabletype === 'cardTable') {
                                            tableTypeObj[bodycode] = 'cardTable';
                                            //新增功能---差异化处理
                                            //this.cardTable.updateTabDataByRowId(bodycode, res.data.body[bodycode]);
                                            this.cardTable.updateTabDataByIndexs(bodycode, [
                                                {
                                                    index,
                                                    data: res.data.body[bodycode].rows[0],
                                                },
                                            ]);
                                        }
                                    }
                                } else if (bodycode instanceof Array) {
                                    bodycode.forEach(code => {
                                        if (res.data && res.data.bodys && res.data.bodys[code]) {
                                            if (tabletype && tabletype === 'cardTable') {
                                                tableTypeObj[code] = 'cardTable';
                                                //this.cardTable.setTableData(code, res.data.bodys[code], null, false);
                                                this.cardTable.updateTabDataByIndexs(code, [
                                                    {
                                                        index,
                                                        data: res.data.bodys[code].rows[index],
                                                        //data: JSON.parse(JSON.stringify(res.data.bodys[code].rows[index]))
                                                    },
                                                ]);
                                            }
                                        }
                                    });
                                } else if (Object.prototype.toString.call(bodycode) === '[object Object]') {
                                    if (Object.keys(bodycode).length > 1) {
                                        Object.keys(bodycode).forEach(code => {
                                            if (res.data && res.data.bodys && res.data.bodys[code]) {
                                                if (tabletype && tabletype === 'cardTable') {
                                                    tableTypeObj[code] = 'cardTable';
                                                    //this.cardTable.setTableData(code, res.data.bodys[code], null, false);
                                                    this.cardTable.updateTabDataByIndexs(code, [
                                                        {
                                                            index,
                                                            //data: JSON.parse(JSON.stringify(res.data.bodys[code].rows[index]))
                                                            data: res.data.bodys[code].rows[index],
                                                        },
                                                    ]);
                                                }
                                            }
                                        });
                                    } else if (Object.keys(bodycode).length == 1) {
                                        let code = Object.keys(bodycode)[0];
                                        if (res.data && res.data.body && res.data.body[code]) {
                                            if (tabletype && tabletype === 'cardTable') {
                                                tableTypeObj[code] = 'cardTable';
                                                //this.cardTable.setTableData(code, res.data.body[code], null, false);
                                                //新增功能---差异化处理
                                                //this.cardTable.updateTabDataByRowId(code, res.data.body[code]);
                                                this.cardTable.updateTabDataByIndexs(code, [
                                                    {
                                                        index,
                                                        data: res.data.body[code].rows[0],
                                                    },
                                                ]);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        if (res.formulamsg && res.formulamsg instanceof Array && res.formulamsg.length > 0) {
                            this.dealTabFormulamsg(res.formulamsg, tableTypeObj);
                        }
                        callback();
                        if (res && typeof this.relationAfterEventCallBack === 'function') {
                            this.relationAfterEventCallBack(this.output, key, areaCode, data, res.data, index, rowid); //表体行新旧值
                        }
                    };
                    //发送查询关联项事件
                    if (url && data && success) {
                        ajax({
                            url,
                            method: 'post',
                            async: false,
                            data,
                            success,
                        });
                    }
                } else {
                    callback();
                }
            };

            //复写dealFormulamsg，司库云多页签使用，wangtaos添加
            this.dealTabFormulamsg = (formulamsg, tableTypeObj, callback, data) => {
                if (!tableTypeObj) {
                    let { bodycode } = this.billinfo;
                    if (Object.prototype.toString.call(bodycode) === '[object Object]') {
                        tableTypeObj = bodycode;
                    }
                }
                if (formulamsg && formulamsg instanceof Array && formulamsg.length > 0) {
                    let mesArr = [];
                    let n = 0;
                    let m = 0;
                    formulamsg.forEach(item => {
                        if (item.type === '5') {
                            let meta = this.meta.getMeta();
                            if (item.render && meta[item.render.areacode]) {
                                let index = meta[item.render.areacode].items.findIndex(ele => {
                                    return ele.attrcode === item.render.attrcode;
                                });
                                if (index !== -1 && item.render && item.render.color) {
                                    meta[item.render.areacode].items[index].color = item.render.color;
                                    this.meta.setMeta(meta);
                                }
                            }
                        }
                        if (item.type === '3' || item.type === '4') {
                            let meta = this.meta.getMeta();
                            if (item.render && meta[item.render.areacode]) {
                                let index = meta[item.render.areacode].items.findIndex(ele => {
                                    return ele.attrcode === item.render.attrcode;
                                });
                                if (index != -1) {
                                    if (meta[item.render.areacode].moduletype === 'form') {
                                        this.form.setFormItemsDisabled(item.render.areacode, { [item.render.attrcode]: !item.render.editflag });
                                    } else if (
                                        meta[item.render.areacode].moduletype === 'table' &&
                                        tableTypeObj &&
                                        tableTypeObj[item.render.areacode]
                                    ) {
                                        if (tableTypeObj[item.render.areacode] === 'editTable') {
                                            this.editTable.setEditableRowKeyByIndex(
                                                item.render.areacode,
                                                +item.render.rownum,
                                                item.render.attrcode,
                                                item.render.editflag,
                                            );
                                        }
                                        if (tableTypeObj[item.render.areacode] === 'cardTable') {
                                            this.cardTable.setTabTdEditByIndex(
                                                item.render.areacode,
                                                +item.render.rownum,
                                                item.render.attrcode,
                                                item.render.editflag,
                                            );
                                        }
                                    }
                                }
                            }
                        }
                        if (item.type === '1') {
                            if (item.message) {
                                mesArr.push({
                                    title: '提示',
                                    color: 'info',
                                    content: item.message,
                                    noCancelBtn: true,
                                    type: '1',
                                });
                                if (callback && data) {
                                    callback(data);
                                }
                            }
                        }
                        if (item.type === '2') {
                            if (item.message) {
                                mesArr.push({
                                    title: '错误',
                                    color: 'danger',
                                    content: item.message,
                                    noCancelBtn: true,
                                    type: '2',
                                });
                            }
                        }
                        if (item.type === '0') {
                            if (item.message) {
                                n++;
                                mesArr.push({
                                    title: '确认',
                                    color: 'success',
                                    content: item.message,
                                    noCancelBtn: false,
                                    type: '0',
                                });
                            }
                        }
                    });
                    if (n === 0 && callback && typeof callback === 'function' && data) {
                        callback(data);
                    }
                    outPromptByArr(mesArr, 0);

                    function outPromptByArr(Arr, index) {
                        if (index < Arr.length) {
                            promptBox({
                                title: Arr[index].title,
                                color: Arr[index].color,
                                content: Arr[index].content,
                                noCancelBtn: Arr[index].noCancelBtn,
                                beSureBtnClick: () => {
                                    if (Arr[index].type === '0') {
                                        m++;
                                        if (m === n) {
                                            if (callback && data) {
                                                callback(data);
                                            }
                                        }
                                    }
                                    outPromptByArr(Arr, ++index);
                                },
                                cancelBtnClick: () => {
                                    if (Arr[index].type === '0') {
                                        m > 0 ? m-- : m;
                                    }
                                    outPromptByArr(Arr, ++index);
                                },
                            });
                        }
                    }
                }
            };

            // 复写validateToSave，司库云多页签使用，wangtaos添加
            this.validateToTabSave = (data, callback, tableTypeObj, billType) => {
                if (!billType) {
                    billType = this.billinfo.billtype;
                }
                let meta = this.meta.getMeta();
                if (meta && meta.validateFlag) {
                    let resData = new Promise(resolve => {
                        ajax({
                            url: '/nccloud/platform/formula/validate.do',
                            data: {
                                type: billType,
                                userJson: JSON.stringify(data),
                            },
                            success(res) {
                                if (res.success) {
                                    resolve(res.formulamsg);
                                }
                            },
                        });
                    });
                    resData.then(formulamsg => {
                        if (formulamsg && formulamsg !== {}) {
                            this.dealTabFormulamsg.call(this, formulamsg, tableTypeObj, callback, data);
                        } else {
                            callback(data);
                        }
                    });
                } else {
                    callback(data);
                }
            };

            //如单据有主组织，新增时,将meta其他字段设置为不可编辑
            this.initMetaByPkorg = (key = 'pk_org') => {
                let metaObj = this.meta.getMeta();
                if (Object.keys(metaObj).length) {
                    for (let id in metaObj) {
                        if (
                            id !== 'formrelation' &&
                            id !== 'gridrelation' &&
                            metaObj[id] &&
                            metaObj[id].items &&
                            metaObj[id].items instanceof Array
                        ) {
                            let isTableForm = this.isTableEditForm(id, metaObj);
                            this.copyMetaBeforePkorg[id] = this.copyMetaBeforePkorg[id] || {};
                            metaObj[id].items.map(e => {
                                let _disabled = e.disabled;
                                if (e.attrcode && e.attrcode !== key) {
                                    if (metaObj[id].moduletype != 'form' || isTableForm) {
                                        e.disabled = true;
                                    }
                                }
                                // 把meta上的disabled存起来
                                this.copyMetaBeforePkorg[id][e.attrcode] = _disabled;
                            });

                            if (metaObj[id].moduletype === 'form' && !isTableForm) {
                                if (this.formDataSource[id]) {
                                    // 把所有关联区域的字段都禁用，不修改meta
                                    let items = this.formDataSource[id].getInnerHooks().getAllFormItem();
                                    let disabled = Object.keys(items).reduce((o, attrcode) => {
                                        if (attrcode !== key) {
                                            o[attrcode] = true;
                                        } else {
                                            // 取模板原始值
                                            o[attrcode] = !!items[attrcode]?.['__origin__']?.disabled;
                                        }
                                        return o;
                                    }, {});
                                    this.formDataSource[id].setItemAttribute('disabled', disabled)
                                }
                            }
                        }
                    }
                }
                this.meta.setMeta(metaObj);
            };

            this.isTableEditForm = (moduleId, metaObj) => {
                let result = false;
                if (metaObj['gridrelation'] && Object.keys(metaObj['gridrelation']).length) {
                    for (let pop in metaObj['gridrelation']) {
                        if (
                            metaObj['gridrelation'][pop].destEditAreaCode &&
                            Array.isArray(metaObj['gridrelation'][pop].destEditAreaCode) &&
                            metaObj['gridrelation'][pop].destEditAreaCode.includes(moduleId)
                        ) {
                            result = true;
                            break;
                        }
                    }
                }
                return result;
            };

            // 资金在用
            this.MutiInit = outputWrapper.call(this, 'MutiInit', mutiInit);

            this.MultiInit = outputWrapper.call(this, 'MultiInit', { getMultiLang: getMultiLang });

            this.ViewModel = ViewModel;

            //选择主组织以后，恢复模板，恢复其他字段的编辑性
            this.resMetaAfterPkorgEdit = () => {
                let meta = this.meta.getMeta();
                if (meta) {
                    for (let id in meta) {
                        if (
                            id !== 'formrelation' &&
                            id !== 'gridrelation' &&
                            meta[id] &&
                            meta[id].items &&
                            meta[id].items instanceof Array
                        ) {
                            if (meta[id].moduletype === 'form' && !this.isTableEditForm(id, meta)) {
                                if (this.formDataSource[id]) {
                                    // 取上一次存的meta值，设到表单上，不改变meta
                                    let formList = this.formDataSource[id].getInnerHooks().getFormList();
                                    let disabled = formList.reduce((o, moduleId) => {
                                        Object.assign(o, this.copyMetaBeforePkorg[moduleId]);
                                        return o;
                                    }, {})

                                    this.formDataSource[id].setItemAttribute('disabled', disabled)
                                }
                            } else {
                                //表格
                                meta[id].items = meta[id].items.map(item => {
                                    if (
                                        this.copyMetaBeforePkorg[id] &&
                                        this.copyMetaBeforePkorg[id].hasOwnProperty(item.attrcode)
                                    ) {
                                        item.disabled = !!this.copyMetaBeforePkorg[id][item.attrcode];
                                    }
                                    return item;
                                });
                                // meta[id].items = meta[id].items.map((item, index) => {
                                // 	if (this.copyMetaBeforePkorg[id] && this.copyMetaBeforePkorg[id].items[index]) {
                                // 		item.disabled = !!this.copyMetaBeforePkorg[id].items[index].disabled;
                                // 	}
                                // 	return item;
                                // });
                            }
                        }
                    }
                    this.meta.setMeta(meta);
                }
            };

            //二开的方法
            this[feConfigName] = {};

            // 统一输出给业务组使用的api
            this.output = {
                use: this.use,
                cardPagination: this.cardPagination,
                editTable: this.editTable,
                form: this.form,
                table: this.table,
                search: this.search,
                button: this.button,
                meta: this.meta,
                insertTable: this.insertTable,
                transferTable: this.transferTable,
                asyncTree: this.asyncTree,
                syncTree: this.syncTree,
                modal: this.modal,
                ncmodal: this.modal,
                setPageStatus: this.setPageStatus,
                getPageStatus: this.getPageStatus,
                renderItem: this.renderItem,
                getPageDataByMeta: this.getPageDataByMeta,
                createTabsMasterChildData: this.createTabsMasterChildData,
                createMasterChildData: this.createMasterChildData,
                createMasterChildDataSimple: this.createMasterChildDataSimple,
                createExtCardDataSimple: this.createExtCardDataSimple,
                createUIDom: this.createUIDom,
                createFormAfterEventData: this.createFormAfterEventData,
                createGridAfterEventData: this.createGridAfterEventData,
                createHeadAfterEventData: this.createHeadAfterEventData,
                createTabsAfterEventData: this.createTabsAfterEventData,
                createBodyAfterEventData: this.createBodyAfterEventData,
                createTabsBodyAfterEventData: this.createTabsBodyAfterEventData,
                createExtCardData: this.createExtCardData,
                createTabsCardData: this.createTabsCardData,
                createCurrentRowAfterEventData: this.createCurrentRowAfterEventData,
                linkTo: this.linkTo,
                openTo: this.openTo,
                specialOpenTo: this.specialOpenTo,
                setHashChangeCallback: this.setHashChangeCallback,
                setRelationAfterEventCallBack: this.setRelationAfterEventCallBack,
                getUrlParam: this.getUrlParam,
                setUrlParam: this.setUrlParam,
                addUrlParam: this.addUrlParam,
                delUrlParam: this.delUrlParam,
                // approveDetail: this.approveDetail,
                treeTableManyCol: this.treeTableManyCol,
                initMetaByPkorg: this.initMetaByPkorg,
                resMetaAfterPkorgEdit: this.resMetaAfterPkorgEdit,
                // Uploader: this.Uploader,
                downLoad: this.downLoad,
                cardTable: this.cardTable,
                MutiInit: this.MutiInit,
                MultiInit: this.MultiInit,
                DragWidthCom: this.DragWidthCom,
                getSearchParam: this.getSearchParam,
                checkNode: this.checkNode,
                dealFormulamsg: this.dealFormulamsg,
                validateToSave: this.validateToSave,
                dealTabFormulamsg: this.dealTabFormulamsg,
                validateToTabSave: this.validateToTabSave,
                linkBack: this.linkBack,
                openOut: this.openOut,
                ViewModel: this.ViewModel,
                pushTo: this.pushTo,
                // ncUploader: this.Uploader,
                handleRelationItems: this.handleRelationItems,
                handleTabRelationItems: this.handleTabRelationItems,
                getAppCode: this.getAppCode,
                getPageCode: this.getPageCode,
                beforeUpdatePage: this.beforeUpdatePage,
                updatePage: this.updatePage,
                BillHeadInfo: this.BillHeadInfo,
                setRelationItemBillinfo: this.setRelationItemBillinfo,
                findFocusModuleId: findFocusModuleId.bind(this),
                controlAutoFocus: controlAutoFocus.bind(this),
                executeAutoFocus,
                getFocusRowIndex,
                getAuthorityOfNotification,
                noticeToUser,
                PubSub,
                multiCardTable: this.multiCardTable,
                socket: this.socket,
            };
        }

        beforeUpdatePage = () => {
            this.isUpdatePage = true;
        };

        updatePage = (formid, tableid, buttonAreas, editid, autoFocus = false) => {
            this.isUpdatePage = false;
            if (buttonAreas && Array.isArray(buttonAreas)) {
                // this.button.updateButtons(buttonAreas) // 按钮组更新
            }

            if (formid) {
                this.form.updateForm(formid);
            }
            if (tableid) {
                this.cardTable.updateTable(tableid, autoFocus);
            }
            if (editid) {
                this.editTable.updateTable(editid, autoFocus);
            }
        };

        //
        keyDownHandle = e => {
            let isTab = [e.keyCode, e.which, e.charCode].find(e => e === 9 || e === 13);
            isTab && (ViewModel.shouldAutoFocus = true);
            setTimeout(() => {
                ViewModel.shouldAutoFocus = false;
            }, 300);
        };

        UNSAFE_componentWillMount() {
            //关闭浏览器时给出提示
            // window.onbeforeunload = function() {
            // 	return '确定要离开吗？'
            // };
            //window.onbeforeunload = null;
            //select、datepicker、number、input、textarea
            //radio、checkbox、switch,label
            //初始化模板，调用业务组初始化模板的方法
            // if (initTemplate && typeof initTemplate === 'function') {
            // 	initTemplate({ ...this.props, ...this.output });
            // }

            // 初始设置语言
            /*if (mutiLangCode) {
                    this.MutiInits.init(mutiLangCode);
                }*/

            let callback = () => {
                if (initTemplate && typeof initTemplate === 'function') {
                    initTemplate({ ...this.props, ...this.output });
                }
            };
            // 初始设置语言
            if (mutiLangCode) {
                if (!this.MutiInit) {
                    this.MutiInit = outputWrapper.call(this, 'MutiInit', mutiInit);
                }
                this.MutiInit.init(mutiLangCode, callback, domainName);
            } else {
                callback();
            }
        }

        disableHistory = () => {
            history.pushState(null, null, document.URL);
        }

        isIE = () => {
            let userAgent = navigator.userAgent,
                isIE = false;
            //判断是否IE浏览器
            if (
                userAgent.indexOf("compatible") > -1 &&
                userAgent.indexOf("MSIE") > -1 &&
                !isOpera
            ) {
                isIE = true;
            }
            //判断是否Edge浏览器
            if (userAgent.indexOf("Trident") > -1) {
                isIE = true;
            }
            return isIE;
        }

        startPerMonitor = () => {
            //渲染监控 --chendch
            console.log("sprBegin---");
            this.listenSprNum = 1;
            if (window.top.sys_monitor) {
                const that = this;
                window.top.addEventListener('modifySPR', this.handleModifySPR, false);
                //处理单页跳转情况
                if (window.top.sys_monitor.getbMonitor && window.top.sys_monitor.getbMonitor() && !this.isIE()) window.top.sys_monitor.initMonitor(that, false);
            }
        }

        handleModifySPR = (obj) => {
            console.log("111--", obj.data, this.listenSprNum);
            if (obj.data.spr && this.listenSprNum == 1 && window.top.sys_monitor) {
                window.top.sys_monitor.startMonitor();
                debugger
                let isOpenMonitor = window.top.sys_monitor.getbMonitor();
                debugger
                if (isOpenMonitor) {
                    this.listenSprNum += 1;
                    debugger
                    window.top.sys_monitor.initMonitor(that);
                }
            } else {
                this.listenSprNum = 1;
            }
        }

        componentDidMount() {
            //this.startPerMonitor();
            //禁用浏览器后退按钮
            if (window !== top && !historyBack) {
                this.disableHistory();
                window.addEventListener('popstate', this.disableHistory);
            }

            //加载二开的文件--liuxis
            fastExtend.install(
                allMethods => {
                    // console.log(allMethods, this);
                    this[feConfigName] = Object.assign({}, allMethods);
                    getCheckedMethod(allMethods, 'UIExtend')(this.output);
                    getCheckedMethod(allMethods, 'currentPageDidMount')(this.output);
                },
                { billinfo: this.billinfo },
            );
            // 如果父级有iframe 让焦点聚焦到子窗口 bbqin
            if (document && document.body) {
                // 方案一
                document.body.tabindex = '0';
                document.body.setAttribute('tabindex', 0);
                // this.appAutoFocus && document.body.focus();

                // 方案二
                // let adom = document.createElement('input');
                // adom.style.position = 'absolute';
                // adom.style.top = '-1000px';
                // document.body.insertBefore(adom, document.querySelector('#app'));
                // this.appAutoFocus && adom.focus();

                // 方案三
                let appDom = document.querySelector('#app');
                if (appDom && isIE()) {
                    appDom.tabindex = '0';
                    appDom.setAttribute('tabindex', 0);
                    this.appAutoFocus && appDom.focus();
                    setTimeout(function () {
                        appDom.tabindex = '-1';
                        appDom.setAttribute('tabindex', -1);
                    }, 500);
                } else {
                    this.appAutoFocus && document.body.focus();
                }
            }
            // 添加  onkeydown 全局事件 by bbqin
            if (document) {
                document.addEventListener('keydown', this.keyDownHandle);
            }
        }

        componentWillUnmount() {
            getCheckedExtendMethod(this, 'beforeUnMountEvent')(this.output);
            // 放到组件里单独注销了 避免一个页面有多个createPage导致其他pubsub不生效的问题
            // 注销订阅事件
            // PubSub.unsubscribe('autoFocus');
            // PubSub.unsubscribe('autoFocus-IoC');
            // 清除所有
            // PubSub.clearAllSubscriptions();
            // 清理
            // this.infoExchangeController = null;

            //销毁二开绑定在window上的事件
            fastExtend.unstall();

            if (document) {
                document.removeEventListener('keydown', this.keyDownHandle);
            }
            if (window !== top) {
                window.removeEventListener('popstate', this.disableHistory);
            }

            // if (window.top.sys_monitor) {
            //     window.top.removeEventListener('modifySPR', this.handleModifySPR, false);
            // }

        }

        setPageStatus = (type, id) => {
            if (['edit', 'add', 'browse'].includes(type)) {
                //改变页面url
                let hash = window.location.hash.split('?'),
                    query = Qs.parse(hash[1]);
                query = { ...query, type, id };
                hash[1] = Qs.stringify(query);
                window.location.hash = hash.join('?');

                // 改变页面状态
                this.setState(
                    { status: type },
                    () => {
                        //console.log(this.state.status);
                    },
                );
            }
        };

        getPageStatus = () => {
            return this.state.status;
        };

        //end monitor
        endMonitor = () => {
            if (window.top.sys_monitor && window.top.sys_monitor.endMonitor) {
                window.top.sys_monitor.endMonitor();
            }
        };

        render() {
            //warning: ref='monitorpage' 仅作为端性能监控使用，release版本将会去除!!!
            //add by chendch@yonyou.com
            //let isOpenMonitor = false;//false;
            // if (window.top.sys_monitor && !this.isIE()) {
            //     if (window.top.sys_monitor.getbMonitor && isIE) {
            //         isOpenMonitor = window.top.sys_monitor.getbMonitor();
            //     }
            // }
            // if (isOpenMonitor) {
            //     return <App {...this.props} {...this.output} endMonitor={this.endMonitor} ref="monitorpage" />;
            // } else {
                return <App {...this.props} {...this.output} />;
            //}
        }
    }
    return Page;
};

function getCompatibleApi(func) {
    let { component, displayName, unfinished } = func;
    let api = func.call(this);
    let unfinishedApi = {};
    if (!component) {
        let notLoadedApi = {}
        Object.keys(api).forEach(name => {
            notLoadedApi[name] = function () {
                console.warn(`${displayName}组件没有被加载，当前调用的api是${name}`);
            }
        });
        api = notLoadedApi;
    }
    if (Array.isArray(unfinished)) {
        unfinished.forEach(name => {
            unfinishedApi[name] = function () {
                console.warn(`${displayName}组件的${name}方法还没有完成`);
            }
        })
    }
    api._store = (component && component['use' + displayName]) || (() => { });
    return Object.assign(unfinishedApi, api);
}