(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@platform/api/index"), require("@platform/components/index"), require("@platform/table-core/index"), require("@platform/base/index"), require("react"), require("@platform/card-table/index"), require("@platform/form/index"), require("@platform/simple-table/index"), require("@platform/edit-table/index"), require("@platform/transfer-table/index"), require("@platform/tree-table/index"), require("babel-polyfill"));
	else if(typeof define === 'function' && define.amd)
		define(["@platform/api/index", "@platform/components/index", "@platform/table-core/index", "@platform/base/index", "react", "@platform/card-table/index", "@platform/form/index", "@platform/simple-table/index", "@platform/edit-table/index", "@platform/transfer-table/index", "@platform/tree-table/index", "babel-polyfill"], factory);
	else if(typeof exports === 'object')
		exports["platform-login"] = factory(require("@platform/api/index"), require("@platform/components/index"), require("@platform/table-core/index"), require("@platform/base/index"), require("react"), require("@platform/card-table/index"), require("@platform/form/index"), require("@platform/simple-table/index"), require("@platform/edit-table/index"), require("@platform/transfer-table/index"), require("@platform/tree-table/index"), require("babel-polyfill"));
	else
		root["platform-login"] = factory(root["@platform/api/index"], root["@platform/components/index"], root["@platform/table-core/index"], root["@platform/base/index"], root["React"], root["@platform/card-table/index"], root["@platform/form/index"], root["@platform/simple-table/index"], root["@platform/edit-table/index"], root["@platform/transfer-table/index"], root["@platform/tree-table/index"], root["babel-polyfill"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__18__) {
return 