
import simpleTable from "@platform/simple-table";
import * as simpleTableAPI from "./compatible";

/**
 * 兼容旧版本创建表格方法
 * @param {string} mainCode 表格主区域编码
 * @param {object} config 表格业务配置
 */

export default function simpleTableCompatible() {
    this.register("table"); // 生成 this.cardTableDataSource[id]
    return {
        // store: useSimpleTable,
        ...apiBindPage.call(this, simpleTableAPI),
    };
}

function apiBindPage(simpleTableAPI) {
    let res = {}
    for (let pop of Object.keys(simpleTableAPI)) {
        res[pop] = simpleTableAPI[pop].bind(this);
    }
    return res;
}

simpleTableCompatible.component = simpleTable;
simpleTableCompatible.displayName = "SimpleTable";
simpleTableCompatible.unfinished = ["simpleTableUnFinishedApi"];
