// 扩展platform样式表
import { setNCCSwitch, getAppReqParam, getGlobalStorage, setGlobalStorage, getCookie, gzip as Gzip, syncAjax} from '@platform/api';
const gziptools = new Gzip();

const ExtendStyle = () => {
    getExtendStyleSwitch();
    if(getGlobalStorage('localStorage', '_ncc_extend_style_') !== '1') return;

    let domHead = document.getElementsByTagName('HEAD').item(0);
     // 插入新style
     let style = document.createElement('link');
     // 这里暂时没有考虑hash的情况 TODO
     style.href = `../../../../NCCExtend/extend_platform/index.css`;
     style.rel = 'stylesheet';
     style.type = 'text/css';
     style.onerror = function (e) {
         console.log('未能platform扩展样式表', 'NCCExtend/extend_platform/index.css');
     };

     domHead.appendChild(style);
};

export default ExtendStyle;


function getExtendStyleSwitch(){
    if (NODE_ENV === 'development') {
        return;
    }

    // 此时的cookie是当前界面唯一 如果放到ajax内部  
    // 多个请求的情况下 会导致前面的cookie被覆盖  所有请求用的是第二次的cookie  而加密用的cookie是第一次的
    let cookiets = (top && top.cookiets) || getCookie('cookiets') || Date.now(); //双cookie验证 liuxis
    // 做一次 数据转型
    cookiets = isNaN(cookiets) ? cookiets : Number(cookiets);

    let headers = { 'Content-Type': 'application/json;charset=UTF-8', cookiets };
    let { appcode } = getAppReqParam();
    appcode = appcode || '10228888';

    // 添加一个参数给nginx 转发使用
    headers['transcode'] = appcode;
  
    let gzipFlag = setNCCSwitch(headers);

    let extendStyleSwitch = getGlobalStorage('localStorage', '_ncc_extend_style_');

    if (extendStyleSwitch == null) {
        let reqData = {
            sysParamJson: {
                busiaction: '查询请求扩展全局样式开关',
                pagecs: cookiets,
            },
        };

        let reqHeaders = headers ? { ...headers } : {};
        reqData = JSON.stringify(addDataSign(reqData, '', { allin: true, headers: reqHeaders }));

        syncAjax({
            url: '/nccloud/platform/extendstyle/switch.do',
            data: gzipFlag ? gziptools.zip(reqData) : reqData,
            headers: reqHeaders,
            async: false,
            type: 'post',
            success: res => {
                res = gzipFlag ? gziptools.unzip(res) : res;
                res = typeof res === 'string' ? JSON.parse(res) : res;
                if (res && res.success) {
                    if (res.data) {
                        //开启加载全局扩展开发样式
                        setGlobalStorage('localStorage', '_ncc_extend_style_', '1');
                    } else {
                        //关闭加载全局扩展开发样式
                        setGlobalStorage('localStorage', '_ncc_extend_style_', '0');
                    }
                } 
            }
        });
    }
}


// 添加一个加签处理方法 -- by bbqin
function addDataSign(data, userCode, {
    allin = false,
    headers = {},
}) {
    // console.log(data, userCode);
    if (
        typeof transSn === 'undefined' ||
        typeof transSign === 'undefined' ||
        // !userCode ||
        (headers && headers['Content-Type'] == 'multipart/form-data') // newData.append(  这种模式 先确定下 需不需要加签操作
    ) {
        return data;
    }
    // 先加签全部数据 或者部分数据

    // 这里会影响原字符串 （编码、位数？）导致后台解析不了字符串
    if (!allin) {
        let strDataSign = data.busiParamJson + '';
        // 加签 -- bbqin
        data.sysParamJson.sn = transSn(userCode);
        data.sysParamJson.signdata = transSign(strDataSign, userCode);
    } else {
        // 加签 -- bbqin
        // data.sysParamJson.sn = transSn(userCode);
        let dataStr = JSON.stringify(data) + '';
        headers['Identifications'] = transSign(dataStr, '');
    }
    // 这里是通过对象方式注入  headers  不符合设计原则 TODO 
    return data;
}



