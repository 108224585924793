/*
 * @Author: your name
 * @Date: 2020-07-16 11:10:16
 * @LastEditTime: 2020-11-17 09:56:49
 * @LastEditors: bbq
 * @Description: In User Settings Edit
 * @FilePath: /Platform_Front/src/platform/components/Form/compatible.js
 */
import React from "react";
import Form from "@platform/form";
import { localeLang, PubSub, isFunction, toast } from '@platform/api';
import { beforeAndAfter, getCheckedExtendMethod } from '../../FastExtend/utils';
import {
    focusToNext,
    focusNextItem
} from './enterkey';

/**
 * @description: 获取当前form的Store 
 * @param {type} 
 * @return: 
 */
function getStore(moduleId) {
    if (this.formDataSource && this.formDataSource[moduleId]) {
        return this.formDataSource[moduleId]
    } else {
        // console.warn('不存在formDataSource');
        // return null
        // 暂时通过error 提醒业务组、 稳定后可去掉
        throw Error(`正在调用formID为${moduleId}的表单的API, 但此表单还未通过use.form注册,请先注册此ID，才能正常调用API`)
    }
}

/**
 * @description: 设置表单某项获取焦点
 * @param {String,String} 
 * @return: 
 */
function setFormItemFocus(moduleId, attrcode) {
    if (moduleId && attrcode) {
        PubSub.publish('autoFocus', { data: attrcode });
    }
}

/**
 * 聚焦到表单某一项
 * @param {*} param0 
 * @param {*} formId 
 */
function focusFormItem({ itemtype, attrcode }, formId) {
    focusNextItem({ itemtype, attrcode }, formId);
}

/**
 * 聚焦到表单 下一项  formId分为两个主要是为了分组
 * @param {*} attrcode 
 * @param {*} currenFormId 
 * @param {*} mainFormId 
 */
function focusFormNextItem(attrcode, currenFormId, mainFormId) {
    let moduleId = mainFormId || currenFormId;
    let currentStore = getStore.call(this, moduleId);
    console.log(attrcode, moduleId, currentStore, this);
    // focusToNext();
    let formComponent = currentStore.getRef();

    if (!formComponent || !formComponent.props) {
        return console.warn('not find formComponent!');
    }

    let { getHotKeyConfig = () => { } } = formComponent.props;
    let { meta, orderOfHotKey, onLastFormEnter } = getHotKeyConfig() || {};
    let { getStore: getDataStore, getAllFormItem } = currentStore.getInnerHooks();
    // 處理states 數據 TODO 後面可以考慮優化下
    let items = getAllFormItem() || {};
    let states = {
        disabled: {},
        visible: {},
        form: {},
    };

    for (let key in items) {
        let item = items[key];
        states.disabled[item.attrcode] = !!item.disabled;
        states.visible[item.attrcode] = !!item.visible;
        states.form[item.attrcode] = item;
    }

    let params = {
        states,
        meta,
        moduleId,
        attrcode: attrcode,
        head: moduleId,
        formrelation: meta && meta.formrelation ? meta.formrelation[moduleId] : [moduleId],
        orderOfHotKey: orderOfHotKey,
        onLastFormEnter: onLastFormEnter,
        // itemType,
        getStore: getDataStore,
    };
    focusToNext(params);
}

/**
 * @description: 获取page、兼容处理编辑前编辑后等回调函数的第一个参数
 * @param {type} 
 * @return: 
 */
function getContext(config = {}, context = {}) {
    let newConfig = { ...config };

    newConfig.onBeforeEvent = function (...rest) {
        let fxParams = { areaId: rest[0], itemId: rest[1], methods: context.output, form: rest[3], item: rest[2] };
        // console.log('beforeAndAfter before', fxParams);
        return beforeAndAfter(
            context,
            { moduleId: rest[0], attrcode: rest[1], fxType: 'onFormBeforeEvent', fxParams },
            () => {
                if (typeof config.onBeforeEvent === 'function') {
                    return config.onBeforeEvent.call(context, { ...context.props, ...context.output }, ...rest)
                }
                return true;
            });
    }

    newConfig.onAfterEvent = function (rest) {
        const {
            areaId, // 主表
            attrcode,
            curMetaItem, // 当前字段属性
            value,
            oldValue,
            componentValue,
            allValue,
            singleFormCode, // 当前子表ID
        } = rest;
        // 暂时处理编辑关联项
        if (curMetaItem.editAfterFlag && isFunction(context.handleRelationItems)) {
            context.handleRelationItems({
                type: 'form',
                areaCode: areaId,
                formMetaCode: singleFormCode,
                key: attrcode,
                value,
                callback: () => {
                    let fxParams = { areaId, itemId: attrcode, methods: context.output, form: allValue, finalValue: value, oldValue, val: componentValue };
                    beforeAndAfter(
                        context,
                        { moduleId: areaId, attrcode, fxType: 'onFormAfterEvent', fxParams },
                        () => {
                            typeof config.onAfterEvent === 'function' && config.onAfterEvent.call(context, { ...context.props, ...context.output }, areaId, attrcode, value, oldValue, componentValue, allValue)
                        });
                },
            })
        } else {
            let fxParams = { areaId, itemId: attrcode, methods: context.output, form: allValue, finalValue: value, oldValue, val: componentValue };
            // console.log('beforeAndAfter after', fxParams);
            beforeAndAfter(
                context,
                { moduleId: areaId, attrcode, fxType: 'onFormAfterEvent', fxParams },
                () => {
                    typeof config.onAfterEvent === 'function' && config.onAfterEvent.call(context, { ...context.props, ...context.output }, areaId, attrcode, value, oldValue, componentValue, allValue)
                });
        }

    }

    return newConfig
}

/**
 * @description: 创建表单
 * @param {type} moduleId 主表ID
 * @param {type} config Object 表单配置项
 * @return: 
 */
function createForm(moduleId, config = {}) {
    let currentStore = getStore.call(this, moduleId);
    return (
        <Form
            key={moduleId}
            store={currentStore}
            name={moduleId}
            formComponentUpdate={form => {
                // console.log('getCheckedExtendMethod', form);
                getCheckedExtendMethod(this, 'updateForm')({ form, moduleId, page: this, useOn: 'create' });
            }}
            config={getContext(config, this)}
            // 快捷键 解耦
            getHotKeyConfig={() => {
                return {
                    pageScope: this,
                    autoFocusDeferred: this.autoFocusDeferred,
                    meta: this.state.meta,
                    withHotKeyChange: this.withHotKeyChange,
                    orderOfHotKey: this.orderOfHotKey,
                    onLastFormEnter: config.onLastFormEnter,
                };
            }}
        />
    )
}

/**
 * @description: 设置表单全部数据
 * @param {Object, Boolean, Boolean, Array, Function} 
 * @return: 
 */
function setAllFormValue(formDataObj, copyFlag = true, emptyOldVal = false, setOldValueExceptKey, callback) {
    for (let moduleId of Object.keys(formDataObj)) {
        let newData = formDataObj[moduleId].rows[0].values,
            currentStore = getStore.call(this, moduleId);
        currentStore.setAllFormValue(newData, copyFlag, emptyOldVal, setOldValueExceptKey, callback)
    }
}

/**
 * @description: 获取表单所有数据
 * @param {String/Array}   
 * @return: Object
 */
function getAllFormValue(moduleIds) {
    if (Array.isArray(moduleIds)) {
        let allFormData = {};
        for (let moduleId of moduleIds) {
            allFormData[moduleId] = getSingleData.call(this, moduleId);
        }
        return allFormData
    } else {
        return getSingleData.call(this, moduleIds)
    }
}

/**
 * @description: 获取单个表数据
 * @param {type} 
 * @return: 
 */
function getSingleData(moduleId, isSimple = false) {
    let currentStore = getStore.call(this, moduleId);
    let formData = isSimple ? setReferValueSimple(moduleId, currentStore.getAllFormValue()) : setReferValue(moduleId, currentStore.getAllFormValue());
    let data = { values: formData };
    let status = currentStore.getStatus();
    if (status == 'add') {
        data.status = '2';
    } else if (status == 'edit') {
        data.status = '1';
    }
    return {
        areaType: 'form',
        rows: [data],
    };
}

/**
 * @description: 获取表单中某个字段的值
 * @param {String, Array} 
 * @return: 
 */
function getFormItemsValue(moduleId, data) {
    let currentStore = getStore.call(this, moduleId);
    if (currentStore) {
        if (Array.isArray(data)) {
            let newData = [];
            newData = data.map(item => {
                let result = null;
                result = currentStore.getItemValue(item)
                return result;
            });
            return newData;
        } else {
            let newData = currentStore.getItemValue(data, true)
            return newData
        }
    } else {
        if (Array.isArray(data)) {
            let newData = [];
            newData = data.map(() => {
                return {
                    display: null,
                    value: null,
                };
            });
            return newData;

        } else {
            return {
                display: null,
                value: null,
            };
        }
    }
}

/**
 * @description: 设置表单中某个字段的值
 * @param {String, Object, Boolean} 
 * @return: 
 */
function setFormItemsValue(moduleId, values = {}, cancel = true) {
    let currentStore = getStore.call(this, moduleId);
    let hasNumberField = false;
    for (let key of Object.keys(values)) {
        let itemtype = currentStore.getItemAttribute(key, 'itemtype');
        if (itemtype === 'number') {
            hasNumberField = true;
        }
        currentStore.setItemValue(key, { ...values[key] }, { cancel })
    }
    // number的value值里有scale，属于props，需要整表更新；
    hasNumberField && currentStore.forceUpdate();
}

/**
 * @description: 获取表单某个或某些字段的编辑性
 * @param {String, String/Array} 
 * @return: 
 */
function getFormItemsDisabled(moduleId, keys) {
    let currentStore = getStore.call(this, moduleId);

    if (Array.isArray(keys)) {
        let res = [];
        res = keys.map(item => {
            return !!currentStore.getItemAttribute(item, 'disabled');
        });
        return res;
    } else {
        return !!currentStore.getItemAttribute(keys, 'disabled');
    }
}

/**
 * @description: 设置表单编辑性
 * @param {String, Object} 
 * @return: 
 */
function setFormItemsDisabled(moduleId, values) {
    let currentStore = getStore.call(this, moduleId);
    currentStore.setItemAttribute('disabled', values)
}

/**
 * @description: 设置表单某些字段的必输性
 * @param {String,Obejct} 
 * @return: 
 */
function setFormItemsRequired(moduleId, values) {
    let currentStore = getStore.call(this, moduleId);
    currentStore.setItemAttribute('required', values)
}

/**
 * @description: 获取表单某些字段的必输性
 * @param {String,String} 
 * @return: 
 */
function getFormItemsRequired(moduleId, keys) {
    let currentStore = getStore.call(this, moduleId);

    if (Array.isArray(keys)) {
        let res = [];
        res = keys.map(item => {
            return !!currentStore.getItemAttribute(item, 'required');
        });
        return res;
    } else {
        return !!currentStore.getItemAttribute(keys, 'required');
    }
}

/**
 * @description: 设置表单某些字段的显隐性
 * @param {type} 
 * @return: 
 */
function getFormItemsVisible(moduleId, keys) {
    let currentStore = getStore.call(this, moduleId);

    if (Array.isArray(keys)) {
        let res = [];
        res = keys.map(item => {
            return !!currentStore.getItemAttribute(item, 'visible');
        });
        return res;
    } else {
        return !!currentStore.getItemAttribute(keys, 'visible');
    }
}

/**
 * @description: 设置表单某些字段的显隐性
 * @param {type} 
 * @return: 
 */
function setFormItemsVisible(moduleId, values) {
    let currentStore = getStore.call(this, moduleId);
    currentStore.setItemAttribute('visible', values)
}

/**
 * @description: 设置表单某些字段的校验规则
 * @param {type} 
 * @return: 
 */
function setFormItemsVerify(moduleId, keys) {
    // TODO: 2005版没对这个方法做实现
    let currentStore = getStore.call(this, moduleId);

    if (Array.isArray(keys)) {
        let res = [];
        res = keys.map(item => {
            return !!currentStore.getItemAttribute(item, 'verify');
        });
        return res;
    } else {
        return !!currentStore.getItemAttribute(keys, 'verify');
    }
}

/**
 * @description: 设置表单某些字段的校验规则
 * @param {type} 
 * @return: 
 */
function getFormItemsVerify(moduleId, values) {
    // TODO: 这里逻辑有问题，2005版没对这个方法做实现
    let currentStore = getStore.call(this, moduleId);
    currentStore.setItemAttribute('verify', values)
}

/**
 * @description: 清空表单所有数据
 * @param {String, Array, Boolean} 
 * @return: 
 */
function EmptyAllFormValue(moduleId, exceptArr, cancel = true) {
    let currentStore = getStore.call(this, moduleId);
    currentStore.emptyAllFormValue(exceptArr, cancel)
}

/** 
 * @description: 表单检验(必填,正则校验)
 * @param {String, String} 
 * @return: Boolean 
 */
function isCheckNow(moduleId, type = 'danger') {
    let requiredItems = [],
        regItems = [],
        maxItems = [],
        flag = true,
        toastStore;
    if (Array.isArray(moduleId)) {
        moduleId.forEach((item, index) => {
            let currentStore = getStore.call(this, item);
            if (index === 0) toastStore = currentStore
            let res = currentStore.isCheckNow();
            requiredItems = requiredItems.concat(res.requiredItems);
            regItems = regItems.concat(res.regItems);
            maxItems = maxItems.concat(res.maxItems);
        });
    } else {
        let currentStore = getStore.call(this, moduleId);
        toastStore = currentStore;
        ({ requiredItems, regItems, maxItems } = currentStore.isCheckNow());
    }
    if (requiredItems.length || regItems.length || maxItems.length) {
        flag = false;

        let json = toastStore.getInnerHooks().getJson();

        let toastContent = '';

        if (requiredItems.length) {
            toastContent += `${json['page-form-0001']}：${requiredItems.join(', ')}\n`
        }
        if (regItems.length) {
            toastContent += `${json['page-form-0002']}：${regItems.join(', ')}\n`
        }
        if (maxItems.length) {
            toastContent += `${json['page-form-0010']}：${maxItems.join(', ')}`
        }

        toast({
            mark: 'form_isCheckNow',
            color: type,
            title: json['page-form-0009'],
            content: toastContent,
        });
    }
    return flag
}

/**
 * @description: 展开表单某个区域
 * @param {String} 主表ID  和  分组ID
 * @return: 
 */
function openArea(moduleId, groupId) {
    let currentStore = getStore.call(this, moduleId);
    currentStore && currentStore.openArea(groupId)
}

/**
 * @description: 收起表单某个区域
 * @param {String}  主表ID  和  分组ID
 * @return: 
 */
function closeArea(moduleId, groupId) {
    let currentStore = getStore.call(this, moduleId);
    currentStore && currentStore.closeArea(groupId)
}

function setAreaVisible(moduleId, groupId, visible) {
    let currentStore = getStore.call(this, moduleId);
    currentStore && currentStore.setAreaVisible(groupId, visible);
}

function getAreaVisible(moduleId, groupId, visible) {
    let currentStore = getStore.call(this, moduleId);
    currentStore && currentStore.getAreaVisible(groupId, visible);
}

function setActiveTab(moduleId, activeTabCode) {
    let currentStore = getStore.call(this, moduleId);
    currentStore && currentStore.getInnerHooks().setFormTabs(activeTabCode);
}

function getActiveTab(moduleId) {
    let currentStore = getStore.call(this, moduleId);
    currentStore && currentStore.getInnerHooks().getFormTabs();
}

/**
 * @description: 表单取消方法
 * @param {String/Array} 
 * @return: 
 */
function cancel(moduleIds) {
    if (Array.isArray(moduleIds)) {
        for (let moduleId of moduleIds) {
            let currentStore = getStore.call(this, moduleId);
            currentStore.cancel()
        }
    } else {
        let currentStore = getStore.call(this, moduleIds);
        currentStore.cancel()
    }
}

/**
 * @description: 设置表单status
 * @param {String, String} 
 * @return: 
 */
function setFormStatus(moduleId, status) {
    let currentStore = getStore.call(this, moduleId);
    currentStore.setStatus(status)
}

/**
 * @description: 获取表单status
 * @param {String} 
 * @return: 
 */
function getFormStatus(moduleId) {
    let currentStore = getStore.call(this, moduleId);
    return currentStore.getStatus()
}

/**
 * @description: 检验是否必填 没看出来有什么用处
 * @param {String} 
 * @return: Boolean
 */
function checkRequired() {
    return false
}

/**
 * @description: 表单从新计算
 * @param {String} 
 * @return: 
 */
function resetItemWidth(moduleId) {
    let currentStore = getStore.call(this, moduleId);
    currentStore.countFormWidth()
}

/**
 * @description: 自动获取焦点API
 * @param {String,String} 
 * @return: 
 */
function setFormItemAutoFocus(moduleId, attrcode) {
    let currentStore = getStore.call(this, moduleId);
    console.log(moduleId);
    currentStore.getInnerHooks().setStore('autoFocusFormItem', attrcode);
}

/**
 * @description: 更新表单
 * @param {String/Array} 
 * @return: 
 */
function updateForm(moduleId) {
    console.log(moduleId);
}

/**
 * @description: 获取表单所有简化版数据
 * @param {String, Array} 
 * @return: Array
 */
function getAllFormValueSimple(moduleIds) {
    if (Array.isArray(moduleIds)) {
        let allFormData = {};
        for (let moduleId of moduleIds) {
            allFormData[moduleId] = getSingleData.call(this, moduleId, true);
            return allFormData
        }
    } else {
        return getSingleData.call(this, moduleIds, true)
    }
}

/**
 * @description: 简化表单每一项数据
 * @param {String, Object} 
 * @return: Object
 */
function setReferValueSimple(moduleId, formData = {}) {
    let newData = {};
    // Object.keys(formData).forEach(pop => {
    //     if (!isEmpty(formData[pop].value)) {
    //         newData[pop] = { value: formData[pop].value };
    //     } else {
    //         newData[pop] = {};
    //     }
    // })
    for (let pop in formData) {
        if (!isEmpty(formData[pop].value)) {
            newData[pop] = { value: formData[pop].value };
        } else {
            newData[pop] = {};
        }
    }
    return newData;
}

/**
 * @description: 判断第一个参数是否为空，后面可以传其他【认为是空值】的参数
 * @param {} 
 * @return: Boolean
 */
function isEmpty(val, ...rest) {
    if (val === null || val === undefined || rest.find(e => e == val)) {
        return true;
    }
    return false;
}

/**
 * @description: 设置显隐性
 * @param {type} 
 * @return: 
 */
function setItemsVisible(moduleId, values) {
    setFormItemsVisible.call(this, moduleId, values)
}

/**
 * @description: attrcode 获取 当前登录语言的 attrcode 
* @param  moduleId String  form的id号 
 * @param  attrcode String 字段
 * @return: 
 */
function getResidtxtLang(moduleId, attrcode) {
    return attrcode;
}

function setFormPopConfirmSwitchTips(moduleId) {

}

function updateDataByRefresh(moduleId, newData, pkname) {
    let currentStore = getStore.call(this, moduleId);

    if (currentStore && newData) {
        let FormData = getAllFormValue.call(this, moduleId);
        FormData = FormData.rows ? FormData.rows[0] : {};
        FormData = FormData.values ? FormData.values : {};
        //let newData = refreshData[0];
        let pknameValue = FormData[pkname] ? (FormData[pkname].value || FormData[pkname].display || FormData[pkname]) : '';
        // 根据pkname进行更新 
        if (pkname && pknameValue == newData[pkname]) {
            for (let pop in newData) {
                FormData[pop] = {
                    ...FormData[pop],
                    value: newData[pop],
                };
            }
            currentStore.setAllFormValue(FormData)
        }
        console.log('update form data by pkname:', pkname);
    }
}

function clearFormVerify(moduleId) {

}

/**
 * @description: 设置参照值
 * @param {String, Object} 
 * @return: 
 */
function setReferValue(moduleId, formData) {
    let newResData = {}; //新加--提高效率
    for (let pop in formData) {
        let result = null;
        if (formData[pop]) {
            if (Object.prototype.toString.call(formData[pop].value).slice(8, -1) === 'String') {
                formData[pop].value = formData[pop].value.trim();
            }
            result = {
                display: formData[pop].display,
                value: formData[pop].value,
            };
            if (formData[pop].hasOwnProperty('scale')) {
                result.scale = formData[pop].scale;
            }
        }
        //formData[pop] = result;
        newResData[pop] = result;//新加--提高效率
    }
    return newResData;
}

function reset(moduleId) {
    let currentStore = getStore.call(this, moduleId);
    currentStore.reset('visible')
}

export {
    createForm,
    getAllFormValue,
    setAllFormValue,
    getFormItemsValue,
    setFormItemsValue,
    getFormItemsDisabled,
    setFormItemsDisabled,
    setFormItemsRequired,
    getFormItemsRequired,
    getFormItemsVisible,
    setFormItemsVisible,
    setFormItemsVerify,
    getFormItemsVerify,
    EmptyAllFormValue,
    isCheckNow,
    openArea,
    closeArea,
    setAreaVisible,
    getAreaVisible,
    setActiveTab,
    getActiveTab,
    cancel,
    setFormStatus,
    getFormStatus,
    checkRequired,
    resetItemWidth,
    setFormItemAutoFocus,
    updateForm,
    getAllFormValueSimple,
    setItemsVisible,
    getResidtxtLang,
    setFormPopConfirmSwitchTips,
    setFormItemFocus,
    focusFormItem,
    focusFormNextItem,
    updateDataByRefresh,
    clearFormVerify,
    // reset,
}