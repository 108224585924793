/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-07-15 20:27:32
 * @LastEditors: bbq
 * @LastEditTime: 2020-08-01 15:29:29
 */
import CardTable from "@platform/card-table";
import * as cardTableAPI from "./compatible";

//console.log('CardTable----------------:', CardTable)

//const useCardTable = CardTable.useCardTable;

/**
 * 兼容旧版本创建表格方法
 * @param {string} mainCode 表格主区域编码
 * @param {object} config 表格业务配置
 */

export default function cardTableCompatible() {
    this.register("cardTable"); // 生成 this.cardTableDataSource[id]
    return {
        //store: useCardTable,
        ...apiBindPage.call(this, cardTableAPI),
    };
}

function apiBindPage(cardTableAPI) {
    let res = {}
    for (let pop of Object.keys(cardTableAPI)) {
        res[pop] = cardTableAPI[pop].bind(this);
    }
    return res;
}

cardTableCompatible.component = CardTable;
cardTableCompatible.displayName = "CardTable";
cardTableCompatible.unfinished = ["updateTable", "getTableItemData", "checkTableRequired"];
