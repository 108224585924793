/*
 * @Author: wanglongx
 * @Date: 2020-07-16 11:07:43
 * @LastEditTime: 2020-07-16 20:17:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /Platform_Front/src/platform/components/Form/output.js
 */ 
import * as formAPI from "./compatible";
import Form from "@platform/form";

/**
 * @param {string} mainCode 
 * @param {object} config 
 */

export default function formCompatible() {
    this.register("form");
    return {
        store: Form.useForm,
        ...apiBindPage.call(this, formAPI),
    };
}

function apiBindPage(formAPI){
    let res ={}
    for(let pop of Object.keys(formAPI)){
        res[pop] = formAPI[pop].bind(this);
    }
    return res;
}

formCompatible.component = Form;
formCompatible.displayName = "Form";
formCompatible.unfinished = [];
